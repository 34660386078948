declare const JwtTokenTag: unique symbol;

export class JwtToken {
  private [JwtTokenTag]: void;

  constructor(public readonly value: string) {}

  get asBearerHeader(): string {
    return `Bearer ${this.value}`;
  }
}
