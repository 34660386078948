import * as z from "zod";
import { ZodTeamSuiteUserId } from "@/domain/entity/user/ZodTeamSuiteUserId";
import { ZodTeamSuiteUserName } from "@/domain/entity/user/ZodTeamSuiteUserName";
import { ZodTeamSuiteEmail } from "@/domain/entity/user/ZodTeamSuiteEmail";

export const UserInfoWithoutToken = {
  schema: z.object({
    id: ZodTeamSuiteUserId.schema,
    name: ZodTeamSuiteUserName.schema,
    email: ZodTeamSuiteEmail.schema,
    role: z.number(),
    isActive: z.boolean(),
    userType: z.number(),
    profile: z.string().nullish(),
  }),
};
export type UserInfoWithoutToken = z.infer<typeof UserInfoWithoutToken.schema>;
