import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import {
  AzureAdTeamSuiteUserIdMapping,
  GetAddresseesRequest,
} from "@communitio-corp/teams-app-server-api-client-axios/lib/api";
import { ITeamsGetAddresseesPort } from "@/usecase/port/TeamsGetAddresseesPort";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { LogicError } from "@/common/lib/errors/LogicError";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";

export class TeamsAppAdapterForTeamsGetAddresseesPort implements ITeamsGetAddresseesPort {
  constructor(
    private readonly teamsAppApi: TeamsAppApi,
    private readonly context: TeamsContextInfo,
    private readonly cmntTeamName: CmntTeamName,
  ) {}

  public execute(): Promise<SimpleTeamsUserInfo[]> {
    const getConversationType = (
      conversationType: typeof TeamsContextInfo.prototype.conversation.type,
    ): "team" | "groupChat" | undefined => {
      switch (conversationType) {
        case "channel":
          return "team";
        case "groupChat":
          return "groupChat";
        default:
          return undefined;
      }
    };
    const conversationType = getConversationType(this.context.conversation.type);
    if (!conversationType) return Promise.resolve([]);
    const generateRequestParam = (conversationType: "team" | "groupChat"): GetAddresseesRequest => {
      switch (conversationType) {
        case "team":
          return {
            conversationType,
            teamId: this.context.conversationTypeAndIdForApi.id,
          };
        case "groupChat":
          return {
            conversationType,
            chatId: this.context.conversationTypeAndIdForApi.id,
          };
      }
    };

    return this.teamsAppApi
      .getAddressees(
        { getAddresseesRequest: generateRequestParam(conversationType) },
        {
          timeout: 30 * 1000,
          params: generateRequestParam(conversationType),
        },
      )
      .then((ret) => {
        switch (ret.data.type) {
          case "success":
            return ret.data.addressees;
          case "error":
            throw new Error(ret.data.code);
        }
      })
      .then((addressees) => {
        return this.teamsAppApi
          .findUserByAadObjectIds({
            cmntTeamId: this.cmntTeamName.value,
            findUserByAadObjectIdsRequest: {
              aadObjectIds: addressees.map((addressee) => addressee.id),
            },
          })
          .then((ret) => {
            if (!ret.data.users) return [];
            return ret.data.users.reduce(
              (userInfo: SimpleTeamsUserInfo[], user: AzureAdTeamSuiteUserIdMapping): SimpleTeamsUserInfo[] => {
                const foundAddressee = addressees.find((addressee) => addressee.id === user.aadObjectId);
                if (!foundAddressee) throw new LogicError("unexpected error");
                if (user.teamSuiteUserId) {
                  userInfo.push({
                    id: user.aadObjectId,
                    displayName: foundAddressee.displayName,
                    userPrincipalName: foundAddressee.email ?? "",
                    cmntUserId: user.teamSuiteUserId,
                  });
                }
                return userInfo;
              },
              [],
            );
          })
          .catch(Promise.reject);
      })
      .catch((err) => {
        console.error("error in TeamsAppAdapterForTeamsGetAddresseesPort", err);
        return Promise.reject(err);
      });
  }
}
