import { Stage } from "@/common/lib/Stage";

export const isProd = (): boolean => window.location.href.indexOf("https://teams.communitio.tech") >= 0;

export const isLocal = (): boolean => window.location.href.indexOf("https://teams.local.communitio.tech") >= 0;

export const stageBasedOnHref = (): Stage => {
  if (isProd()) {
    return "prd";
  } else if (isLocal()) {
    return "local";
  } else {
    return "dev";
  }
};
