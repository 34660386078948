import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { Stage } from "@/common/lib/Stage";
import { StorageUtils } from "@/common/lib/StorageUtils";

export class EnvVars {
  public static cmntBaseUrl(cmntTeamName: CmntTeamName, stage: Stage): string {
    switch (stage) {
      case "prd":
        return `https://${cmntTeamName.value}.communitio.net`;
      case "dev":
        return `https://${cmntTeamName.value}.dev.communitio.net`;
      case "local":
        return `https://${cmntTeamName.value}.dev.communitio.net`;
    }
  }

  public static cmntWebBaseUrlForTeam(cmntTeamName: CmntTeamName, stage: Stage): string {
    return `${this.cmntWebBaseUrl(stage)}/team/${cmntTeamName.value}`;
  }

  public static cmntWebBaseUrl(stage: Stage): string {
    switch (stage) {
      case "prd":
        return `https://app.communitio.net`;
      case "dev":
        return `https://app.dev.communitio.net`;
      case "local":
        return `https://app.dev.communitio.net`;
    }
  }

  public static teamsAppBaseUrl(stage: Stage): string {
    const storedTeamsAppBaseUrl = StorageUtils.getLocalStorage("TeamsAppBaseUrl");
    if (storedTeamsAppBaseUrl) {
      return storedTeamsAppBaseUrl;
    } else {
      switch (stage) {
        case "prd":
          return "https://teams-app.communitio.tech";
        case "dev":
          return "https://teams-app.dev.communitio.tech";
        case "local":
          // ngrokに置き換える(teams-app-server側も変更すること)
          // 併せて、keycloakの設定も必要に応じて設定する
          return `https://teams-app.dev.communitio.tech`;
      }
    }
  }

  public static authBaseUrl(stage: Stage): string {
    switch (stage) {
      case "prd":
        return "https://auth.communitio.tech";
      case "dev":
        return "https://auth.dev.communitio.tech";
      case "local":
        return "https://auth.dev.communitio.tech";
    }
  }

  public static webSiteBaseUrl(stage: Stage): string {
    const storedWebSiteBaseUrl = StorageUtils.getLocalStorage("WebSiteBaseUrl");
    if (storedWebSiteBaseUrl) {
      return storedWebSiteBaseUrl;
    } else {
      switch (stage) {
        case "prd":
          return "https://teams.communitio.tech";
        case "dev":
          return `https://teams.dev.communitio.tech`;
        case "local":
          return "https://teams.local.communitio.tech:8081";
      }
    }
  }

  public static teamsAppServerDomain(stage: Stage): string {
    switch (stage) {
      case "prd":
        return "teams.communitio.tech";
      case "dev":
        return `teams.dev.communitio.tech`;
      case "local":
        return "teams.local.communitio.tech";
    }
  }

  public static GoogleClientId(stage: Stage): string {
    switch (stage) {
      case "prd":
        return `578372566803-cs6ndug9rnm1qlkb6dfdpe2pd56crqfa.apps.googleusercontent.com`;
      case "dev":
      case "local":
        return `838748301361-s9kn1cmucqa59v6i6vidq9j0mij816s5.apps.googleusercontent.com`;
    }
  }

  public static AppClientId(stage: Stage): string {
    switch (stage) {
      case "prd":
        return "bceca1f0-723f-44d0-b732-b3506c0a641d";
      case "dev":
        return "6cfa44f4-d8c9-4cec-b713-238846cfefa3";
      case "local":
        return "20d5aedf-a003-4ef6-bbd5-3010307f8287";
    }
  }

  public static appName(stage: Stage): string {
    switch (stage) {
      case "prd":
        return "TeamSticker";
      case "dev":
        return "dev:TeamSticker";
      case "local":
        return "local:TeamSticker";
    }
  }
}
