import { CmntPeerGiftCampaignId } from "@/domain/entity/peergift/CmntPeerGiftCampaignId";
import { CmntPeerGiftCampaignName } from "@/domain/entity/peergift/CmntPeerGiftCampaignName";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";
import { CmntPeerGiftCampaignDescription } from "@/domain/entity/peergift/CmntPeerGiftCampaignDescription";
import { CmntPeerGiftCampaignStatus } from "@/domain/entity/peergift/CmntPeerGiftCampaignStatus";
import { CmntPeerGiftId } from "@/domain/entity/peergift/CmntPeerGiftId";
import { CmntPeerGiftCampaignLimitPrice } from "@/domain/entity/peergift/CmntPeerGiftCampaignLimitPrice";

declare const CmntPeerGiftMyCampaignIDTag: unique symbol;

export class CmntPeerGiftMyCampaign {
  private [CmntPeerGiftMyCampaignIDTag]: void;

  constructor(
    // * ギフトキャンペーンID。
    public readonly campaignId: CmntPeerGiftCampaignId,
    // * ギフトキャンペーン名。
    public readonly campaignName: CmntPeerGiftCampaignName,
    // * ギフトキャンペーンの説明。
    public readonly description: CmntPeerGiftCampaignDescription,
    // * ギフトキャンペーン開始日。
    public readonly validFrom: CmntValidDateTime,
    // * ギフトキャンペーン終了日(この日を含む)。
    public readonly validUntil: CmntValidDateTime,
    // * ギフトキャンペーンで使用できるギフトの単価上限。
    public readonly limit: CmntPeerGiftCampaignLimitPrice,
    // * ギフトキャンペーンステータス。
    public readonly status: CmntPeerGiftCampaignStatus,
    // * ギフトキャンペーンに紐づくギフト一覧。
    public readonly gifts: CmntPeerGiftId[],
    // * 割り当てクーポン個数
    public readonly numOfCoupons: number,
    // * のこり利用可能クーポン個数
    public readonly numOfRest: number,
    // * 使用済みクーポン個数
    public readonly numOfUsed: number,
  ) {}

  public get restDays(): number {
    const now = CmntValidDateTime.now();
    return now.diffFrom(this.validUntil).asDays();
  }
}
