declare const CardMessageIDTag: unique symbol;

export class CardMessage {
  public static UPPER_LIMIT = 1000;

  private [CardMessageIDTag]: void;

  constructor(public readonly value: string) {}

  public get isValid(): boolean {
    return this.value.length >= 0 && this.value.length <= CardMessage.UPPER_LIMIT;
  }
}
