import * as microsoftTeams from "@microsoft/teams-js";
import { ITeamsContextPort } from "@/usecase/port/TeamsContextPort";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { TeamsTeamName } from "@/domain/entity/teams/TeamsTeamName";
import { TeamsChatId } from "@/domain/entity/teams/TeamsChatId";
import { TeamsGroupId } from "@/domain/entity/teams/TeamsGroupId";
import { TeamsTeamId } from "@/domain/entity/teams/TeamsTeamId";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { LogicError } from "@/common/lib/errors/LogicError";
import { TeamsMeetingId } from "@/domain/entity/teams/TeemsMeetingId";
import { TeamsEntityId } from "@/domain/entity/teams/TeamsEntityId";
import { isDefined, isNullOrUndefined } from "@/common/lib/TypeUtilities";

export class MicrosoftTeamsSdkAdapter implements ITeamsContextPort {
  public async get(): Promise<TeamsContextInfo> {
    return microsoftTeams.app.initialize().then(() => {
      return microsoftTeams.app
        .getContext()
        .then((context) => {
          return MicrosoftTeamsSdkAdapter.parseContext(context);
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    });
  }

  static parseContext(context: microsoftTeams.app.Context): TeamsContextInfo {
    if (
      isDefined(context.user) &&
      isDefined(context.user.tenant) &&
      isDefined(context.user.tenant.id) &&
      isDefined(context.user.id)
    ) {
      if (isNullOrUndefined(context.chat?.id) || context.chat?.id === "") {
        // channel or staticTab
        if (isDefined(context.team) && isDefined(context.team.groupId)) {
          return new TeamsContextInfo(
            new TeamsTenantId(context.user.tenant.id),
            {
              teamId: new TeamsTeamId(context.team.groupId),
              type: "channel",
            },
            isDefined(context.team.groupId) ? new TeamsGroupId(context.team.groupId) : undefined,
            new TeamsTeamName(context.team.displayName ?? ""),
            new TeamsAAAObjectUserId(context.user.id),
            context,
          );
        } else {
          return new TeamsContextInfo(
            new TeamsTenantId(context.user.tenant.id),
            {
              entityId: new TeamsEntityId(context.page.id),
              type: "staticTab",
            },
            undefined,
            new TeamsTeamName(context.team?.displayName ?? ""),
            new TeamsAAAObjectUserId(context.user.id),
            context,
          );
        }
      } else {
        // chat or meeting
        if (isNullOrUndefined(context.meeting?.id) || context.meeting?.id === "") {
          return new TeamsContextInfo(
            new TeamsTenantId(context.user.tenant.id),
            {
              chatId: new TeamsChatId(context.chat?.id ?? ""),
              type: "groupChat",
            },
            isDefined(context.team) && isDefined(context.team.groupId)
              ? new TeamsGroupId(context.team.groupId)
              : undefined,
            new TeamsTeamName(context.team?.displayName ?? ""),
            new TeamsAAAObjectUserId(context.user.id),
            context,
          );
        } else {
          return new TeamsContextInfo(
            new TeamsTenantId(context.user.tenant.id),
            {
              chatId: new TeamsChatId(context.chat?.id ?? ""),
              type: "meeting",
              meetingId: new TeamsMeetingId(context.meeting?.id ?? ""),
            },
            isDefined(context.team) && isDefined(context.team.groupId)
              ? new TeamsGroupId(context.team.groupId)
              : undefined,
            new TeamsTeamName(context.team?.displayName ?? ""),
            new TeamsAAAObjectUserId(context.user.id),
            context,
          );
        }
      }
    }
    throw new LogicError(`Failed to parse context info: ${JSON.stringify(context)}`);
  }
}
