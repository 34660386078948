declare const CmntPeerGiftCampaignLimitPriceIDTag: unique symbol;

/**
 * ギフトキャンペーンで使用できるギフトの単価上限。
 */
export class CmntPeerGiftCampaignLimitPrice {
  public static LOWER_LIMIT = 1;
  public static UPPER_LIMIT = 10000;

  private [CmntPeerGiftCampaignLimitPriceIDTag]: void;

  constructor(public readonly value: number) {}

  public isValid(): boolean {
    return (
      CmntPeerGiftCampaignLimitPrice.LOWER_LIMIT <= this.value &&
      this.value <= CmntPeerGiftCampaignLimitPrice.UPPER_LIMIT
    );
  }
}
