


















import { Component, Vue } from "vue-property-decorator";
import querystring from "query-string";
import { EnvVars } from "@/domain/entity/misc/EnvVars";
import { stageBasedOnHref } from "@/common/lib/WindowUtil";

@Component
export default class StartAuthenticateForUser extends Vue {
  private tenantId = "";
  private userId = "";
  private signinUrl = "";

  public mounted(): void {
    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const tenantIdOrUndef = qs["tenantId"];
    this.tenantId = tenantIdOrUndef ? tenantIdOrUndef.toString() : "";
    const userIdOrUndef = qs["userId"];
    this.userId = userIdOrUndef ? userIdOrUndef.toString() : "";

    const stage = stageBasedOnHref();
    const redirectUrl = `${EnvVars.teamsAppBaseUrl(stage)}/api/user_granted`;
    const baseUrl = `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/authorize`;
    const clientId = EnvVars.AppClientId(stage);
    const state = JSON.stringify({
      tenantId: this.tenantId,
      userId: this.userId,
      appId: clientId,
    });

    const scopes =
      "openid offline_access User.Read User.ReadBasic.All profile email";
    const p = {
      client_id: clientId,
      response_type: "code",
      redirect_uri: redirectUrl,
      response_mode: "query",
      scope: scopes,
      state,
    };
    this.signinUrl = `${baseUrl}?${querystring.stringify(p)}`;
  }

  public onClickAuth(): void {
    window.location.href = this.signinUrl;
  }
}
