



























import { Component, Emit, Prop, Vue, Model } from "vue-property-decorator";
import MessageEditor from "@/container/create_card/component/MessageEditor.vue";
import MessageLengthIndicator from "@/container/create_card/component/MessageLengthIndicator.vue";
import StickerSelector from "@/container/create_card/component/StickerSelector.vue";
import { CardMessage } from "@/domain/entity/sticker/CardMessage";
import { CmntSticker } from "@/domain/entity/sticker/CmntSticker";
import { CmntStickersV2 } from "@/domain/entity/sticker/CmntStickersV2";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";

@Component({
  model: {
    prop: "message",
    event: "input",
  },
  components: {
    MessageEditor,
    MessageLengthIndicator,
    StickerSelector,
  },
})
export default class CardContentEditor extends Vue {
  @Model("input", { type: String, required: true })
  protected readonly message!: string;
  @Prop({ type: Object, required: true }) public stickers!: CmntStickersV2;
  @Prop({ type: CmntEventSticker, required: false })
  public eventSticker: CmntEventSticker;
  @Prop({ type: CmntTeamName, required: true })
  public cmntTeamName: CmntTeamName;
  @Prop({ type: Object, required: false })
  public sticker: CmntSticker | null;
  @Prop({ type: Boolean, required: true })
  public useWideStickerSelector: boolean;

  public CardMessage = CardMessage;

  /**
   * カードメッセージ
   */
  public get message_(): string {
    return this.message;
  }
  public set message_(message: string) {
    this.$emit("input", message);
  }

  public get randomMessagePlaceholder(): string {
    if (this.$getLang() === "ja") {
      return ` メッセージ例:
    急なお願いにも関わらず引き受けて頂いてありがとうございました。
    100点の仕上がりのおかげでプレゼンもうまくいきました！

    ○○さんの丁寧な仕事ぶりにはいつも学ばせて頂いています。`;
    } else {
      return `Message example:
    Thank you for accepting the sudden request.
    Thanks to the 100-point finish, the presentation went well!

    I'm always learning from your polite work.`;
    }
  }

  @Emit("update:sticker")
  public onSelectedSticker(sticker: CmntSticker): CmntSticker {
    return sticker;
  }
}
