import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { ITeamStickerGetEventStickersPort } from "@/usecase/port/TeamStickerGetEventStickersPort";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { validateCmntEventStickers } from "@/usecase/interactor/conversions";
import { InvalidTeamsAppApiResponseError } from "@/usecase/port/InvalidTeamsAppApiResponseError";

export class TeamsAppAdapterForTeamStickerGetEventStickerPort implements ITeamStickerGetEventStickersPort {
  constructor(
    private readonly teamsAppApi: TeamsAppApi,
    readonly teamsTenantId: TeamsTenantId,
    readonly cmntTeamName: CmntTeamName,
  ) {}

  public get(): Promise<CmntEventSticker[]> {
    return this.teamsAppApi
      .getEventStickers({
        tenantId: this.teamsTenantId.value,
        cmntTeamId: this.cmntTeamName.value,
      })
      .then((data) => {
        if (data.data.eventStickers === undefined) {
          throw new InvalidTeamsAppApiResponseError(`eventStickers is undefined`);
        } else {
          return validateCmntEventStickers(data.data.eventStickers);
        }
      });
  }
}
