import { EnvName } from "@/domain/entity/misc/EnvName";
import { CmntStickerId } from "@/domain/entity/sticker/CmntStickerId";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";
import { StringUtils } from "@/common/lib/StringUtils";
import { EnvUtils } from "@/common/lib/EnvUtils";

export class UrlUtils {
  public static staticImage(name: string): string {
    return `/img/${name}`;
  }

  public static getDefaultUserImageUrl(): string {
    return UrlUtils.staticImage("default_profile.png");
  }

  public static getDefaultStickerImageUrl(envName: EnvName, stickerId: CmntStickerId): string {
    return `${UrlUtils.getImageUrlBase(envName)}/default/sticker_images/sticker_${stickerId.id}.png`;
  }

  public static getStickerImageUrl(
    tenantName: CmntTeamName,
    envName: EnvName,
    stickerId: CmntStickerId,
    extra: string,
  ): string {
    const p = extra.length > 0 ? `?ts=${extra}` : "";
    return `${UrlUtils.getImageUrlBase(envName)}/${tenantName.value}/sticker_images/sticker_${stickerId.id}.png${p}`;
  }

  public static getImageUrlBase(env: EnvName): string {
    if (env.isProd) {
      return "https://communitio-images.communitio.tech";
    } else if (env.isStg) {
      return "https://communitio-images.stg.communitio.tech";
    } else if (env.isDev) {
      return "https://communitio-images.dev.communitio.tech";
    } else {
      // for local
      return "https://communitio-images.dev.communitio.tech";
    }
  }

  public static getTeamsAppUrlBase(env: EnvName): string {
    if (env.isProd) {
      return "https://teams-app.communitio.tech";
    } else {
      return "https://teams-app.dev.communitio.tech";
    }
  }

  public static getUserImageUrl(tenantName: CmntTeamName, envName: EnvName, userId: string, extra: string): string {
    if (userId.toLowerCase() === "system") {
      return `/assets/img/teamsuite_icon.png`;
    } else {
      return `${UrlUtils.getImageUrlBase(envName)}/${tenantName.value}/user_images/${userId}/128.png?ts=${extra}`;
    }
  }

  public static getUserImageOrDefaultByUserId(tenantName: CmntTeamName, userId: CmntUserId): string {
    if (!StringUtils.nullOrEmpty(userId.value)) {
      const timestampPer10Minutes = Math.floor(new Date().getTime() / 1000 / 60 / 6).toString();
      return UrlUtils.getUserImageUrl(tenantName, EnvUtils.getEnvironment(), userId.value, timestampPer10Minutes);
    } else {
      return UrlUtils.getDefaultUserImageUrl();
    }
  }

  public static getUserNonDisplayUsersNumImage(nonDisplayUsersNum: number): string {
    const canvasWidth = 56;
    const canvasHeight = 56;

    // キャンバス作成
    const canvas = document.createElement("canvas");
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    // キャンバス作成失敗時
    if (typeof canvas.getContext === "undefined") {
      return UrlUtils.getDefaultUserImageUrl();
    }

    const ctx = canvas.getContext("2d");
    if (ctx === null) {
      return UrlUtils.getDefaultUserImageUrl();
    }

    const showNonDisplayNum = "+" + nonDisplayUsersNum.toString();

    // キャンバスをラウンド加工
    ctx.arc(canvasWidth / 2, canvasHeight / 2, canvasWidth / 2, 0, Math.PI * 2);

    // キャンバスの背景色
    ctx.fillStyle = "#f1f0ef";
    ctx.fill();

    // キャンバスに文字列描写
    ctx.fillStyle = "#4E4B45";
    ctx.font = "18px Noto Sans JP";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(showNonDisplayNum, canvasWidth / 2, canvasHeight / 2, canvasWidth);

    // キャンバスを画像に変換
    const image = canvas.toDataURL();

    return image;
  }
}
