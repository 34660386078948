

















import {
  Vue,
  Prop,
  Component as ComponentAnnotation,
} from "vue-property-decorator";
import type { Component } from "vue";

@ComponentAnnotation
export default class ErrorBoundary extends Vue {
  @Prop({ type: Function }) public fallbackComponent: Vue;
  @Prop({ type: Function }) protected onError: <T extends Error>(
    err: T,
    vm: Component,
    info: string
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ type: Object }) public params: any;
  @Prop({ type: Boolean, default: false }) protected stopPropagation: boolean;

  public hasError = false;
  public info: string | null = null;
  public err: Error | null = null;

  protected errorCaptured<T extends Error>(
    err: T,
    vm: Component,
    info: string
  ): boolean {
    this.hasError = true;
    this.info = info;
    this.err = err;

    if (this.onError) {
      this.onError(err, vm, info);
    }

    return !this.stopPropagation;
  }
}
