import Vue from "vue";
import VueRouter from "vue-router";
import { RouteConfig } from "vue-router/types/router";
import GrantedPage from "@/container/auth/GrantedPage.vue";
import NotGrantedPage from "@/container/auth/NotGrantedPage.vue";
import AuthenticatedPage from "@/container/auth/AuthenticatedPage.vue";
import NotAuthenticatedPage from "@/container/auth/NotAuthenticatedPage.vue";
import AuthRedirect from "@/container/auth/AuthRedirectPage.vue";
import StartAuthenticateForUser from "@/container/auth/StartAuthenticateForUser.vue";
import MessageExtensionContainer from "@/container/create_card/MessageExtensionContainer.vue";
import AuthenticatedByUser from "@/container/auth/AuthenticatedByUser.vue";
import NotAuthenticatedByUser from "@/container/auth/NotAuthenticatedByUser.vue";
import { MicrosoftTeamsSdkAdapter } from "@/usecase/interactor/MicrosoftTeamsSdkAdapter";
import { StubTeamsContextPort } from "@/usecase/development/StubTeamsContextPort";

Vue.use(VueRouter);

// 1. ルートコンポーネントを定義します
// 他のファイルからインポートすることもできます

// 2. ルートをいくつか定義します
// 各ルートは 1 つのコンポーネントとマッピングされる必要があります。
// このコンポーネントは実際の `Vue.extend()`、
// またはコンポーネントオプションのオブジェクトでも構いません。
// ネストされたルートに関しては後で説明します
const routes: RouteConfig[] = [
  {
    path: "/create_card",
    component: MessageExtensionContainer,
    props: (route) => ({
      teamsContextPort: route.query["debug"] ? new StubTeamsContextPort() : new MicrosoftTeamsSdkAdapter(),
    }),
  },
  { path: "/granted", component: GrantedPage },
  { path: "/granted_by_admin", component: GrantedPage },
  { path: "/not_granted", component: NotGrantedPage },
  { path: "/not_granted_by_admin", component: NotGrantedPage },
  { path: "/not_auth", component: NotAuthenticatedPage },
  { path: "/authenticated", component: AuthenticatedPage },
  { path: "/auth_redirect", component: AuthRedirect },
  { path: "/authenticate_by_user", component: StartAuthenticateForUser },
  { path: "/authenticated_by_user", component: AuthenticatedByUser },
  { path: "/not_authenticated_by_user", component: NotAuthenticatedByUser },
  {
    path: "/",
    component: MessageExtensionContainer,
    props: (route) => ({
      teamsContextPort: route.query["debug"] ? new StubTeamsContextPort() : new MicrosoftTeamsSdkAdapter(),
    }),
  },
];

// 3. ルーターインスタンスを作成して、ルートオプションを渡します
// 追加のオプションをここで指定できますが、
// この例ではシンプルにしましょう
const router = new VueRouter({
  mode: "history",
  base: "/teams/",
  routes, // `routes: routes` の短縮表記
});

export default router;
