import { ILogger } from "@/common/lib/logger/ILogger";
import { ITeamsGetUsersPort } from "@/usecase/port/TeamsGetUsersPort";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { LogicError } from "@/common/lib/errors/LogicError";
import { TeamsSearchUsersPort } from "@/usecase/port/TeamsSearchUsersPort";

export type LogContext = "SearchUser";

export const sendToSentry = (logger: ILogger, context: LogContext, e: unknown): void => {
  logger.error(e, {
    context: context,
  });
};

export const fetchUsersInTeam = async (
  teamsGetUsers: ITeamsGetUsersPort | null,
  cmntTeamName: CmntTeamName,
): Promise<SimpleTeamsUserInfo[]> => {
  if (teamsGetUsers === null) {
    return Promise.reject(new LogicError(`teamsGetUsers must be initialized.`));
  } else {
    const query = encodeURIComponent(cmntTeamName.value);
    return teamsGetUsers.get(query);
  }
};

export type SearchMode =
  | {
      type: "advanced";
      users: SimpleTeamsUserInfo[];
    }
  | {
      type: "basic";
      searchUsers: TeamsSearchUsersPort;
    };
