(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["teams_app_web-app~d0ae3f07"] = factory();
	else
		root["teams_app_web-app~d0ae3f07"] = factory();
})(window, function() {
return 