




import { Component, Vue } from "vue-property-decorator";
import * as microsoftTeams from "@microsoft/teams-js";
import { EnvVars } from "@/domain/entity/misc/EnvVars";
import { stageBasedOnHref } from "@/common/lib/WindowUtil";

@Component
export default class AuthenticatedByUser extends Vue {
  protected async mounted(): Promise<void> {
    await microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.authentication.notifySuccess("");
        microsoftTeams.dialog.url.open({
          url: `${EnvVars.webSiteBaseUrl(stageBasedOnHref())}/create_card`,
          size: {
            width: 600,
            height: 430,
          },
        });
      })
      .catch(() => {
        //
      });
    window.self.close();
  }
}
