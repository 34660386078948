import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { TeamsTeamId } from "@/domain/entity/teams/TeamsTeamId";
import { TeamsGroupId } from "@/domain/entity/teams/TeamsGroupId";
import { TeamsChatId } from "@/domain/entity/teams/TeamsChatId";
import { TeamsTeamName } from "@/domain/entity/teams/TeamsTeamName";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { TeamsMeetingId } from "@/domain/entity/teams/TeemsMeetingId";
import { TeamsEntityId } from "@/domain/entity/teams/TeamsEntityId";
import { LogicError } from "@/common/lib/errors/LogicError";

declare const TeamsContextInfoIDTag: unique symbol;

export type ConversationType = typeof TeamsContextInfo.prototype.conversation.type;

export class TeamsContextInfo {
  private [TeamsContextInfoIDTag]: void;

  constructor(
    public readonly tenantId: TeamsTenantId,
    public readonly conversation:
      | {
          type: "staticTab";
          entityId: TeamsEntityId;
        }
      | {
          type: "channel";
          teamId: TeamsTeamId;
        }
      | {
          type: "groupChat";
          chatId: TeamsChatId;
        }
      | {
          type: "meeting";
          chatId: TeamsChatId;
          meetingId: TeamsMeetingId;
        },
    public readonly groupId: TeamsGroupId | undefined,
    public readonly teamName: TeamsTeamName,
    public readonly userId: TeamsAAAObjectUserId,
    public readonly context: microsoftTeams.app.Context,
  ) {}

  get legacyContext(): microsoftTeams.Context {
    // microsoftTeams.app.Context を microsoftTeams.Context に変換
    return {
      entityId: this.context.page.id,
      locale: this.context.app.locale,
      userObjectId: this.context.user?.id ?? "",
    };
  }

  get teamsUserInfo(): SimpleTeamsUserInfo {
    return {
      userPrincipalName: this.context.user?.userPrincipalName ?? "",
      id: this.context.user?.id ?? "",
      displayName: "",
      cmntUserId: "",
    };
  }

  // noinspection JSUnusedGlobalSymbols
  get i18nLocale(): string {
    const locale = this.context.app.locale.toLowerCase();
    return locale === "ja" || locale === "ja-jp" || locale === "ja_jp" ? "ja" : "en";
  }

  get userPrincipalName(): string | undefined {
    return this.context.user?.userPrincipalName;
  }

  get tags(): {
    teamName: string;
    chatId: string;
    teamId: string;
    groupId: string;
    channelName: string | undefined;
    locale: string;
    tenant: string;
    channelId: string | undefined;
  } {
    return {
      tenant: this.tenantId.value,
      teamId: this.conversation.type === "channel" ? this.conversation.teamId.value : "",
      teamName: this.teamName.value,
      groupId: this.groupId?.value ?? "",
      channelId: this.context.channel?.id,
      channelName: this.context.channel?.displayName,
      chatId: this.conversation.type === "groupChat" ? this.conversation.chatId.value : "",
      locale: this.context.app.locale,
    };
  }

  get userContext(): { theme: string | undefined; id: string; userTeamRole: microsoftTeams.UserTeamRole | undefined } {
    return {
      id: this.userId.value,
      userTeamRole: this.context.team?.userRole,
      theme: this.context.app.theme,
    };
  }

  get conversationTypeAndIdForApi(): { id: string; type: "channel" } | { id: string; type: "groupChat" } {
    switch (this.conversation.type) {
      case "staticTab":
        throw new LogicError("current context is not conversation");
      case "channel":
        return {
          type: "channel",
          id: this.conversation.teamId.value,
        };
      case "groupChat":
        return {
          type: "groupChat",
          id: this.conversation.chatId.value,
        };
      case "meeting":
        return {
          type: "groupChat",
          id: this.conversation.chatId.value,
        };
    }
  }

  // noinspection JSUnusedGlobalSymbols
  get conversationId(): string {
    switch (this.conversation.type) {
      case "staticTab":
        throw new LogicError("current context is not conversation");
      case "channel":
        return this.conversation.teamId.value;
      case "groupChat":
        return this.conversation.chatId.value;
      case "meeting":
        return this.conversation.chatId.value;
    }
  }

  get isChannel(): boolean {
    return this.conversation.type === "channel";
  }

  get isChatOrMeeting(): boolean {
    return this.conversation.type === "groupChat" || this.conversation.type === "meeting";
  }
}
