












import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as microsoftTeams from "@microsoft/teams-js";
import * as Raven from "raven-js";
import params from "../../../params";

@Component
export default class GrantedPage extends Vue {
  protected async mounted(): Promise<void> {
    await microsoftTeams.app
      .initialize()
      .then(() => {
        return microsoftTeams.app.getContext().then((context) => {
          const locale = context.app.locale.toLowerCase();
          this.$changeLang(
            locale === "ja" || locale === "ja-jp" || locale === "ja_jp"
              ? "ja"
              : "en"
          );
        });
      })
      .catch(() => {
        //
      });

    Raven.captureMessage(`<${params.env}/pages/not granted>`, {
      level: "warn",
    });
  }
}
