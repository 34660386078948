import { CmntStickerId } from "@/domain/entity/sticker/CmntStickerId";
import { CmntStickerName } from "@/domain/entity/sticker/CmntStickerName";
import { CmntSticker } from "@/domain/entity/sticker/CmntSticker";
import { CmntStickerStatus } from "@/domain/entity/sticker/CmntStickerStatus";
import { CmntStickerExample } from "@/domain/entity/sticker/CmntStickerExample";

declare const CmntStickerForEventStickerIDTag: unique symbol;

export class CmntStickerForEventSticker {
  private [CmntStickerForEventStickerIDTag]: void;

  constructor(
    public readonly id: CmntStickerId,
    public readonly name: CmntStickerName,
    public readonly status: CmntStickerStatus,
    public readonly example: CmntStickerExample,
  ) {}

  public toSticker(): CmntSticker {
    return new CmntSticker(this.id, this.name, this.status, [], this.example);
  }
}
