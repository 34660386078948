




import { Component, Vue } from "vue-property-decorator";
import * as microsoftTeams from "@microsoft/teams-js";

@Component
export default class NotAuthenticatedByUser extends Vue {
  protected async mounted(): Promise<void> {
    await microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.authentication.notifyFailure("");
      })
      .catch(() => {
        //
      });
    window.self.close();
  }
}
