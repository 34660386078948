import { z } from "zod";
import { StickerStatus } from "@communitio-corp/teams-app-server-api-client-axios";
import { CmntStickerId, ZodCmntStickerId } from "@/domain/entity/sticker/CmntStickerId";
import { CmntStickerName, ZodCmntStickerName } from "@/domain/entity/sticker/CmntStickerName";
import { CmntStickerStatus, ZodCmntStickerStatus } from "@/domain/entity/sticker/CmntStickerStatus";
import { CmntStickerExample, ZodCmntStickerExample } from "@/domain/entity/sticker/CmntStickerExample";
import { CmntUserGroupId, ZodCmntUserGroupId } from "@/domain/entity/user/CmntUserGroupId";

declare const CmntStickerIDTag: unique symbol;

export class CmntSticker {
  private [CmntStickerIDTag]: void;

  constructor(
    public readonly id: CmntStickerId,
    public readonly name: CmntStickerName,
    public readonly status: CmntStickerStatus,
    public readonly scope: CmntUserGroupId[],
    public readonly example: CmntStickerExample,
  ) {}

  public get enabled(): boolean {
    return this.status.status === StickerStatus.Enabled;
  }
}

const ZodCmntStickerScope = {
  schema: z.discriminatedUnion("scopeType", [
    z.object({
      scopeType: z.literal("all"),
    }),
    z.object({
      scopeType: z.literal("group"),
      groups: z.array(ZodCmntUserGroupId.schema),
    }),
  ]),
};

export const ZodCmntSticker = {
  schema: z.object({
    stickerId: ZodCmntStickerId.schema,
    name: ZodCmntStickerName.schema,
    status: ZodCmntStickerStatus.schema,
    scope: ZodCmntStickerScope.schema,
    example: ZodCmntStickerExample.schema,
  }),
};

export type ZodCmntSticker = z.infer<typeof ZodCmntSticker.schema>;

export const toLegacyCmntSticker = (data: ZodCmntSticker): CmntSticker => {
  // レガシーなCmntSticker型に変換する。
  // FIXME: この変換はいずれ削除したい。
  return new CmntSticker(
    new CmntStickerId(data.stickerId),
    new CmntStickerName(data.name),
    new CmntStickerStatus(data.status),
    data.scope.scopeType === "group" ? data.scope.groups.map((id) => new CmntUserGroupId(id)) : [],
    new CmntStickerExample(data.example ?? ""),
  );
};
