






































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

// import debounce from "lodash/debounce";
import { VAutocomplete } from "vuetify/lib";

import { PropType } from "vue";
import ReplaceableImage from "@/common/component/image/ReplaceableImage.vue";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";

import {
  SearchMode,
  sendToSentry,
} from "@/container/create_card/MessageExtensionLogic";
import { CmntUserName } from "@/domain/entity/user/CmntUserName";
import { CmntUserIdAndName } from "@/domain/entity/user/CmntUserIdAndName";
import { UrlUtils } from "@/common/lib/UrlUtils";
import { isDefined } from "@/common/lib/TypeUtilities";
import { ILogger } from "@/common/lib/logger/ILogger";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { TeamsTeamName } from "@/domain/entity/teams/TeamsTeamName";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { TeamsUserPrincipalName } from "@/domain/entity/teams/TeamsUserPrincipalName";
import { TeamsSearchUsersPort } from "@/usecase/port/TeamsSearchUsersPort";

export type AutoCompleteItem = {
  header?: string;
  text?: string;
  value?: string;
  group?: string;
  cmntUserId?: CmntUserId;
  cmntUserName?: CmntUserName;
  searchType?: "local" | "server";
};

type UserSearchResult = {
  cmntUserId: CmntUserId;
  cmntUserName: CmntUserName;
  email: TeamsUserPrincipalName;
};

@Component({
  components: {
    ReplaceableImage,
  },
  model: {
    prop: "selectedUsers",
    event: "update",
  },
})
export default class UsersSelector extends Vue {
  public UrlUtils = UrlUtils;
  @Prop({ type: CmntTeamName, required: true })
  public cmntTeamName: CmntTeamName;
  @Prop({ type: SimpleTeamsUserInfoV2, required: true })
  public fromUser!: SimpleTeamsUserInfoV2;
  @Prop({ type: Number, required: false, default: null })
  public readonly numOfReceiverLimit: number | null;
  @Prop({ type: Array, required: true })
  public readonly selectedUsers: CmntUserIdAndName[];
  @Prop({ type: String, required: true })
  public label: string;
  @Prop({ type: Boolean, required: false, default: true })
  public readonly canRemoveMyself: boolean;
  @Prop({ type: Boolean, default: false })
  public openOnMount: boolean;
  @Prop({ type: Object as PropType<ILogger> })
  private readonly logger?: ILogger;
  @Prop({ type: TeamsTeamName, required: true })
  public teamsTeamName!: TeamsTeamName;
  @Prop({ type: Object as PropType<SearchMode>, required: true })
  public readonly searchMode: SearchMode;

  public showSelectRecipientMenu = false;
  public isLoading = false;
  public searchInput = "";
  public currentPage = 1;
  public totalPages = 0;
  public loadMoreClicked = false;

  private searchResults: UserSearchResult[] = [];

  public $refs: {
    autocomplete: VAutocomplete;
    selectNewRecipientMenuActivator: HTMLDivElement;
  };

  private isShiftKeyPressed = false;

  mounted(): void {
    if (this.openOnMount) {
      window.setTimeout(() => {
        this.$refs.selectNewRecipientMenuActivator.dispatchEvent(
          new MouseEvent("click")
        );
      }, 100);
    }
    switch (this.searchMode.type) {
      case "advanced":
        this.searchResults = this.searchMode.users.map((u) => {
          return {
            cmntUserId: new CmntUserId(u.cmntUserId),
            cmntUserName: new CmntUserName(u.displayName),
            email: new TeamsUserPrincipalName(u.userPrincipalName),
          };
        });
        break;
    }
  }

  // FIXME:
  //   teamsuite-web から移行するにあたり、debounce 用のライブラリが異なっており
  //   Debouncer の使い方が分からなかったため、一旦デバウンス処理は除外している
  //
  // private userSearchWithThrottle = debounce(
  //   async (str: string, page: number) => {
  //     await this.loadUserFromNetwork(str, page);
  //   },
  //   400
  // );

  private get filteredUserSearchResult(): AutoCompleteItem[] {
    const ids = this.selectedUsers.map((u) => u.id.value);
    return this.searchResults
      .filter((r) => !ids.includes(r.cmntUserId.value))
      .map((u) => {
        return {
          text: `${u.cmntUserName.name} (${u.email.value})`,
          value: u.cmntUserId.value,
          cmntUserId: u.cmntUserId,
          cmntUserName: u.cmntUserName,
          group: this.$m.common.component.selector.users_selector.search_result,
          searchType: "server",
        };
      });
  }

  /**
   * 選択されたユーザーを除外したリストを返す
   * @public
   */
  public get autoCompleteItems(): AutoCompleteItem[] {
    const ret: { header?: string; text?: string; value?: string }[] = [];
    if (this.filteredUserSearchResult.length > 0) {
      ret.push(...this.filteredUserSearchResult);
    }
    return ret;
  }

  public get myId(): CmntUserId {
    return new CmntUserId(this.fromUser.id);
  }

  public get classes(): string {
    const cls: string[] = [`selected-${this.selectedUsers.length}`];
    cls.push("personal");

    return cls.join(" ");
  }

  public get canSelectNewRecipient(): boolean {
    if (!this.numOfReceiverLimit) return true;
    return this.selectedUsers.length < this.numOfReceiverLimit;
  }

  @Watch("canSelectNewRecipient")
  public onCanSelectNewRecipientChange(canSelectNewRecipient: boolean): void {
    if (!canSelectNewRecipient) {
      this.showSelectRecipientMenu = false;
    }
  }

  public async updateSearchInput(value: string | null): Promise<void> {
    if (isDefined(value)) {
      this.searchInput = value;
      this.currentPage = 1;
      if (this.canLoadMore) {
        await this.loadMore();
      }
    }
  }

  public unSelectUser(cmntUserId: CmntUserId): void {
    this.showSelectRecipientMenu = false;
    this.updateSelectedUsers(
      this.selectedUsers.filter((u) => u.id.value !== cmntUserId.value)
    );
  }

  public async onUserSelected(
    selected: {
      cmntUserId: CmntUserId;
      cmntUserName: CmntUserName;
    }[]
  ): Promise<CmntUserIdAndName[]> {
    // 検索結果の最後の1つを選択時、もっと見るが有効の場合
    if (this.filteredUserSearchResult.length === 1 && this.canLoadMore) {
      await this.loadMore();
    }

    const newSelectedUsers = selected.map((s) => {
      return new CmntUserIdAndName(s.cmntUserId, s.cmntUserName);
    });
    const newSelectedUsersWithoutDuplicate = newSelectedUsers.filter(
      (newSelectedUser) =>
        !this.selectedUsers.some(
          (selectedUser) => selectedUser.id.value === newSelectedUser.id.value
        )
    );
    const selectedUsers = this.selectedUsers.concat(
      newSelectedUsersWithoutDuplicate
    );
    this.updateSelectedUsers(selectedUsers);
    if (!this.isShiftKeyPressed) {
      await this.updateSearchInput("");
    }
    return selectedUsers;
  }

  @Emit("update")
  public updateSelectedUsers(
    selectedUsers: CmntUserIdAndName[]
  ): CmntUserIdAndName[] {
    return selectedUsers;
  }

  public getCustomFilter():
    | undefined
    | ((
        item: AutoCompleteItem,
        queryText: string,
        itemText: string
      ) => boolean) {
    switch (this.searchMode.type) {
      case "advanced":
        return undefined;
      case "basic":
        return (
          item: AutoCompleteItem,
          queryText: string,
          itemText: string
        ): boolean => {
          if (item.searchType === "server") {
            return true;
          }

          const addWhiteSpaces = (str: string): string => {
            let result = "";
            for (let i = 0; i < str.length; i++) {
              result += str[i] + "\\s*";
            }
            return result;
          };
          const regex = new RegExp(`${addWhiteSpaces(queryText)}`);
          return itemText.match(regex) !== null;
        };
    }
  }

  private onKeydown(event: KeyboardEvent): void {
    if (event.shiftKey) this.isShiftKeyPressed = true;
  }

  private onKeyup(event: KeyboardEvent): void {
    if (!event.shiftKey) this.isShiftKeyPressed = false;
  }

  @Watch("showSelectRecipientMenu")
  private onShowSelectRecipientMenu(showSelectRecipientMenu: boolean): void {
    if (showSelectRecipientMenu) {
      window.addEventListener("keydown", this.onKeydown);
      window.addEventListener("keyup", this.onKeyup);
      this.$nextTick(() => {
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.activateMenu();
        }
      });
    } else {
      window.removeEventListener("keydown", this.onKeydown);
      window.removeEventListener("keyup", this.onKeyup);
    }
  }

  public onBlur(): void {
    setTimeout(() => {
      if (this.loadMoreClicked) {
        this.loadMoreClicked = false;
        return;
      }
      this.showSelectRecipientMenu = false;
      this.searchInput = "";
    }, 100);
  }

  @Watch("searchInput") private async onChangeSearchInput(): Promise<void> {
    switch (this.searchMode.type) {
      case "basic":
        await this.loadUserFromNetwork(
          this.searchMode.searchUsers,
          this.searchInput,
          this.currentPage
        );
        // FIXME: デバウンスを行う
        // await this.userSearchWithThrottle(this.searchInput, this.currentPage);
        break;
    }
  }

  private async loadUserFromNetwork(
    searchUsers: TeamsSearchUsersPort,
    searchText: string,
    page = 1
  ): Promise<void> {
    this.isLoading = true;
    await searchUsers
      .execute(searchText, this.cmntTeamName)
      .then((resp) => {
        const newResults = resp
          .map((u) => {
            return {
              cmntUserId: u.cmntUserId,
              cmntUserName: new CmntUserName(u.displayName.value),
              email: u.userPrincipalName,
            };
          })
          .filter((u) => u.cmntUserId.value !== this.fromUser.id);
        // NOTE: 検索結果はページングされているが、Port 側で捨てられているため全ページ数は 1
        this.totalPages = 1;
        if (page > 1) {
          this.searchResults = [...this.searchResults, ...newResults];
        } else {
          this.searchResults = newResults;
        }
      })
      .catch((err) => {
        if (this.logger) {
          // 検索結果が出ないだけなので、エラー時はSentryへの通知だけしてなにもしない。
          sendToSentry(this.logger, "SearchUser", err);
        }
      })
      .finally(() => (this.isLoading = false));
  }

  public get canLoadMore(): boolean {
    return this.currentPage < this.totalPages;
  }

  public async loadMore(): Promise<void> {
    if (!this.isLoading && this.canLoadMore) {
      switch (this.searchMode.type) {
        case "basic": {
          this.isLoading = true;
          this.loadMoreClicked = true;
          this.currentPage++;
          await this.loadUserFromNetwork(
            this.searchMode.searchUsers,
            this.searchInput,
            this.currentPage
          );
          if (this.autoCompleteItems.length === 0 && this.canLoadMore) {
            // 再帰的に読み込む
            await this.loadMore();
          } else {
            this.focusAutocompleteInput();
          }
          this.isLoading = false;

          break;
        }
      }
    }
  }

  private focusAutocompleteInput(): void {
    this.$nextTick(() => {
      const input = this.$refs.autocomplete.$el.querySelector("input");
      if (input) {
        input.focus();
      }
    });
  }
}
