import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { ITeamsGetUsersPort } from "@/usecase/port/TeamsGetUsersPort";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";

export class TeamsAppAdapterForTeamsGetUsersPort implements ITeamsGetUsersPort {
  constructor(private readonly teamsAppApi: TeamsAppApi, private readonly teamsContextInfo: TeamsContextInfo) {}

  public get(query?: string): Promise<SimpleTeamsUserInfo[]> {
    return this.teamsAppApi
      .getUsers(
        {
          context: JSON.stringify({
            tenantId: this.teamsContextInfo.tenantId,
            conversation: this.teamsContextInfo.conversation,
            groupId: this.teamsContextInfo.groupId,
            teamName: this.teamsContextInfo.teamName,
            userId: this.teamsContextInfo.userId,
            context: this.teamsContextInfo.legacyContext,
          }),
          query: query,
        },
        {
          timeout: 5 * 1000,
        },
      )
      .then((ret) => {
        return ret.data.users as SimpleTeamsUserInfo[];
      });
  }
}
