declare const CmntEventStickerNameIDTag: unique symbol;

export class CmntEventStickerName {
  public static LOWER_LIMIT = 1;
  public static UPPER_LIMIT = 25;

  private className = "CmntEventStickerName";

  private [CmntEventStickerNameIDTag]: void;

  constructor(public readonly value: string) {}

  public isValid(): boolean {
    return (
      CmntEventStickerName.LOWER_LIMIT <= this.value.length && this.value.length <= CmntEventStickerName.UPPER_LIMIT
    );
  }
}
