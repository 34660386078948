import uuidv4 from "uuid/v4";

export class StringUtils {
  public static generateUUID(): string {
    return uuidv4();
  }

  public static padZero(value: number, len = 2): string {
    return ("0000000" + value).substr(-1 * Math.max(len, value.toString().length));
  }

  // もし頑張るなら一文字づつ判定して長さを決める。
  public static truncate(str: string, len: number, omission?: string): string {
    if (len < 0 || !str || str.length <= len) {
      return str;
    } else {
      omission = omission ? omission : "...";
      return str.substring(0, len) + omission;
    }
  }

  public static truncateLast(str: string, len: number, omission?: string): string {
    if (len < 0 || !str || str.length <= len) {
      return str;
    } else {
      omission = omission ? omission : "...";
      return omission + str.substring(str.length - len, str.length);
    }
  }

  public static nullOrEmpty(s: string | undefined): boolean {
    return !s || s.length === 0;
  }

  public static getRandomStr(length: number): string {
    const source = ["abcdefghijklmnopqrstuvwxyz", "ABCDEFGHIJKLMNOPQRSTUVWXYZ", "0123456789", "!#$%&=~/*-+"];
    const offset = source.length - 1;
    const randomConfig = []; // ランダム設定配列
    let sum = 0;
    let result = "";

    // どのsourceを何文字含むかをランダムで設定
    for (let i = 0; i <= offset; i++) {
      const includeNum = i === offset ? length - sum : 1 + Math.floor(Math.random() * (length - offset - sum - i + 2));

      randomConfig.push({
        src: source[i], // 元になるsource
        includeNum, // 含む回数
        count: 0, // 含まれた回数
      });
      sum += includeNum;
    }

    // 指定した長さのランダムな文字列を生成
    for (let i = 0; i < length; i++) {
      const index = 1 + Math.floor(Math.random() * randomConfig.length - 1);
      const randomSource = randomConfig[index];

      // ランダムなsourceから文字を選んで結合
      result += randomSource.src[Math.floor(Math.random() * randomSource.src.length)];

      randomSource.count++;

      // countとincludeNumが一致したらrandomConfig配列から削除
      if (randomSource.count === randomSource.includeNum) {
        randomConfig.splice(index, 1);
      }
    }

    return result;
  }

  public static isNotNull<T>(value: T | undefined | null): value is T {
    return value !== null && value !== undefined;
  }
}
