import z from "zod";

import { CmntStickerV2 } from "@/domain/entity/sticker/CmntStickerV2";

export const CmntStickersV2 = {
  schema: z.object({
    defaultCategory: CmntStickerV2.schema,
    categories: z.array(CmntStickerV2.schema),
  }),
};
export type CmntStickersV2 = z.infer<typeof CmntStickersV2.schema>;
