import { z } from "zod";

declare const CmntUserGroupIdIDTag: unique symbol;

export class CmntUserGroupId {
  private [CmntUserGroupIdIDTag]: void;

  constructor(public readonly value: string) {}
}

export const ZodCmntUserGroupId = {
  schema: z.string(),
};
export type ZodCmntUserGroupId = z.infer<typeof ZodCmntUserGroupId.schema>;
