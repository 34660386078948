declare const CmntPeerGiftCampaignDescriptionIDTag: unique symbol;

/**
 * ギフトキャンペーンの説明。
 */
export class CmntPeerGiftCampaignDescription {
  public static LOWER_LIMIT = 1;
  public static UPPER_LIMIT = 1000;

  private [CmntPeerGiftCampaignDescriptionIDTag]: void;

  constructor(public readonly value: string) {}

  public isValid(): boolean {
    return (
      CmntPeerGiftCampaignDescription.LOWER_LIMIT <= this.value.length &&
      this.value.length <= CmntPeerGiftCampaignDescription.UPPER_LIMIT
    );
  }
}
