import { CmntUserId } from "@/domain/entity/user/CmntUserId";

export const interIconMarginSizePx = 8;

/**
 * アイコン表示するユーザー数の上限。
 *
 * 受け取り手の数がdisplayIconNumまでは、ユーザーのアイコンを表示する。
 *
 * 受け取り手の数がdisplayIconNumより多い場合、（displayIconNum - 1）ユーザーをアイコン表示し、アイコン表示されていないユーザー数を表示する。
 *
 * 例:
 * A: 受け取り手の数 = 7 & displayIconNum = 6の場合、
 *
 *   5ユーザーをアイコン表示し、アイコン表示されていないユーザー数"+2"を表示する。
 *
 * B: 受け取り手の数 = 2 & displayIconNum = 6の場合、
 *
 *   2ユーザーをアイコン表示する。
 *
 * C: 受け取り手の数 = 6 & displayIconNum = 6の場合、
 *
 *   6ユーザーをアイコン表示する。
 */
export const displayIconMaxNum = 6;
export const sheetPaddingSizePx = 8;

export const getSheetMaxInnerWidthPx = (sheetMaxWidthPx: number): number => {
  return sheetMaxWidthPx - sheetPaddingSizePx * 2;
};

export const getDisplayIconNum = (multipleReceiversLength: number, maxIconNum: number = displayIconMaxNum): number => {
  return Math.min(multipleReceiversLength, maxIconNum);
};

export const getDisplayUsersNum = (multipleReceiversLength: number, maxIconNum: number = displayIconMaxNum): number => {
  if (multipleReceiversLength <= maxIconNum) {
    return multipleReceiversLength;
  } else {
    return maxIconNum - 1;
  }
};

export const getNonDisplayUsersNum = (multipleReceiversLength: number, displayUsersNum: number): number => {
  return multipleReceiversLength - displayUsersNum;
};

export const getMaxIconArrayWidthPx = (iconMaxSizePx: number, displayIconNum: number): number => {
  return iconMaxSizePx * displayIconNum + interIconMarginSizePx * (displayIconNum - 1);
};

export const getIconArrayWidth = (sheetMaxInnerWidthPx: number, maxIconArrayWidthPx: number): number => {
  return Math.min(sheetMaxInnerWidthPx, maxIconArrayWidthPx);
};

export const getIconShrinkRate = (iconArrayWidth: number, maxIconArrayWidthPx: number): number => {
  return iconArrayWidth / maxIconArrayWidthPx;
};

export const getIconSizePx = (iconMaxSizePx: number, iconShrinkRate: number): number => {
  return iconMaxSizePx * iconShrinkRate;
};

export const getSortByIdFunction = (preferredUserId?: CmntUserId): ((a: CmntUserId, b: CmntUserId) => number) => {
  return (a: CmntUserId, b: CmntUserId): number => {
    if (preferredUserId) {
      if (a.value === b.value) return 0;
      if (a.value === preferredUserId.value) return -1;
      if (b.value === preferredUserId.value) return 1;
    }
    return a.value.localeCompare(b.value);
  };
};
