import { Component, Vue } from "vue-property-decorator";
import { UrlUtils } from "@/common/lib/UrlUtils";
import { EnvUtils } from "@/common/lib/EnvUtils";
import { StringUtils } from "@/common/lib/StringUtils";
import { NumUtils } from "@/common/lib/NumUtils";

@Component
export default class VueBaseMixin extends Vue {
  protected UrlUtils = UrlUtils;
  protected EnvUtils = EnvUtils;
  protected StringUtils = StringUtils;
  protected NumUtils = NumUtils;
}
