import z from "zod";

import { CmntStickerCategoryName } from "@/domain/entity/sticker/CmntStickerCategoryName";
import { ZodCmntSticker, toLegacyCmntSticker } from "@/domain/entity/sticker/CmntSticker";

// zodとlegacyの共存
export const CmntStickerV2 = {
  schema: z.object({
    name: CmntStickerCategoryName.schema,
    // FIXME: 実装を全部繋げたあとに、別のコミットでCmntStickerを使っているところをZodCmntStickerに変更して、transformしなくてよいようにする。
    // 実装をつなげるために、一時的にCmntStickerを使っている。
    stickers: z.array(ZodCmntSticker.schema.transform(toLegacyCmntSticker)),
  }),
};
export type CmntStickerV2 = z.infer<typeof CmntStickerV2.schema>;
