

































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CmntPeerGiftMyCampaign } from "@/domain/entity/peergift/CmntPeerGiftMyCampaign";
import { CmntPeerGiftId } from "@/domain/entity/peergift/CmntPeerGiftId";
import { CmntPeerGift } from "@/domain/entity/peergift/CmntPeerGift";
import { nonNullable } from "@/common/lib/Utilities";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";

@Component
export default class GiftSelector extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly disabled!: boolean;
  @Prop({ type: String, required: true })
  public readonly disabledTooltip!: string;
  @Prop({ type: Array, required: true })
  protected campaigns!: CmntPeerGiftMyCampaign[];

  /**
   * 自分が送れる残りギフト数
   */
  @Prop({ type: Number, required: true })
  public readonly numOfRestGift!: number;

  @Prop({ type: Array, required: true }) protected gifts!: CmntPeerGift[];

  /**
   * 選択されているギフト
   */
  @Prop({ type: CmntPeerGift, required: false })
  public gift: CmntPeerGift | null;

  public showGiftsOverlay = false;

  public get targetCampaigns(): CmntPeerGiftMyCampaign[] {
    const now = CmntValidDateTime.now();
    return this.campaigns.filter(
      (c) => c.status.isOpen && now.isBefore(c.validUntil) && c.numOfRest > 0
    );
  }

  public giftsForCampaign(campaign: CmntPeerGiftMyCampaign): CmntPeerGift[] {
    return campaign.gifts
      .map((gid) => this.getGift(gid))
      .filter(nonNullable)
      .filter((g: CmntPeerGift) => g.status.isOpen)
      .sort((lhs: CmntPeerGift, rhs: CmntPeerGift) => {
        return lhs.price.price - rhs.price.price;
      });
  }

  @Emit("selected")
  public onSelectGift(
    campaign: CmntPeerGiftMyCampaign,
    gift: CmntPeerGift
  ): { campaign: CmntPeerGiftMyCampaign; gift: CmntPeerGift } {
    this.showGiftsOverlay = false;
    return { campaign, gift };
  }

  @Emit("unselected")
  public onUnSelectGift(gift: CmntPeerGift | null): CmntPeerGift | null {
    this.showGiftsOverlay = false;
    return gift;
  }

  private getGift(giftId: CmntPeerGiftId): CmntPeerGift | null {
    return this.gifts.find((g) => g.giftId.id === giftId.id) ?? null;
  }
}
