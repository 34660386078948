import { TeamsUserName } from "@/domain/entity/teams/TeamsUserName";
import { TeamsUserPrincipalName } from "@/domain/entity/teams/TeamsUserPrincipalName";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";

declare const SimpleTeamsUserInfoV2IDTag: unique symbol;

export class SimpleTeamsUserInfoV2 {
  private [SimpleTeamsUserInfoV2IDTag]: void;

  constructor(
    public readonly objectId: TeamsAAAObjectUserId,
    public readonly displayName: TeamsUserName,
    public readonly userPrincipalName: TeamsUserPrincipalName,
    public readonly cmntUserId: CmntUserId,
  ) {}

  public get id(): string {
    return this.objectId.value;
  }

  public get toLegacyInterface(): SimpleTeamsUserInfo {
    return {
      id: this.objectId.value,
      displayName: this.displayName.value,
      userPrincipalName: this.userPrincipalName.value,
      cmntUserId: this.cmntUserId.value,
    };
  }
}
