/**
 * イベントステッカー。 指定した期間中に公開された利用できる期間限定のステッカー。
 * @export
 */
import { CmntEventStickerId } from "@/domain/entity/sticker/CmntEventStickerId";
import { CmntEventStickerName } from "@/domain/entity/sticker/CmntEventStickerName";
import { CmntStickerForEventSticker } from "@/domain/entity/sticker/CmntStickerForEventSticker";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";

declare const CmntEventStickerIDTag: unique symbol;

export class CmntEventSticker {
  private [CmntEventStickerIDTag]: void;

  constructor(
    // イベントステッカーの識別子。
    public readonly eventStickerId: CmntEventStickerId,
    // イベントステッカーの名前。
    public readonly eventStickerName: CmntEventStickerName,
    // イベントステッカーの開始日。
    public readonly from: CmntValidDateTime,
    // イベントステッカーの終了日。
    public readonly to: CmntValidDateTime,
    // イベントステッカー公開期間中に使用できるステッカーの一覧。
    public readonly stickers: CmntStickerForEventSticker[],
  ) {}
}
