








































import { Component, Prop, Vue } from "vue-property-decorator";
import * as Raven from "raven-js";
import querystring from "query-string";
import { interpret } from "xstate";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import params from "../../../params";
import SelectDefaultTeam from "@/container/create_card/SelectDefaultTeam.vue";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import {
  ITeamsUserConfigPort,
  TeamsUserConfig,
} from "@/usecase/port/TeamsUserConfigPort";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { ITeamsTeamConfigPort } from "@/usecase/port/TeamsTeamConfigPort";
import { TeamsAppAdapterForTeamsTeamConfigPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsTeamConfigPort";
import { buildTeamsAppApi } from "@/usecase/interactor/TeamsAppApiBuilder";
import { TeamsAppAdapterForTeamsUserConfigPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsUserConfigPort";
import { ITeamsGetUsersPort } from "@/usecase/port/TeamsGetUsersPort";
import { TeamsAppAdapterForTeamsGetUsersPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsGetUsersPort";
import { ITeamStickerGetStickersPort } from "@/usecase/port/TeamStickerGetStickersPort";
import { TeamsAppAdapterForTeamStickerGetStickerPort } from "@/usecase/interactor/TeamsAppAdapterForTeamStickerGetStickerPort";
import { ITeamsContextPort } from "@/usecase/port/TeamsContextPort";
import { ITeamStickerGetEventStickersPort } from "@/usecase/port/TeamStickerGetEventStickersPort";
import { TeamsAppAdapterForTeamStickerGetEventStickerPort } from "@/usecase/interactor/TeamsAppAdapterForTeamStickerGetEventStickerPort";
import CreateCardV2 from "@/container/create_card/CreateCardV2.vue";
import { stageBasedOnHref } from "@/common/lib/WindowUtil";
import FallbackComponent from "@/container/error/FallbackComponent.vue";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { TeamsUserName } from "@/domain/entity/teams/TeamsUserName";
import { TeamsUserPrincipalName } from "@/domain/entity/teams/TeamsUserPrincipalName";
import MessageExtensionV2 from "@/container/create_card/MessageExtensionV2.vue";
import { TeamStickerLoadError } from "@/domain/entity/error/TeamStickerLoadError";
import { searchUserMachine } from "@/container/create_card/SearchUser.FSM";
import {
  fetchUsersInTeam,
  SearchMode,
  sendToSentry,
} from "@/container/create_card/MessageExtensionLogic";
import { RavenLogger } from "@/common/lib/logger/RavenLogger";
import { LogicError } from "@/common/lib/errors/LogicError";
import { nonNullable } from "@/common/lib/Utilities";
import { TeamsAppAdapterForTeamsSearchUserPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsSearchUserPort";
import { JwtToken } from "@/domain/entity/authentication/JwtToken";
import { isDefined } from "@/common/lib/TypeUtilities";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";

type State =
  | {
      state: "loading_user_config";
    }
  | {
      state: "selecting_default_team";
    }
  | {
      state: "creating_card";
      cmntTeamName: CmntTeamName;
    }
  | {
      state: "error";
      kind: "ErrorWithSelectTeamMenu" | "DeadEndError";
      message: string;
    };

@Component({
  components: {
    MessageExtensionV2,
    CreateCardV2,
    SelectDefaultTeam,
    FallbackComponent,
  },
})
export default class MessageExtensionContainer extends Vue {
  @Prop({ type: Object as () => ITeamsContextPort, required: true })
  protected teamsContextPort: ITeamsContextPort;
  public state: State = {
    state: "loading_user_config",
  };
  protected userConfig: TeamsUserConfig | null = null;
  public teamsContextInfo: TeamsContextInfo | null = null;
  protected teamsUserConfigPort: ITeamsUserConfigPort | null = null;
  protected teamsTeamConfigPort: ITeamsTeamConfigPort | null = null;
  private jwtToken: JwtToken | null = null;
  private teamsAppApi: TeamsAppApi | null = null;
  private readonly logger = new RavenLogger();
  private searchUserMachine = searchUserMachine(
    {
      cmntTeamName: undefined,
      usersInTeam: undefined,
    },
    {
      sendToSentry: this.sendToSentry,
      fetchUsesInTeam: this.fetchUsersInTeam,
    }
  );
  private searchUserService = interpret(this.searchUserMachine);
  private searchUserMachineState = this.searchUserService.initialState;

  get teamsGetUsersPort(): ITeamsGetUsersPort | null {
    if (nonNullable(this.teamsContextInfo) && nonNullable(this.teamsAppApi)) {
      return new TeamsAppAdapterForTeamsGetUsersPort(
        this.teamsAppApi,
        this.teamsContextInfo
      );
    } else {
      return null;
    }
  }

  public get useWideStickerSelector(): boolean {
    const teamName = this.userConfig?.defaultTeam?.value;
    return (
      teamName === "sammy" ||
      teamName === "nexty-ele-demo" ||
      teamName === "nexty1" ||
      teamName === "nexty2" ||
      teamName === "we4649" ||
      teamName === "kcazure" ||
      teamName === "adscimsaml"
    );
  }

  public get loggedInTeams(): CmntTeamName[] {
    return this.userConfig?.loggedInTeams ?? [];
  }

  public get defaultTeam(): CmntTeamName {
    return this.userConfig?.defaultTeam ?? new CmntTeamName("");
  }

  protected decNumRefToString(decNumRef: string): string {
    return decNumRef.replace(/&#(\d+);/gi, (match, $1) => {
      return String.fromCharCode($1);
    });
  }

  public async setLangWithContext(): Promise<void> {
    try {
      await microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.notifySuccess();
        return microsoftTeams.app.getContext().then((context) => {
          console.log("context", context);
          const locale = context.app.locale.toLowerCase();
          this.$changeLang(
            locale === "ja" || locale === "ja-jp" || locale === "ja_jp"
              ? "ja"
              : "en"
          );
        });
      });
    } catch (e) {
      //
    }
  }

  protected async mounted(): Promise<void> {
    this.state = {
      state: "loading_user_config",
    };
    await this.setLangWithContext();
    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const connectedTeams =
      typeof qs["cmntTeams"] === "string" ? qs["cmntTeams"].split(",") : [];
    const defaultCmntTeam =
      qs["defaultCmntTeam"]?.toString() ||
      (connectedTeams.length === 1 && connectedTeams[0]) ||
      undefined;
    const maybeJwtToken = qs["jwtToken"];
    this.jwtToken =
      typeof maybeJwtToken === "string" ? new JwtToken(maybeJwtToken) : null;

    if (isDefined(this.jwtToken)) {
      this.teamsAppApi = buildTeamsAppApi(stageBasedOnHref(), this.jwtToken);
      this.teamsContextInfo = await this.teamsContextPort.get();
      this.teamsTeamConfigPort = new TeamsAppAdapterForTeamsTeamConfigPort(
        this.teamsAppApi,
        this.teamsContextInfo
      );
      this.teamsUserConfigPort = new TeamsAppAdapterForTeamsUserConfigPort(
        this.teamsAppApi,
        this.teamsContextInfo.tenantId,
        this.teamsContextInfo.userId
      );

      Raven.setTagsContext(this.teamsContextInfo.tags);
      Raven.setUserContext(this.teamsContextInfo.userContext);
      Raven.captureMessage(
        `<${params.env}/pages/create_card> opened. (${this.teamsContextInfo.tenantId.value})`,
        {
          level: "info",
          extra: {
            context: this.teamsContextInfo,
          },
        }
      );
      this.setUserConfig({
        defaultTeam: defaultCmntTeam ? new CmntTeamName(defaultCmntTeam) : null,
        loggedInTeams: connectedTeams
          .filter((s) => s.length > 0)
          .map((s) => new CmntTeamName(s)),
      });

      this.searchUserService
        .onTransition((s) => {
          this.searchUserMachineState = s;
        })
        .start();
      if (defaultCmntTeam !== undefined) {
        this.searchUserService.send({
          type: "TEAMSUITE_TENANT_SELECTED",
          cmntTeamName: new CmntTeamName(defaultCmntTeam),
        });
      }
    } else {
      this.state = {
        state: "error",
        kind: "DeadEndError",
        message: this.$m.create_card_v2.failed_to_initialize,
      };
    }
  }

  private setUserConfig(newUserConfig: TeamsUserConfig): void {
    this.userConfig = newUserConfig;
    this.state =
      newUserConfig.defaultTeam === null
        ? {
            state: "selecting_default_team",
          }
        : { state: "creating_card", cmntTeamName: newUserConfig.defaultTeam };
  }

  get searchMode(): SearchMode | null {
    if (this.searchUserMachineState.matches("advanced search mode")) {
      if (this.searchUserMachineState.context.usersInTeam === undefined) {
        throw new LogicError("usersInTeam should be defined");
      }

      // 自分を除外する
      const users = this.searchUserMachineState.context.usersInTeam.filter(
        (u) => {
          return u.id !== this.teamsContextInfo?.userId.value;
        }
      );

      // もし正しく0件を返した場合、ローディングが止まらないのでBASIC_MODEに切り替えてあげる
      if (users.length === 0) {
        setTimeout(() => {
          this.searchUserService.send({
            type: "SWITCH_BASIC_MODE",
          });
        }, 5 * 1000);
      }
      return {
        type: "advanced",
        users,
      };
    } else if (this.searchUserMachineState.matches("basic search mode")) {
      const port = this.teamsSearchUsersPort;
      if (port === null) {
        throw new LogicError("teamsGetUsersPort should be defined");
      }

      return {
        type: "basic",
        searchUsers: port,
      };
    } else {
      // advance modeで空配列にしておくと、RecipientSelector側（V1の場合はMessageExtenxionV1側）でよしなに
      // ローディングを出してくれる。
      return {
        type: "advanced",
        users: [],
      };
    }
  }

  get teamsSearchUsersPort(): TeamsAppAdapterForTeamsSearchUserPort | null {
    if (
      nonNullable(this.teamsContextInfo) &&
      nonNullable(this.jwtToken) &&
      nonNullable(this.teamsAppApi)
    ) {
      return new TeamsAppAdapterForTeamsSearchUserPort(
        this.teamsAppApi,
        this.teamsContextInfo,
        this.jwtToken
      );
    } else {
      return null;
    }
  }

  get readyToDisplayMessageExtension(): boolean {
    return this.searchMode !== null && this.teamsSearchUsersPort !== null;
  }

  public get canSelectAnotherTeamSuiteTeam(): boolean {
    return (
      this.userConfig?.loggedInTeams.length !== undefined &&
      this.userConfig?.loggedInTeams.length > 1
    );
  }

  public async onTeamSelected(team: CmntTeamName): Promise<void> {
    this.searchUserService.send({
      type: "TEAMSUITE_TENANT_SELECTED",
      cmntTeamName: team,
    });
    if (this.userConfig) {
      this.setUserConfig({ ...this.userConfig, defaultTeam: team });
      await this.teamsUserConfigPort?.store(this.userConfig);
    }
  }

  public get teamStickerGetStickersPort(): ITeamStickerGetStickersPort | null {
    if (nonNullable(this.teamsContextInfo) && nonNullable(this.teamsAppApi)) {
      return new TeamsAppAdapterForTeamStickerGetStickerPort(
        this.teamsAppApi,
        this.teamsContextInfo.tenantId,
        this.userConfig?.defaultTeam ?? null
      );
    } else {
      return null;
    }
  }

  public get teamStickerGetEventStickersPort(): ITeamStickerGetEventStickersPort | null {
    if (
      nonNullable(this.teamsContextInfo) &&
      nonNullable(this.teamsAppApi) &&
      this.userConfig?.defaultTeam
    ) {
      return new TeamsAppAdapterForTeamStickerGetEventStickerPort(
        this.teamsAppApi,
        this.teamsContextInfo.tenantId,
        this.userConfig.defaultTeam
      );
    } else {
      return null;
    }
  }

  public onSwitchTeamSuiteTeam(): void {
    if (this.userConfig) {
      this.setUserConfig({ ...this.userConfig, defaultTeam: null });
    }
    this.searchUserService.send("SELECT_ANOTHER_TEAMSUITE_TENANT");
  }

  get fromUserV2B(): null | SimpleTeamsUserInfoV2 {
    if (this.teamsContextInfo !== null) {
      const fromUserInfoV2 = new SimpleTeamsUserInfoV2(
        new TeamsAAAObjectUserId(this.teamsContextInfo.teamsUserInfo.id),
        new TeamsUserName(this.teamsContextInfo.teamsUserInfo.displayName),
        new TeamsUserPrincipalName(
          this.teamsContextInfo.teamsUserInfo.userPrincipalName
        ),
        // TODO userId
        new CmntUserId("")
      );
      if (this.searchUserMachineState.matches("advanced search mode")) {
        const found = this.searchUserMachineState.context.usersInTeam?.find(
          (u) => u.id === fromUserInfoV2.objectId.value
        );
        if (found) {
          return new SimpleTeamsUserInfoV2(
            fromUserInfoV2.objectId,
            new TeamsUserName(found.displayName),
            fromUserInfoV2.userPrincipalName,
            // TODO userId
            new CmntUserId(fromUserInfoV2.cmntUserId.value)
          );
        } else {
          return fromUserInfoV2;
        }
      } else {
        return fromUserInfoV2;
      }
    } else {
      return null;
    }
  }

  private fetchUsersInTeam(
    cmntTeamName: CmntTeamName
  ): Promise<SimpleTeamsUserInfo[]> {
    return fetchUsersInTeam(this.teamsGetUsersPort, cmntTeamName);
  }

  private sendToSentry(e: unknown): void {
    sendToSentry(this.logger, "SearchUser", e);
  }

  protected errorCaptured<T extends Error>(
    err: T
    // _vm: VueComponent,
    // _info: string
  ): boolean {
    const hasMultipleTeams = (this.userConfig?.loggedInTeams.length ?? 0) > 1;
    if (err instanceof TeamStickerLoadError) {
      this.state = {
        state: "error",
        kind: hasMultipleTeams ? "ErrorWithSelectTeamMenu" : "DeadEndError",
        message: this.$m.create_card_v2.failed_to_get_stickers,
      };
    } else {
      this.state = {
        state: "error",
        kind: hasMultipleTeams ? "ErrorWithSelectTeamMenu" : "DeadEndError",
        message: this.$m.create_card_v2.failed_to_get_stickers,
      };
    }
    // error はここで止める。
    return false;
  }

  // iframeのドメインをapp.communitio.netに変えないと無理。
  // @Watch("userConfig") private onChangeUserConfig() {
  //   const team = this.userConfig?.defaultTeam;
  //   if (isDefined(team) && isDefined(this.teamsUserConfigPort)) {
  //     this.teamsUserConfigPort
  //       .loginWithAccessToken(team)
  //       .then((ret) => {
  //         appModule
  //           .fetchFeatureFlag({ teamName: team, token: ret.token })
  //           .then((ret) => {
  //             console.warn("feature flags", ret);
  //           })
  //           .catch((e) => {
  //             console.warn("failed fetch feature flags", e);
  //           });
  //         console.warn("loggedin", ret);
  //       })
  //       .catch((e) => {
  //         console.warn("failed loggedin", e);
  //       });
  //   }
  // }
}
