declare const EnvNameIDTag: unique symbol;

export class EnvName {
  public static isValidEnvName(name: string): name is "prd" | "stg" | "dev" | "local" {
    return name === "prd" || name === "stg" || name === "dev" || name === "local";
  }

  private [EnvNameIDTag]: void;

  constructor(public readonly name: "prd" | "stg" | "dev" | "local") {}

  public get isProd(): boolean {
    return this.name === "prd";
  }

  public get isStg(): boolean {
    return this.name === "stg";
  }

  public get isDev(): boolean {
    return this.name === "dev";
  }

  public get isLocal(): boolean {
    return this.name === "local";
  }
}
