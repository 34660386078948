import { FrameContexts, HostClientType, HostName } from "@microsoft/teams-js";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";
import { MicrosoftTeamsSdkAdapter } from "@/usecase/interactor/MicrosoftTeamsSdkAdapter";

export default class Mock {
  public static devTeamsContextInfo(): TeamsContextInfo {
    // https://teams.microsoft.com/l/team/19%3a8c377a9280bb43d082b26cb57f94f641%40thread.skype/conversations?groupId=b3189c8c-850d-4465-9b48-e72f797477c3&tenantId=8be41b2d-1c3e-4b49-9ec7-550a15315ddd
    // https://teams.microsoft.com/l/team/19%3a7256e9cbb9f747589a4baa07eedc54a1%40thread.skype/conversations?groupId=d267d326-86cf-4cf5-b505-f78349a0d998&tenantId=8be41b2d-1c3e-4b49-9ec7-550a15315ddd
    // for channel in team
    return MicrosoftTeamsSdkAdapter.parseContext({
      user: {
        id: "ccfcff85-00a6-4d11-9b94-e7f345d46ae6",
        tenant: {
          id: "8be41b2d-1c3e-4b49-9ec7-550a15315ddd",
        },
      },
      team: {
        internalId: "b3189c8c-850d-4465-9b48-e72f797477c3", // for communitio team
        groupId: "b3189c8c-850d-4465-9b48-e72f797477c3",
        // internalId: "d267d326-86cf-4cf5-b505-f78349a0d998", // for communitio team
        // groupId: "d267d326-86cf-4cf5-b505-f78349a0d998",
        displayName: "dummy",
        userRole: 0, // microsoftTeams.UserTeamRole.Admin,
      },
      app: {
        locale: "ja",
        theme: "default",
        sessionId: "",
        host: {
          name: HostName.teams,
          clientType: HostClientType.desktop,
          sessionId: "",
        },
      },
      page: {
        id: "dummy-entity-id",
        frameContext: FrameContexts.content,
      },
      dialogParameters: {},
    });
    // for groupChat
    // return MicrosoftTeamsSdkAdapter.parseContext({
    //   tid: "8be41b2d-1c3e-4b49-9ec7-550a15315ddd",
    //   teamName: "dummy",
    //   userObjectId: "ccfcff85-00a6-4d11-9b94-e7f345d46ae6",
    //   chatId: "19:meeting_YzJjMjA2ZWItOTY5Yy00YTkyLWEyYWItMmMyMDJhNjc5ODY2@thread.v2", // AirOffice chat
    //   locale: "ja",
    //   entityId: "dummy-entity-id",
    //   userTeamRole: 0, // microsoftTeams.UserTeamRole.Admin,
    // });
  }

  public static mockTeamsContextInfo(): TeamsContextInfo | null {
    if (process.env.NODE_ENV === "development") {
      // process.env.NODE_ENVはwebpackビルド時に常にfalseになるので、uglifyで消される。
      return Mock.devTeamsContextInfo();
    } else {
      return null;
    }
  }
}
