import {
  EventSticker,
  PeerGiftMyCampaignsResponseItem,
  StickerForEventSticker,
} from "@communitio-corp/teams-app-server-api-client-axios";
import { InvalidTeamsAppApiResponseError } from "@/usecase/port/InvalidTeamsAppApiResponseError";
import { CmntSticker } from "@/domain/entity/sticker/CmntSticker";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntStickerForEventSticker } from "@/domain/entity/sticker/CmntStickerForEventSticker";
import { CmntStickerId } from "@/domain/entity/sticker/CmntStickerId";
import { CmntStickerName } from "@/domain/entity/sticker/CmntStickerName";
import { CmntEventStickerName } from "@/domain/entity/sticker/CmntEventStickerName";
import { CmntEventStickerId } from "@/domain/entity/sticker/CmntEventStickerId";
import { CmntPeerGiftMyCampaign } from "@/domain/entity/peergift/CmntPeerGiftMyCampaign";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";
import { CmntPeerGiftCampaignId } from "@/domain/entity/peergift/CmntPeerGiftCampaignId";
import { CmntPeerGiftCampaignDescription } from "@/domain/entity/peergift/CmntPeerGiftCampaignDescription";
import { CmntPeerGiftCampaignName } from "@/domain/entity/peergift/CmntPeerGiftCampaignName";
import { CmntPeerGiftCampaignLimitPrice } from "@/domain/entity/peergift/CmntPeerGiftCampaignLimitPrice";
import { CmntPeerGiftCampaignStatus } from "@/domain/entity/peergift/CmntPeerGiftCampaignStatus";
import { CmntPeerGiftId } from "@/domain/entity/peergift/CmntPeerGiftId";
import { CmntStickerStatus } from "@/domain/entity/sticker/CmntStickerStatus";
import { CmntStickerExample } from "@/domain/entity/sticker/CmntStickerExample";

export type InvalidCmntSticker = {
  __brand: "InvalidCmntSticker";
  message: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInvalidCmntSticker = (v: any): v is InvalidCmntSticker => v?.__brand === "InvalidCmntSticker";

export const isValidSticker = <T extends CmntSticker | CmntStickerForEventSticker | CmntEventSticker>(
  s: T | InvalidCmntSticker,
): s is T => !isInvalidCmntSticker(s);

export const fromApiResponseToCmntStickerForEventSticker = (
  sticker: StickerForEventSticker,
): CmntStickerForEventSticker | InvalidCmntSticker => {
  const errors: string[] = [];
  if (sticker.stickerId === "") {
    errors.push("stickerId must be non-empty string");
  }
  if (sticker.name === "") {
    errors.push("name is undefined");
  }

  if (errors.length > 0) {
    return {
      __brand: "InvalidCmntSticker",
      message: `${errors.join(", ")}`,
    };
  }
  return new CmntStickerForEventSticker(
    new CmntStickerId(sticker.stickerId),
    new CmntStickerName(sticker.name),
    new CmntStickerStatus(sticker.status),
    new CmntStickerExample(sticker.example ?? ""),
  );
};

export const fromApiResponseToCmntEventSticker = (
  eventSticker: EventSticker,
): CmntEventSticker | InvalidCmntSticker => {
  if (eventSticker.stickers === undefined) {
    return {
      __brand: "InvalidCmntSticker",
      message: "stickers is undefined",
    };
  }

  if (typeof eventSticker.id !== "string" || eventSticker.id === "") {
    return {
      __brand: "InvalidCmntSticker",
      message: `id must be non-empty string`,
    };
  }

  if (typeof eventSticker.name !== "string" || eventSticker.name === "") {
    return {
      __brand: "InvalidCmntSticker",
      message: `name must be non-empty string`,
    };
  }

  const fromDateStrOrUndefined = (str: string): CmntValidDateTime | undefined => {
    try {
      return CmntValidDateTime.fromDateStrOrThrow(`${str}`);
    } catch {
      return undefined;
    }
  };

  const eventStickerFrom = fromDateStrOrUndefined(`${eventSticker.from}T00:00:00+09:00`);
  if (eventStickerFrom === undefined) {
    return {
      __brand: "InvalidCmntSticker",
      message: `from is invalid or empty`,
    };
  }

  const eventStickerTo = fromDateStrOrUndefined(`${eventSticker.to}T23:59:59+09:00`);
  if (eventStickerTo === undefined) {
    return {
      __brand: "InvalidCmntSticker",
      message: `to is invalid or empty`,
    };
  }

  // すぐ上でチェックしているので、ここでのassertは型をnarrowingするためのもの。
  // assert(eventSticker.id);
  // assert(eventSticker.name);
  // assert(eventStickerFrom);
  // assert(eventStickerTo);

  const stickers = eventSticker.stickers.map(fromApiResponseToCmntStickerForEventSticker);
  const invalidStickers = stickers.filter(isInvalidCmntSticker);
  const validStickers = stickers.filter(isValidSticker);

  if (invalidStickers.length > 0) {
    return {
      __brand: "InvalidCmntSticker",
      message: `${invalidStickers.length} stickers are invalid: ${invalidStickers
        .map((sticker) => sticker.message)
        .join(", ")}`,
    };
  }

  return new CmntEventSticker(
    new CmntEventStickerId(eventSticker.id),
    new CmntEventStickerName(eventSticker.name),
    eventStickerFrom,
    eventStickerTo,
    validStickers,
  );
};

export const validateCmntEventStickers = (eventStickers: EventSticker[]): CmntEventSticker[] => {
  const validated = eventStickers.map(fromApiResponseToCmntEventSticker);
  const invalid = validated.filter(isInvalidCmntSticker);
  const valid = validated.filter(isValidSticker);

  if (invalid.length > 0) {
    throw new InvalidTeamsAppApiResponseError(
      `${invalid.length} stickers are invalid: ${invalid.map((sticker) => sticker.message).join(", ")}`,
    );
  } else {
    return valid;
  }
};

export const validatePeerGiftMyCampaigns = (resp: PeerGiftMyCampaignsResponseItem): CmntPeerGiftMyCampaign =>
  new CmntPeerGiftMyCampaign(
    new CmntPeerGiftCampaignId(resp.id ?? ""),
    new CmntPeerGiftCampaignName(resp.name ?? ""),
    new CmntPeerGiftCampaignDescription(resp.description ?? ""),
    CmntValidDateTime.fromDateStrOrThrow(`${resp.validFrom}T00:00:00+09:00`),
    CmntValidDateTime.fromDateStrOrThrow(`${resp.validUntil}T23:59:59+09:00`),
    new CmntPeerGiftCampaignLimitPrice(resp.limit ?? 0),
    CmntPeerGiftCampaignStatus.fromName(resp.status ?? ""),
    (resp.gifts ?? ([] as string[])).map((id) => new CmntPeerGiftId(id)),
    resp.coupons ?? 0,
    // あとで割当を減らされたときのことを考慮。
    Math.max(0, (resp.coupons ?? 0) - (resp.used ?? 0)),
    resp.used ?? 0,
  );
