














import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { IEmoji } from "v-emoji-picker/lib/models/Emoji";
import CountableTextarea from "@/common/component/textarea/CountableTextarea.vue";
import MessageToolbar from "@/container/create_card/component/MessageToolbar.vue";

@Component({
  model: {
    prop: "text",
    event: "input",
  },
  components: {
    CountableTextarea,
    MessageToolbar,
  },
})
export default class MessageEditor extends Vue {
  public $refs: {
    textarea: CountableTextarea;
  };
  @Model("input", { type: String, required: true })
  protected readonly text!: string;
  @Prop({ type: String, required: true })
  public readonly placeholder!: string;
  @Prop({ type: Number, required: true })
  public readonly lowerLimit!: number;
  @Prop({ type: Number, required: true })
  public readonly upperLimit!: number;

  get text_(): string {
    return this.text;
  }
  set text_(text: string) {
    this.$emit("input", text);
  }

  public showEmojiMenu = false;

  public onSelectedEmoji(selected: IEmoji): void {
    this.$refs.textarea.append(selected.data);
  }
}
