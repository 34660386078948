



























import { Component, Emit, Vue } from "vue-property-decorator";
import { VEmojiPicker } from "v-emoji-picker";
import { IEmoji } from "v-emoji-picker/lib/models/Emoji";

@Component({
  components: {
    VEmojiPicker,
  },
})
export default class EmojiSelector extends Vue {
  public showEmojiMenu = false;

  @Emit("emoji-selected")
  public onSelectedEmoji(selected: IEmoji): IEmoji {
    this.showEmojiMenu = false;
    return selected;
  }
}
