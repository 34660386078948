import { actions, createMachine, MachineSchema } from "xstate";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { LogicError } from "@/common/lib/errors/LogicError";

const { assign } = actions;

type TContext = {
  cmntTeamName: CmntTeamName | undefined;
  usersInTeam: SimpleTeamsUserInfo[] | undefined;
};

type TEvent =
  | {
      type: "TIMEOUT";
    }
  | {
      type: "SELECT_ANOTHER_TEAMSUITE_TENANT";
    }
  | {
      type: "SWITCH_BASIC_MODE";
    }
  | {
      type: "TEAMSUITE_TENANT_SELECTED";
      cmntTeamName: CmntTeamName;
    };

type TServiceMap = {
  fetchUsersInTeam: {
    data: SimpleTeamsUserInfo[];
  };
  searchUserByGraphApi: {
    data: SimpleTeamsUserInfo[];
  };
};

type TDependency = {
  sendToSentry: (e: unknown) => void;
  fetchUsesInTeam: (cmntTeamName: CmntTeamName) => Promise<SimpleTeamsUserInfo[]>;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const searchUserMachine = (initial: TContext, dependency: TDependency) =>
  /** @xstate-layout N4IgpgJg5mDOIC5SzAQwE4GMAWBVF6AsqjgJYB2YAdAGZgAuZ5UABAK4GwsUuov1oAtgGIIAe0pUKANzEBraigw58YIiWwVqdRpubtO3cr35CEMsZlT1SEgNoAGALqJQABzGxSNia5AAPRABGAGYHAA4qEIB2aJCAJniATiSHABY0kLSAGhAAT2CAVioHQocANhDCwrSgh3i0pOi0gF8W3KUsPAJiJm0GJlYONS4ePgFUETV0MXQqNwAbaxpZwSpOlR6NLVoBvSHDMdNJ83JZKx9yRxckEA8vS79AhCDkkKpyhySQ1LKvpPiuQKCBC4WKDghpVBoPi5Wi6TaHTQXVU6j6u10FAOIyMJgmIgAKgBJQgAUQA8rgCX57t5bOQnsE3h8vj9ShCUoD8ogQjESpCYtFwiFyuVwvFESANt01L09IowAswJgbPpUOQxPRsGoWAShABlNjeMCmcjq+jCAmkgCChH1uCJVoA+laAHLW10Ep360kAGVJAGErQARGmeOm+W7PeGRMXNb5VOFBWJA4JJYp-Dni8qFeLJ8qS6WouVaYQ+-1Bp0e8kEgASpIASi6bXaHc63R7qbdaY8o4g0lzgUF01RM-Foq84SKgkE2u0QBqIHA-EWtuidIMDDijviww96YyXvUgh8J+EhYUkuEMmloqmXsLR5CgnC0qKHM0C-PV7LtpJUBA0jqpgkAsNKLCCGIS57hGDJ9i8OZpFQyRinC4QOCEM5BPeQQZE+EKJAOdSxOEhbIpsv7ogARqgXiYGB5HYBBUFgDBvagM8L41MhSSoUKGFYfeCQOB8orwtOnJpIUIRkcoMpovK6yKsqqqsOqmrauguoGkaAimuabEHvBMTlFQAJBOE55SeUQQ1CE97xF8-IQmkwrHlehSySia7yoZkYccE4T3gAtOUSRmY0Iq1Ok4RijOc4tEAA */
  createMachine(
    {
      context: initial,
      tsTypes: {} as import("./SearchUser.FSM.typegen").Typegen0,
      schema: {} as MachineSchema<TContext, TEvent, TServiceMap>,
      on: {
        SELECT_ANOTHER_TEAMSUITE_TENANT: {
          actions: "clear context",
          target: ".selecting another TeamSuite tenant",
        },
        SWITCH_BASIC_MODE: {
          target: "basic search mode",
        },
      },
      initial: "selecting another TeamSuite tenant",
      states: {
        "fetching users in a team": {
          invoke: {
            src: "fetchUsersInTeam",
            onDone: [
              {
                actions: "assign fetched users to usersInTeam",
                description: "取得したユーザ数が一件以上。",
                cond: "isThereAtLeastOneUserInTheTeam",
                target: "advanced search mode",
              },
              {
                target: "basic search mode",
              },
            ],
            onError: [
              {
                actions: "send error to Sentry",
                target: "basic search mode",
              },
            ],
          },
          on: {
            TIMEOUT: {
              target: "basic search mode",
            },
          },
        },
        "advanced search mode": {
          description:
            "UserSearchBoxFOrAdminPermission コンポーネントを表示して、文字列の部分一致など高度な検索を行うモード。",
        },
        "basic search mode": {
          description: "Graph APIを通じて前方一致の単純な検索を行うモード。",
        },
        "selecting another TeamSuite tenant": {
          on: {
            TEAMSUITE_TENANT_SELECTED: {
              actions: "assign selected team to cmntTeamName",
              target: "fetching users in a team",
            },
          },
        },
      },
      id: "searchUserMachine",
    },
    {
      actions: {
        "assign fetched users to usersInTeam": assign({
          usersInTeam: (_, e) => e.data,
        }),
        "send error to Sentry": (_, e) => {
          dependency.sendToSentry(e);
        },
        "assign selected team to cmntTeamName": assign({
          cmntTeamName: (_, e) => e.cmntTeamName,
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        "clear context": assign((_) => ({
          cmntTeamName: undefined,
          usersInTeam: undefined,
        })),
      },
      services: {
        fetchUsersInTeam: (c) => {
          if (c.cmntTeamName === undefined) {
            throw new LogicError("cmntTeamName must be initilized");
          } else {
            return dependency.fetchUsesInTeam(c.cmntTeamName);
          }
        },
      },
      guards: {
        isThereAtLeastOneUserInTheTeam: (_, e) => {
          return e.data.length > 0;
        },
      },
    },
  );
