import { render, staticRenderFns } from "./RecipientsIconArray.vue?vue&type=template&id=71789604&scoped=true&"
import script from "./RecipientsIconArray.vue?vue&type=script&lang=ts&"
export * from "./RecipientsIconArray.vue?vue&type=script&lang=ts&"
import style0 from "./RecipientsIconArray.vue?vue&type=style&index=0&id=71789604&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71789604",
  null
  
)

export default component.exports