




import Vue from "vue";
import { Component } from "vue-property-decorator";
import * as microsoftTeams from "@microsoft/teams-js";
import querystring from "query-string";

@Component
export default class AuthenticatedPage extends Vue {
  protected async mounted(): Promise<void> {
    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const paramsOrUndef = qs["params"];
    const params = paramsOrUndef ? paramsOrUndef.toString() : "";
    await microsoftTeams.app
      .initialize()
      .then(() => {
        microsoftTeams.authentication.notifySuccess(params);
      })
      .catch(() => {
        //
      });
  }
}
