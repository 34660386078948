import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";

export const ja = {
  common: {
    component: {
      selector: {
        users_selector: {
          search_result: "検索結果",
          search_by_name_and_email: "名前、メールアドレスで検索",
          load_more: "もっと見る",
          no_search_result: "検索結果がありません。",
        },
      },
    },
  },
  general: {
    ok: "OK",
  },
  settings: {
    related_cmnt_team: "連携先TeamStickerチーム",
    already_configured: "(設定済)",
    not_configured: "(未設定)",
    required_for_read_stickers: "TeamStickerと連動させるために必要です。 ※権限設定は管理者が一度行えば動作します。",
    reset_api_permission: "API権限を再設定する",
    set_api_permission: "API権限を設定する",
    not_yet_configured_api_permission: "まだAPI設定がされていません。",
    please_enter_your_team_name: "チーム名を入力してください。",
    please_contact_us_to_create_your_tenant:
      "TeamStickerアカウントをお持ちでない場合はこちらからお問い合わせください。",
    details: "詳細設定",
    permission_settings_for_teams: "Teamsアプリ権限設定",
    permission_settings_for_teams_description:
      "ステッカーをおくる際のユーザー検索をユーザー個別の認証なしでできるようになります。<br/>※この権限設定は管理者が一度行えば動作します。(しない場合は各ユーザーがそれぞれ認証する必要があります)",
    grant_permission_for_teamsticker: "TeamStickerにAPIの実行許可を与える",
    team_suite_team_id: "TeamStickerチームID",
    sticker_share_channel: "ステッカー共有チャネル",
    sticker_share_channel_description:
      "「ステッカー共有チャネル」: TeamStickerと連携できたステッカーがこのチャネルに共有されます。<br/> チャネルIDはブラウザでTeamsを開き、共有したいチャネルを開いた時のURL欄から参照してください。 <br/> 例: URLが「https://teams.microsoft.com/_#/conversations/sticker_shared?threadId=19:20a7b7532e7340bd9d02ee86d2f6187a@thread.skype&ctx=channel」 <br/> の場合、 <br/> <b>「19:20a7b7532e7340bd9d02ee86d2f6187a@thread.skype」</b> <br/> がチャネルIDになります。",
    saved: "保存しました！",
    save: "保存",
    request: "お問い合わせ",
    disconnect: "削除",
    cancel: "取消",
    edit: "編集",
    sticker_share_channel_can_edited_by_admin:
      "ステッカー共有チャネルを編集するには管理者としてログインしている必要があります。TeamStickerの管理者にお問い合わせください。",
    teamsuite_team_can_deleted_by_admin:
      "ステッカー共有チャネルを編集するには管理者としてログインしている必要があります。TeamStickerの管理者にお問い合わせください。",
  },
  auth_for_user: {
    please_signin_by_ms_account_to_use_teamsticker:
      "TeamStickerをご利用になるにはMicrosoftアカウントでサインインしてください。",
    cannot_logged_in: "ログインできませんでした。",
    contact_id: "問い合わせID: ",
    cannot_to_grant_permission:
      "アプリへ実行権限を付与できませんでした。TeamStickerの管理者ユーザーにて再度実行してください。",
    granted_permission: "アプリに実行権限が付与されました。このページを閉じてTeamsの画面に戻ってください。",
    login_modal_will_launch_automatically:
      "ログイン画面が自動的に開きます。もし開かない場合は下のリンクをクリックしてください。",
  },
  create_card: {
    how_to_add_bot: "＜TeamSticker ボット追加方法＞",
    steps:
      "1. メッセージ入力欄に「＠」と入力する。\n" +
      "2. 選択肢の中から「ボットを取得」を選択する。\n" +
      "3. 「ボットの追加」画面で「TeamSticker」を検索する。\n" +
      "4. TeamStickerアイコンをクリックする。",
    after_added_message: "▼▼追加が完了するとチャット内に以下のメッセージが表示されます▼▼",
    sticker: "ステッカー",
    find_receiver: "おくりたい人を検索",
    user_search_placeholder: "ユーザを検索",
    to: "あて先",
    message: "メッセージ",
    card_creating: "カード作成中...",
    send_card: "カードをおくる",
    unavailable_in_this_time: "現在一時的にご利用いただけません。",
    inserting_card: "カードを挿入しています。",
    choose_sticker: "ステッカーを選択",
    send_sticker: "ステッカーをおくる",
    please_select_sticker: "ステッカーを選択してください。",
    please_select_receiver: "おくる相手を選択してください。",
    please_remove_receivers: "あて先は最大5人までです。",
    please_enter_message: "メッセージを入力してください。",
    not_found_valid_receivers: "送付可能なユーザーが見つかりませんでした。",
    failed_to_load_users__maybe_not_complete_setup:
      "ユーザーの取得に失敗しました。アプリのセットアップが完了しているか管理者にご確認ください。",
    failed_to_load_users__maybe_not_installed:
      "ユーザーの取得に失敗しました。アプリがチャットに存在しているかご確認ください。存在していない場合、TeamStickerを追加してください。",
    failed_to_load_users__maybe_network_issue: "ユーザーの取得に失敗しました。ネットワークの状態を確認してください。",
    failed_to_get_stickers: "ステッカーの取得に失敗しました。サポートに連絡してください。",
    unknown_conversation_type: "会話の形式が不明です。\nチャットの場合は「下書き」の状態ではご利用いただけません。",
    next: "つぎへ",
    cancel: "キャンセル",
    send: "おくる",
    progressing: "処理中...",
    receiver_candidate: "候補",
    message_placeholder: "メッセージを入力...",
    search_placeholder: "検索する",
    teams_bot_install_guide: "/img/teams_bot_install_guide.png",
    teams_bot_install_success_message: "/img/teams_bot_install_success_message.png",
    select_team: "カードを投稿するTeamStickerチームを選択してください。",
    loading: "ロード中です。しばらくお待ちください。",
    send_card_in: (name: CmntTeamName): string => `${name.value}でカードを投稿します。`,
    select_another_team: "他のチームでカードを送る。",
    logged_in_teams: "-- ログイン済みチーム一覧から選択してください。 --",
    event_sticker_is_open: (eventSticker: CmntEventSticker): string =>
      `${
        eventSticker.eventStickerName.value
      } ( ${eventSticker.from.toLocalDateString()} ～ ${eventSticker.to.toLocalDateString()}) が開催中！`,
  },
  create_card_v2: {
    unknown_conversation_type: "会話の形式が不明です。\nチャットの場合は「下書き」の状態ではご利用いただけません。",
    loading_recipients: "読込中<br/>&nbsp;",
    click_to_select_recipients: (tap: boolean): string => `${tap ? "タップ" : "クリック"}して送信先を選択`,
    no_candidates: "ユーザが見つかりません。",
    select_recipients: "送信先を選択",
    select_sticker: "ステッカー<br/>を選択",
    send_with_gift: "ギフトを付けておくる",
    rest_gifts: (num: number): string => `残り ${num} 回おくれます`,
    send: "おくる",
    unselect_gift: "ギフトの選択を取り消す",
    gift_can_present_only_one_recipient: "ギフトを送る場合は送信先を一人にしてください",
    not_eligible_for_peer_gift_to_sender: "あなたはギフトを送ることができない設定になっています",
    not_eligible_for_peer_gift_to_recipient: "設定によりこのユーザーにはギフトを送ることができません",
    please_select_recipient: "送信先を選択してください。",
    gift_is_private: "おくった相手以外にギフトは公開されません",
    search_placeholder: "名前、メールアドレスで検索",
    rest_days_until_close: (rest: number): string => `終了まで ${rest} 日`,
    num_of_rest_message_1: "あと",
    num_of_rest_message_2: "回おくれます",
    failed_to_get_stickers:
      "ステッカーの取得に失敗しました。<br/>しばらくしてからお試しいただくか、サポート窓口までお問い合わせください。",
    contact_to_support: "サポート窓口を開く",
    failed_to_initialize:
      "通信エラーが発生しました。<br/>しばらくしてからお試しいただくか、サポート窓口までお問い合わせください。",
    all_receivers: "すべてのメンバー",
    to_all_members_of: (teamName: string): string => `${teamName}のみなさんへ`,
    all_members_of_group_chat_addressee_label: "グループチャットのみなさん",
    all_members_of_team_addressee_label: "チームのみなさん",
    addressee_label_with_to: (addresseeLabel: string): string => `${addresseeLabel}へ`,
  },
  login: {
    not_yet_setup_complete:
      "TeamStickerプラグインがセットアップされていません。<br/>管理者に依頼してセットアップを完了してください。",
    you_can_use_full_features_after_login: "ログインするとTeamStickerのすべての機能を<br/>お楽しみいただけます",
    you_can_use_full_features_after_login_mobile: "ログインするとTeamStickerのすべての<br/>機能をお楽しみいただけます",
    view_sticker_history: "ステッカー履歴を確認",
    view_sticker_reward: "リワードを確認",
    view_sticker_connection: "つながりが見える",
    select_communitio_team: "ログインするTeamStickerのチームを選択してください。",
    login_to_communitio: "TeamStickerにログイン",
    login_to_team: (name: CmntTeamName): string => `${name.value}にログイン`,
    about_communitio: "TeamStickerについて",
    go_static_timeline_tab: "ログイン画面へ",
    navigation_to_static_tab_message_1:
      "ログインするには画面左のナビゲーションバー内<br/>にある「TeamSticker」のアイコンをクリックし、<br/>「タイムライン」タブを開いて下さい。",
    navigation_to_static_tab_message_2:
      "「TeamSticker」アイコンが表示されていない場合は、<br/>「・・・」から「TeamSticker」と検索してください。",
    link_to_teamsuite_web:
      "セキュリティ等の理由でログインできない場合は、以下のリンクからTeamStickerのWebサイトにアクセスしてください。",
    switch_team_hint: "ユーザーアカウントが存在するチームを選択することで利用できる可能性があります。",
  },
  web_proxy: {
    not_setup_related_communitio: "連携先TeamStickerチームが未設定です。",
    please_setup_from_setting_tab: "設定タブから設定してください。",
    open_setting_tab: "設定タブを開く",
  },
  sticker_sent_notification: {
    title_send_with_gift: "ステッカーとギフトをおくりました！",
    title_send_only_sticker: "ステッカーをおくりました！",
    message_with_exist_gift: (numOfSendableGift: number): string => {
      return `あと${numOfSendableGift}回ギフトをおくることができます。<br/>失効してしまう前に感謝とともにギフトを送ってみませんか？`;
    },
    message_with_no_rest_gift: `ギフトをすべておくりました！<br/>さらにステッカーを送ってみましょう！`,
    message_only_sticker: `あなたのありがとうの気持ちを相手に届けることができました。<br/>この調子で感謝称賛の輪を広げましょう！`,
    error: "情報が取得できませんでした。",
  },
};
