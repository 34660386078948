export class InvalidTeamsAppApiResponseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidTeamsAppApiResponseError";
  }
}

export const isInvalidTeamsAppApiResponseError = (error: unknown): error is InvalidTeamsAppApiResponseError => {
  return error instanceof InvalidTeamsAppApiResponseError;
};
