declare const CmntPeerGiftCampaignStatusIDTag: unique symbol;

/**
 * ギフトキャンペーンのスタータス。
 */
export class CmntPeerGiftCampaignStatus {
  public static Open = new CmntPeerGiftCampaignStatus("open");
  public static Closed = new CmntPeerGiftCampaignStatus("closed");

  public static fromName(name: string): CmntPeerGiftCampaignStatus {
    switch (name) {
      case "open": {
        return CmntPeerGiftCampaignStatus.Open;
      }
      case "closed": {
        return CmntPeerGiftCampaignStatus.Closed;
      }
      default: {
        return CmntPeerGiftCampaignStatus.Closed;
      }
    }
  }

  private [CmntPeerGiftCampaignStatusIDTag]: void;

  constructor(public readonly value: string) {}

  public get isOpen(): boolean {
    return this.value === CmntPeerGiftCampaignStatus.Open.value;
  }

  public get isClosed(): boolean {
    return this.value === CmntPeerGiftCampaignStatus.Closed.value;
  }
}
