type WouldBe<T> = { [P in keyof T]?: unknown };

// Objectかどうかを判定する型ガード関数
export const isObject = <T extends Record<string, unknown>>(value: unknown): value is WouldBe<T> =>
  typeof value === "object" && value !== null;

export const isDefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const isNullOrUndefined = <T>(value: T | null | undefined): value is null | undefined =>
  value === null || value === undefined;

interface MapWithSafeGet<K, V, KnownKey extends K> extends GuardedMap<K, V, KnownKey> {
  get(k: KnownKey): V;
  get(k: K): V | undefined;
}

export interface GuardedMap<K, V, K1 extends K = never> extends Map<K, V> {
  has<K2 extends K>(key: K2): this is MapWithSafeGet<K, V, K1 | K2>;
}

export const isString = (value: unknown): value is string => {
  return value !== null && typeof value === "string";
};
