declare const CmntPeerGiftStatusIDTag: unique symbol;

export class CmntPeerGiftStatus {
  public static Open = new CmntPeerGiftStatus("open");
  public static Closed = new CmntPeerGiftStatus("closed");

  public static fromName(name: string): CmntPeerGiftStatus {
    switch (name) {
      case "open": {
        return CmntPeerGiftStatus.Open;
      }
      case "closed": {
        return CmntPeerGiftStatus.Closed;
      }
      default: {
        return CmntPeerGiftStatus.Closed;
      }
    }
  }

  private [CmntPeerGiftStatusIDTag]: void;

  constructor(public readonly value: string) {}

  public get isOpen(): boolean {
    return this.value === CmntPeerGiftStatus.Open.value;
  }

  public get isClosed(): boolean {
    return this.value === CmntPeerGiftStatus.Closed.value;
  }
}
