import { PeergiftApi, TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { Configuration } from "@communitio-corp/teams-app-server-api-client-axios/lib/configuration";
import { EnvVars } from "@/domain/entity/misc/EnvVars";
import { Stage } from "@/common/lib/Stage";
import { JwtToken } from "@/domain/entity/authentication/JwtToken";

export const buildTeamsAppApi = (stage: Stage, token: JwtToken): TeamsAppApi => {
  const configuration = new Configuration({
    baseOptions: {
      headers: {
        Authorization: token.asBearerHeader,
      },
    },
  });
  return new TeamsAppApi(configuration, EnvVars.teamsAppBaseUrl(stage));
};
export const buildPeerGiftAppApi = (stage: Stage, token: JwtToken): PeergiftApi => {
  const configuration = new Configuration({
    baseOptions: {
      headers: {
        Authorization: token.asBearerHeader,
      },
    },
  });
  return new PeergiftApi(configuration, EnvVars.teamsAppBaseUrl(stage));
};
