



































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";

@Component({})
export default class SelectDefaultTeam extends Vue {
  @Prop({ type: Array, required: true })
  protected teams: CmntTeamName[];
  public selected: CmntTeamName | null = null;

  public get canSubmit(): boolean {
    return this.selected !== null;
  }

  public get options(): { value: CmntTeamName; text: string }[] {
    return this.teams.map((t) => ({
      value: t,
      text: t.value,
    }));
  }

  public get errorTooltip(): string | null {
    if (this.selected === null) {
      return this.$m.create_card.select_team.toString();
    } else {
      return null;
    }
  }

  @Emit("on-team-selected")
  public onTeamSelected(): CmntTeamName | null {
    return this.selected;
  }
}
