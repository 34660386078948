


































































































































import { PropType } from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as Raven from "raven-js";
import * as microsoftTeams from "@microsoft/teams-js";
import { PeergiftApi } from "@communitio-corp/teams-app-server-api-client-axios";
import params from "../../../params";
import RecipientSelector from "@/container/create_card/component/RecipientSelector.vue";
import CardContentEditor from "@/container/create_card/component/CardContentEditor.vue";
import GiftSelector from "@/container/create_card/component/GiftSelector.vue";
import ProgressingButton from "@/common/component/button/ProgressingButton.vue";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntSticker } from "@/domain/entity/sticker/CmntSticker";
import { CmntStickersV2 } from "@/domain/entity/sticker/CmntStickersV2";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { CardMessage } from "@/domain/entity/sticker/CardMessage";
import { CmntPeerGiftMyCampaign } from "@/domain/entity/peergift/CmntPeerGiftMyCampaign";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { CmntPeerGift } from "@/domain/entity/peergift/CmntPeerGift";
import { SearchMode } from "@/container/create_card/MessageExtensionLogic";
import { LogicError } from "@/common/lib/errors/LogicError";
import { TeamsUserName } from "@/domain/entity/teams/TeamsUserName";
import { TeamsUserPrincipalName } from "@/domain/entity/teams/TeamsUserPrincipalName";
import { nonNullable } from "@/common/lib/Utilities";
import { TeamsSearchUsersPort } from "@/usecase/port/TeamsSearchUsersPort";
import { StringUtils } from "@/common/lib/StringUtils";
import { isDefined } from "@/common/lib/TypeUtilities";
import { buildPeerGiftAppApi } from "@/usecase/interactor/TeamsAppApiBuilder";
import { stageBasedOnHref } from "@/common/lib/WindowUtil";
import { JwtToken } from "@/domain/entity/authentication/JwtToken";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";
import RecipientsIconArray from "@/container/create_card/component/RecipientsIconArray.vue";
import type { ConversationType } from "@/domain/entity/teams/TeamsContextInfo";
import { CmntUserIdAndName } from "@/domain/entity/user/CmntUserIdAndName";
import { CmntUserName } from "@/domain/entity/user/CmntUserName";
import { TeamsTeamName } from "@/domain/entity/teams/TeamsTeamName";
import { ILogger } from "@/common/lib/logger/ILogger";

// FIXME:
// サーバー側と同期を取ったが、そもそも必要か？
// ※ サーバー側の型定義には含まれている kind は
// 元々の実装では data に含まれていなかったため、含めていない
// ※ cmntTeamName については、空文字で初期化されており
// undefined にはならないはずだが、サーバー側は optional として宣言されている
// cmntTeamName は空文字で初期化せず undefined にするべきか？
type SubmitActionData = {
  cardId?: string;
  sticker: {
    id: string;
    name: string;
  };
  cmntTeamName?: string;
  from: SimpleTeamsUserInfo;
  receivers: SimpleTeamsUserInfo[];
  purpose: string;
  gift?: {
    campaignId: string;
    giftId: string;
  };
  addresseeLabel?: string;
};

@Component({
  components: {
    CardContentEditor,
    RecipientSelector,
    GiftSelector,
    ProgressingButton,
    RecipientsIconArray,
  },
})
export default class CreateCardV2 extends Vue {
  $refs: {
    recipientSelectorContainer: HTMLDivElement;
  };

  @Prop({ type: Array, required: true })
  public campaigns!: CmntPeerGiftMyCampaign[];
  @Prop({ type: CmntTeamName })
  public cmntTeamName: CmntTeamName;
  @Prop({ type: CmntEventSticker, required: false })
  public eventSticker: CmntEventSticker | null;
  @Prop({ type: SimpleTeamsUserInfoV2, required: true })
  public fromUser!: SimpleTeamsUserInfoV2;
  @Prop({ type: Array, required: true }) public gifts!: CmntPeerGift[];
  @Prop({ type: Object, required: true }) public stickers!: CmntStickersV2;
  @Prop({ type: TeamsTenantId, required: true })
  public teamsTenantId!: TeamsTenantId;
  @Prop({ type: TeamsTeamName, required: true })
  public teamsTeamName!: TeamsTeamName;
  @Prop({ type: Object as () => SearchMode, required: true })
  searchMode: SearchMode;
  @Prop({ type: Boolean, required: true })
  protected readonly isEligibleForPeerGift: boolean;
  @Prop({ type: Boolean, required: true })
  public useWideStickerSelector: boolean;
  @Prop({ type: JwtToken, required: true })
  public jwtToken: JwtToken;
  @Prop({ type: String as PropType<ConversationType>, required: true })
  protected readonly conversationType: ConversationType;
  @Prop({ type: Boolean, required: true })
  protected readonly isForAllMembers: boolean;
  @Prop({ type: Array, required: true })
  public otherUsersInfo: SimpleTeamsUserInfo[];
  @Prop({ type: Object as PropType<ILogger>, required: true })
  public readonly logger: ILogger;

  public get otherUsersIdAndName(): CmntUserIdAndName[] {
    return this.otherUsersInfo.map((userInfo) => {
      return new CmntUserIdAndName(
        new CmntUserId(userInfo.cmntUserId),
        new CmntUserName(userInfo.displayName)
      );
    });
  }

  @Watch("isForAllMembers")
  private onIsForAllMemberChanged(isForAllMembers: boolean): void {
    if (isForAllMembers) {
      if (this.message.length === 0) {
        this.message = this.defaultMessageForAllMembers;
      }
    }
  }

  public message = "";
  public sticker: CmntSticker | null = null;
  protected campaign: CmntPeerGiftMyCampaign | null = null;
  public gift: CmntPeerGift | null = null;
  public selectedUsers_: CmntUserIdAndName[] = [];

  public get selectedUsers(): CmntUserIdAndName[] {
    if (this.isForAllMembers) {
      return this.otherUsersInfo.map(
        (userInfo) =>
          new CmntUserIdAndName(
            new CmntUserId(userInfo.cmntUserId),
            new CmntUserName(userInfo.displayName)
          )
      );
    } else {
      return this.selectedUsers_;
    }
  }

  public set selectedUsers(selectedUsers: CmntUserIdAndName[]) {
    if (!this.isForAllMembers) {
      this.selectedUsers_ = [...selectedUsers];
    }
  }

  public sendProgressing = false;
  public selectedUsersInBasicSearchMode: SimpleTeamsUserInfoV2[] = [];
  public recipientEligibilityForPeerGift: { [key: string]: boolean } = {};

  private peergiftApi: PeergiftApi | null = null;

  public get isPeerGiftEligible(): boolean {
    return (
      this.isEligibleForPeerGift &&
      this.selectedUsers.length === 1 &&
      this.recipientEligibilityForPeerGift[this.selectedUsers[0].id.value]
    );
  }

  public get giftDisabledTooltip(): string {
    if (this.selectedUsers.length > 1) {
      return this.$m.create_card_v2.gift_can_present_only_one_recipient;
    }

    if (!this.isEligibleForPeerGift) {
      return this.$m.create_card_v2.not_eligible_for_peer_gift_to_sender;
    }

    if (
      this.selectedUsers.length === 1 &&
      !this.recipientEligibilityForPeerGift[this.selectedUsers[0].id.value]
    ) {
      return this.$m.create_card_v2.not_eligible_for_peer_gift_to_recipient;
    }

    return this.$m.create_card_v2.please_select_recipient;
  }

  public get canSendGift(): boolean {
    return (
      this.isEligibleForPeerGift &&
      this.campaigns.filter((c) => c.numOfRest > 0).length > 0
    );
  }

  public get numOfSelectableRecipients(): number {
    return this.gift ? 1 : 20;
  }

  public get numOfRestGifts(): number {
    return this.campaigns.reduce(
      (accum, campaign) => accum + campaign.numOfRest,
      0
    );
  }

  public get isReceiverValid(): boolean {
    return this.selectedUsers.length > 0;
  }

  public get canSendCard(): boolean {
    if (this.campaign && this.gift) {
      return (
        this.isReceiverValid &&
        new CardMessage(this.message).isValid &&
        this.message.length > 0 &&
        this.sticker !== null
      );
    } else {
      return (
        this.isReceiverValid &&
        new CardMessage(this.message).isValid &&
        this.sticker !== null
      );
    }
  }

  protected findSelectedSticker(stickerId: string): CmntSticker | null {
    const allStickers = [
      ...this.stickers.defaultCategory.stickers,
      ...this.stickers.categories.flatMap((category) => category.stickers),
      ...(this.eventSticker?.stickers.map((s) => s.toSticker()) ?? []),
    ];
    return allStickers.find((s) => s.id.id === stickerId) ?? null;
  }

  public cardWidth = 0;
  private cardResizeObserver = new ResizeObserver(this.onResizeCard);

  private onResizeCard(resizeObserverEntries: ResizeObserverEntry[]): void {
    const resizeObserverEntry = resizeObserverEntries[0];
    this.cardWidth = resizeObserverEntry.borderBoxSize[0].inlineSize;
  }

  public mounted(): void {
    this.cardResizeObserver.observe(this.$refs.recipientSelectorContainer);

    this.peergiftApi = buildPeerGiftAppApi(stageBasedOnHref(), this.jwtToken);

    this.cardWidth = this.$refs.recipientSelectorContainer.clientWidth;

    this.onChangedTeamsUser();
  }

  public beforeDestroy(): void {
    this.cardResizeObserver.unobserve(this.$refs.recipientSelectorContainer);
  }

  public onGiftSelected(selected: {
    campaign: CmntPeerGiftMyCampaign;
    gift: CmntPeerGift;
  }): void {
    this.gift = selected.gift;
    this.campaign = selected.campaign;
  }

  public onGiftUnSelected(): void {
    this.gift = null;
    this.campaign = null;
  }

  get teamsUsersInAdvancedSearchMode(): SimpleTeamsUserInfoV2[] | undefined {
    switch (this.searchMode.type) {
      case "advanced":
        return this.searchMode.users.map(
          (u) =>
            new SimpleTeamsUserInfoV2(
              new TeamsAAAObjectUserId(u.id),
              new TeamsUserName(u.displayName),
              new TeamsUserPrincipalName(u.userPrincipalName),
              new CmntUserId(u.cmntUserId ?? u.id)
            )
        );
      default:
        return undefined;
    }
  }

  /**
   * searchMode.type === "basic"
   */
  get teamsSearchUsersPort(): TeamsSearchUsersPort {
    switch (this.searchMode.type) {
      case "advanced":
        throw new LogicError("searchMode must be basic.");
      case "basic":
        return this.searchMode.searchUsers;
      default:
        throw new LogicError("should not reach here");
    }
  }

  get receivers(): SimpleTeamsUserInfo[] {
    if (this.isForAllMembers) return this.otherUsersInfo;
    switch (this.searchMode.type) {
      case "advanced":
        return this.selectedUsers
          .map((su) =>
            this.teamsUsersInAdvancedSearchMode?.find(
              (u) => u.cmntUserId.value === su.id.value
            )
          )
          .map((user) => user?.toLegacyInterface)
          .filter(nonNullable);
      case "basic":
        return this.selectedUsersInBasicSearchMode.map(
          (u) => u.toLegacyInterface
        );
      default:
        throw new LogicError("should not reach here");
    }
  }

  public formattedExample(example: string | null): string {
    return example ? example.toString().replace(/[\r\n]/g, "<br/>") : "";
  }

  public get defaultMessageForAllMembers(): string {
    const add2LineFeeds = (label: string): string => `${label}\n\n`;

    if (this.teamsTeamName.value.length > 0) {
      return add2LineFeeds(
        this.$m.create_card_v2.to_all_members_of(this.teamsTeamName.value)
      );
    } else if (this.addresseeLabel) {
      return add2LineFeeds(
        this.$m.create_card_v2.addressee_label_with_to(this.addresseeLabel)
      );
    }
    return "";
  }

  public get addresseeLabel(): string | undefined {
    if (this.isForAllMembers) {
      switch (this.conversationType) {
        case "groupChat":
        case "meeting":
          return this.$m.create_card_v2
            .all_members_of_group_chat_addressee_label;
        case "channel":
          return this.$m.create_card_v2.all_members_of_team_addressee_label;
      }
    }
    return undefined;
  }

  public onSubmitClicked(): void {
    if (this.sendProgressing) {
      return;
    }

    if (this.sticker === null) {
      // ありえない
      return;
    }

    this.sendProgressing = true;

    // server側のSubmitActionDataと形式をあわせること。
    const data: SubmitActionData = {
      cardId: StringUtils.generateUUID(),
      sticker: {
        id: this.sticker.id.id,
        name: this.sticker.name.value,
      },
      cmntTeamName: this.cmntTeamName?.value ?? undefined,
      from: this.fromUser.toLegacyInterface,
      receivers: this.receivers,
      purpose: this.message,
      gift:
        this.gift && this.campaign
          ? {
              campaignId: this.campaign.campaignId.id,
              giftId: this.gift.giftId.id,
            }
          : undefined,
      addresseeLabel: this.addresseeLabel,
    };
    Raven.captureMessage(
      `<${params.env}/pages/create_card_v2> create card submitting. (${this.cmntTeamName?.value})`,
      {
        level: "info",
        extra: {
          data,
        },
      }
    );

    microsoftTeams.dialog.url.submit(data);
  }

  public onCancel(): void {
    microsoftTeams.dialog.url.submit({ command: "close" });
  }

  @Watch("searchMode")
  private onChangedTeamsUser(): void {
    if (
      this.searchMode.type === "advanced" &&
      this.searchMode.users.length === 1
    ) {
      this.selectedUsers_ = [
        new CmntUserIdAndName(
          new CmntUserId(this.searchMode.users[0].cmntUserId),
          new CmntUserName(this.searchMode.users[0].displayName)
        ),
      ];
    }
  }

  @Watch("selectedUsers")
  private async onChangedSelectedUserIds(): Promise<void> {
    if (isDefined(this.peergiftApi) && this.selectedUsers.length === 1) {
      const selectedUserId = this.selectedUsers[0].id.value;
      const found = this.teamsUsersInAdvancedSearchMode?.find(
        (u) => u.cmntUserId.value === this.selectedUsers[0].id.value
      );
      if (
        found &&
        !isDefined(this.recipientEligibilityForPeerGift[selectedUserId])
      ) {
        await this.peergiftApi
          .getPeerGiftEligibility({
            tenantId: this.teamsTenantId.value,
            objectId: found.objectId.value,
            cmntTeamId: this.cmntTeamName.value,
          })
          .then((ret) => {
            const eligibility = ret.data.eligible ?? false;
            Vue.set(
              this.recipientEligibilityForPeerGift,
              selectedUserId,
              eligibility
            );
          });
      }
    }
  }

  public get showUserSelector(): boolean {
    return this.searchMode.type === "basic" || this.usersWithoutMe.length > 0;
  }

  public get usersWithoutMe(): SimpleTeamsUserInfoV2[] {
    if (!this.teamsUsersInAdvancedSearchMode) return [];
    return this.teamsUsersInAdvancedSearchMode.filter(
      (user) => user.objectId.value !== this.fromUser.objectId.value
    );
  }
}
