declare const CmntPeerGiftConfigCodeIDTag: unique symbol;

/**
 * Gifteeシステム上のギフト設定コード
 */
export class CmntPeerGiftConfigCode {
  private [CmntPeerGiftConfigCodeIDTag]: void;

  constructor(public readonly code: string) {}
}
