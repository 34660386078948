declare const CmntPeerGiftTypeIDTag: unique symbol;

export class CmntPeerGiftType {
  public static Card = new CmntPeerGiftType("card");
  public static Box = new CmntPeerGiftType("box");

  public static fromValue(value: string): CmntPeerGiftType {
    if (value === "box") {
      return this.Box;
    } else {
      return this.Card;
    }
  }

  private [CmntPeerGiftTypeIDTag]: void;

  constructor(public readonly type: "card" | "box") {}
}
