import { z } from "zod";

export class CmntStickerExample {
  public maxLimit = 300;

  private className = "CmntStickerExample";

  constructor(public value: string) {}

  public isValid(): boolean {
    return this.length() > 0 && this.length() <= this.maxLimit;
  }

  public length(): number {
    return this.value.trim().length;
  }
}

// 今後置き換えていく
export const ZodCmntStickerExample = {
  schema: z.string().brand<"teamsuite.StickerExample" | { readonly brand: unique symbol }["brand"]>().nullish(),
};

export type ZodCmntStickerExample = z.infer<typeof ZodCmntStickerExample.schema>;
