import { dom, library } from "@fortawesome/fontawesome-svg-core";
// x falEyeSlash
// x falTimes
// x falTimesCircle
// x falSadTear
// x falSmile
// x falGift
// x farExternalLink
// x farChevronUp/Down
// x fadSpinner
import {
  faEyeSlash as faEyeSlashLight,
  faGift as faGiftLight,
  faSadTear as faSadTearLight,
  faSmile as faSmileLight,
  faTimes as faTimesLight,
  faTimesCircle as faTimesCircleLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDown as faChevronDownRegular,
  faChevronUp as faChevronUpRegular,
  faExternalLink as faExternalLinkRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { faSpinner as faSpinnerDuotone } from "@fortawesome/pro-duotone-svg-icons";
import Vue from "vue";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";

library.add(
  // Light
  faGiftLight,
  faTimesLight,
  faTimesCircleLight,
  faEyeSlashLight,
  faSadTearLight,
  faSmileLight,
  // Regular
  faExternalLinkRegular,
  faChevronUpRegular,
  faChevronDownRegular,
  // Duotone
  faSpinnerDuotone,
);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.component("FontAwesomeLayers", FontAwesomeLayers);
Vue.component("FontAwesomeLayersText", FontAwesomeLayersText);

dom.watch();
