import { CmntUserId } from "@/domain/entity/user/CmntUserId";
import { CmntUserName } from "@/domain/entity/user/CmntUserName";

declare const CmntUserIdAndNameTag: unique symbol;

export class CmntUserIdAndName {
  private [CmntUserIdAndNameTag]: void;

  constructor(public readonly id: CmntUserId, public readonly name: CmntUserName) {}
}
