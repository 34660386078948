import { PeergiftApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { validatePeerGiftMyCampaigns } from "@/usecase/interactor/conversions";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import {
  ITeamStickerGetMyCampaignAndGiftsPort,
  TeamStickerGetMyCampaignAndGifts,
} from "@/usecase/port/TeamStickerGetMyCampaignPort";
import { fromPeerGift } from "@/domain/entity/peergift/conversion";

export class TeamsAppAdapterForTeamStickerGetMyCampaignPort implements ITeamStickerGetMyCampaignAndGiftsPort {
  constructor(
    private readonly peergiftApi: PeergiftApi,
    readonly teamsTenantId: TeamsTenantId,
    readonly cmntTeamName: CmntTeamName | null,
  ) {}

  public get(aaaObjectId: TeamsAAAObjectUserId): Promise<TeamStickerGetMyCampaignAndGifts> {
    return this.peergiftApi
      .getPeerGiftMyCampaigns({
        tenantId: this.teamsTenantId.value,
        objectId: aaaObjectId.value,
        cmntTeamId: this.cmntTeamName?.value,
      })
      .then((data) => {
        return {
          gifts: data.data.gifts?.items?.map(fromPeerGift) ?? [],
          campaigns: data.data.campaigns?.items?.map(validatePeerGiftMyCampaigns) ?? [],
        };
      })
      .catch(() => {
        // ピアギフト情報取得失敗時は送れない。
        return {
          gifts: [],
          campaigns: [],
        };
      });
  }
}
