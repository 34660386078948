import * as Raven from "raven-js";
import { ILogger } from "@/common/lib/logger/ILogger";

export class RavenLogger implements ILogger {
  error(error: unknown, extra: Record<string, unknown>): void {
    Raven.setExtraContext(extra).captureException(error).setExtraContext(undefined);
  }

  info(msg: string, extra: Record<string, unknown>): void {
    Raven.setExtraContext(extra).captureMessage(msg).setExtraContext(undefined);
  }
}
