import params from "../../../params";
import { EnvName } from "@/domain/entity/misc/EnvName";

export class EnvUtils {
  public static getEnvironment(): EnvName {
    const team = window.location.hostname
      .replace(".teamsuite.net", "")
      .replace(".communitio.tech", "")
      .replace(".communitio.net", "");
    const envNameFromHost: string = team.indexOf(".") > 0 ? team.substr(team.indexOf(".") + 1) : "prd";

    const devHostRegexps = ["^localhost$", "^victorious-coast-08adab600-[0-9]+.eastasia.azurestaticapps.net$"];
    const isDev = devHostRegexps.some((hostRegexp) => window.location.hostname.match(hostRegexp));
    if (isDev) {
      return new EnvName("dev");
    } else if (envNameFromHost === "local") {
      // local と dev-local は params.env で判別される。
      if (params.env === "dev-local") {
        return new EnvName("dev");
      } else {
        return new EnvName("local");
      }
    } else {
      if (EnvName.isValidEnvName(envNameFromHost)) {
        return new EnvName(envNameFromHost);
      } else {
        return new EnvName("prd");
      }
    }
  }
}
