












import { Component, Prop, Vue } from "vue-property-decorator";
import { NumUtils } from "@/common/lib/NumUtils";

@Component
export default class MessageLengthIndicator extends Vue {
  @Prop({ type: Number, required: true })
  public readonly numOfWritten: number;
  @Prop({ type: Number, required: true }) public readonly numOfLimit: number;

  public NumUtils = NumUtils;
  // protected get writtenRatio(): number {
  //   return Math.round((this.numOfWritten / this.numOfLimit) * 10000) / 100;
  // }

  public get indicatorEmoji(): string {
    if (this.numOfWritten > 160) {
      return "🤗";
    } else if (this.numOfWritten > 80) {
      return "😆";
    } else if (this.numOfWritten > 40) {
      return "😄";
    } else if (this.numOfWritten > 20) {
      return "😐";
    } else {
      return "🙄";
    }
  }
}
