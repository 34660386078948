import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export class CmntDuration {
  public static fromSeconds(num: number | undefined): CmntDuration {
    return new CmntDuration(dayjs.duration(num ?? 0, "seconds"));
  }

  constructor(public readonly d: duration.Duration) {}

  public asDays(): number {
    return Math.floor(this.d.asSeconds() / 60 / 60 / 24);
  }
}
