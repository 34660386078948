import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { ITeamStickerGetStickersPort } from "@/usecase/port/TeamStickerGetStickersPort";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { CmntStickersV2 } from "@/domain/entity/sticker/CmntStickersV2";

export class TeamsAppAdapterForTeamStickerGetStickerPort implements ITeamStickerGetStickersPort {
  constructor(
    private readonly teamsAppApi: TeamsAppApi,
    readonly teamsTenantId: TeamsTenantId,
    readonly cmntTeamName: CmntTeamName | null,
  ) {}

  public get(aaaObjectId: TeamsAAAObjectUserId): Promise<CmntStickersV2> {
    return this.teamsAppApi
      .getStickersV2({
        tenantId: this.teamsTenantId.value,
        objectId: aaaObjectId.value,
        cmntTeamId: this.cmntTeamName?.value,
      })
      .then((ret) => {
        return CmntStickersV2.schema.parse(ret.data);
      });
  }
}
