import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";

export const en = {
  common: {
    component: {
      selector: {
        users_selector: {
          search_result: "Search results",
          search_by_name_and_email: "Search by name or email",
          load_more: "More",
          no_search_result: "No search results",
        },
      },
    },
  },
  general: {
    ok: "OK",
  },
  settings: {
    related_cmnt_team: "Related communitio team",
    already_configured: "(configured)",
    not_configured: "(not configured)",
    required_for_read_stickers:
      "Required to get the sticker information. *Authority setting will work if the administrator makes it once.",
    reset_api_permission: "Reset API setting",
    set_api_permission: "Set API setting",
    not_yet_configured_api_permission: "API setting is not done yet.",
    please_enter_your_team_name: "Please enter a team name.",
    please_contact_us_to_create_your_tenant: "If you do not have a TeamSticker account, please contact us here.",
    details: "Advanced Settings",
    permission_settings_for_teams: "Teams app permission settings",
    permission_settings_for_teams_description:
      "It will be possible to search users when sending stickers without individual authentication. <br/> * This permission setting works only once the administrator has set it. (If not, each user must authenticate separately)",
    grant_permission_for_teamsticker: "Grant TeamSticker permission to execute API",
    team_suite_team_id: "TeamSticker Team ID",
    sticker_share_channel: "Sticker sharing channel",
    sticker_share_channel_description:
      'Sticker sharing channel: Stickers that could work with TeamSticker will be shared on this channel.  <br/> To get channel ID, open Teams in a browser and refer to the URL when opening the channel you want to share.  <br/> Example: URL is "https://teams.microsoft.com/_#/conversations/sticker_shared?threadId=19:20a7b7532e7340bd9d02ee86d2f6187a@thread.skype&ctx=channel" <br/> in the case of, <br/> <b>"19:20a7b7532e7340bd9d02ee86d2f6187a@thread.skype"</b> <br/> is the channel ID.',
    saved: "Saved!",
    save: "Save",
    request: "Contact",
    disconnect: "remove",
    cancel: "cancel",
    edit: "Edit",
    sticker_share_channel_can_edited_by_admin:
      "You must be logged in as an administrator to edit the sticker sharing channel. Contact your TeamSticker administrator.",
    teamsuite_team_can_deleted_by_admin:
      "You must be logged in as an administrator to delete the team. Contact your TeamSticker administrator.",
  },
  auth_for_user: {
    please_signin_by_ms_account_to_use_teamsticker: "Sign in with your Microsoft account to use Team Sticker.",
    cannot_logged_in: "Unable to login to communitio.",
    contact_id: "Contact ID: ",
    cannot_to_grant_permission:
      "Could not grant execute permission to the app. Please execute again as an administrator user of communitio.",
    granted_permission:
      "The app has been granted execute permission. Please close this page and return to the Teams screen.",
    login_modal_will_launch_automatically:
      "The login screen will be displayed automatically. If it does not appear, please click the link below.",
  },
  create_card: {
    how_to_add_bot: "＜How to add TeamSticker bot＞",
    steps:
      "1. Enter '@' in the message input field.\n" +
      "2. Select 'Get bots' from the options.\n" +
      "3. Search for 'TeamSticker' on the 'Add a Bot' screen.\n" +
      "4. Click the Team Sticker icon.",
    after_added_message: "++ When the addition is completed, the following message will be displayed in the chat ++",
    sticker: "Sticker",
    find_receiver: "Search user",
    user_search_placeholder: "Search user",
    to: "To",
    message: "Message",
    card_creating: "Creating a card...",
    send_card: "Send card",
    unavailable_in_this_time: "Currently temporarily unavailable.",
    inserting_card: "Inserting a card.",
    choose_sticker: "Choose a sticker",
    send_sticker: "Send a sticker",
    please_select_sticker: "Please select a sticker.",
    please_select_receiver: "Please select a card receiver.",
    please_remove_receivers: "You can send a card to maximum of 5 users at the same time.",
    please_enter_message: "Please enter message.",
    not_found_valid_receivers: "No users were found to send a card.",
    failed_to_load_users__maybe_not_complete_setup:
      "Failed to get users. Please check with your admin to see if the app setup is complete.",
    failed_to_load_users__maybe_not_installed:
      "Failed to get users. Please check if the app is in chat. If not, please add TeamSticker.",
    failed_to_load_users__maybe_network_issue: "Failed to get users. Please check the network status.",
    failed_to_get_stickers: "Failed to get stickers. Contact support for help.",
    unknown_conversation_type:
      "The format of the conversation is unknown.\nIn case of chat, it is not available in the state of 'draft'.",
    next: "Next",
    cancel: "Cancel",
    send: "Send",
    progressing: "Progressing",
    receiver_candidate: "Candidate",
    message_placeholder: "Enter message...",
    search_placeholder: "Search user",
    teams_bot_install_guide: "/img/teams_bot_install_guide_en.png",
    teams_bot_install_success_message: "/img/teams_bot_install_success_message_en.png",
    select_team: "Select a TeamSticker team where you send a sticker.",
    loading: "loading",
    send_card_in: (name: CmntTeamName): string => `Send card in ${name.value}`,
    select_another_team: "Switch a team.",
    logged_in_teams: "-- Please select a team --",
    event_sticker_is_open: (eventSticker: CmntEventSticker): string =>
      `${
        eventSticker.eventStickerName.value
      } ( ${eventSticker.from.toLocalDateString()} ～ ${eventSticker.to.toLocalDateString()}) is Opened!`,
  },
  create_card_v2: {
    unknown_conversation_type:
      "The format of the conversation is unknown.\nIn case of chat, it is not available in the state of 'draft'.",
    loading_recipients: "Loading<br/>&nbsp;",
    click_to_select_recipients: (tap: boolean): string => `${tap ? "Tap" : "Click"} to select recipients`,
    no_candidates: "no user is found",
    select_recipients: "Select Recipient",
    select_sticker: "Select<br/>Sticker",
    send_with_gift: "Send with gift",
    rest_gifts: (num: number): string => `You can send ${num} times`,
    send: "Send",
    unselect_gift: "Unselect Gift",
    gift_can_present_only_one_recipient: "Gift can present only one recipient",
    // "あなたはギフトを送ることができない設定になっています"
    not_eligible_for_peer_gift_to_sender: "You are not eligible to send a gift",
    // "設定によりこのユーザーにはギフトを送ることができません"
    not_eligible_for_peer_gift_to_recipient: "This user is not eligible to receive a gift",
    // "送信先を選択してください。"
    please_select_recipient: "Please select recipient",
    gift_is_private: "Gift is visible only recipient",
    search_placeholder: "Search by name or email",
    rest_days_until_close: (rest: number): string => `${rest} days until close`,
    num_of_rest_message_1: "You can send",
    num_of_rest_message_2: "times",
    failed_to_get_stickers: "Failed to load stickers.<br/>Please retry later or contact to support.",
    contact_to_support: "Contact to support",
    failed_to_initialize: "Connection error.<br/>Please retry later or contact to support.",
    all_receivers: "All members",
    to_all_members_of: (teamName: string): string => `To ${teamName} members,`,
    all_members_of_group_chat_addressee_label: "this group chat members,",
    all_members_of_team_addressee_label: "this team members,",
    addressee_label_with_to: (addresseeLabel: string): string => `To ${addresseeLabel}`,
  },
  login: {
    not_yet_setup_complete:
      "TeamSticker plugin is not set up.<br/>Please ask your teams administrator to complete the setup.",
    you_can_use_full_features_after_login: "Login to enjoy all the features of Team Sticker",
    you_can_use_full_features_after_login_mobile: "Login to enjoy all the features of Team Sticker",
    view_sticker_history: "Check sticker history",
    view_sticker_reward: "Check your reward",
    view_sticker_connection: "View connections",
    select_communitio_team: "Select a Communitio team",
    login_to_communitio: "Login to communitio",
    login_to_team: (name: CmntTeamName): string => `Login to ${name.value}`,
    about_communitio: "About communitio",
    go_static_timeline_tab: "Open login window",
    navigation_to_static_tab_message_1: `To log in, click the "TeamSticker" icon in the navigation bar on the left side of the screen and open the "Timeline" tab.`,
    navigation_to_static_tab_message_2: `If you don't see the "TeamSticker" icon, search for "Team Sticker" from "...".`,
    link_to_teamsuite_web:
      "If you cannot log in for security reasons, please access the TeamSticker website from the link below.",
    switch_team_hint: "You may be able to use it by selecting a team where your user account exists.",
  },
  web_proxy: {
    not_setup_related_communitio: "The related communitio team has not been configured.",
    please_setup_from_setting_tab: "Please configure it from Settings tab.",
    open_setting_tab: "Open Settings tab",
  },
  sticker_sent_notification: {
    title_send_with_gift: "You sent sticker and gift!",
    title_send_only_sticker: "You sent sticker!",
    message_with_exist_gift: (numOfSendableGift: number): string => {
      return `You can send the gift ${numOfSendableGift} more times. <br/> Why don't you send a gift with gratitude before it expires?`;
    },
    message_with_no_rest_gift: `You sent all the gifts!<br/>Let's hope you enjoy the sticker!`,
    message_only_sticker: `You were able to convey your gratitude to the other party.<br/>Let's expand the circle of gratitude and praise in this condition!`,
    error: "Failed to fetch the information.",
  },
};
