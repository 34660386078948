import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ja";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale("ja");
dayjs.tz.setDefault("Asia/Tokyo");

export class CmntDateTime {
  protected constructor(public readonly m: dayjs.Dayjs) {}
}
