import { z } from "zod";

declare const CmntStickerIdIDTag: unique symbol;

export class CmntStickerId {
  public maxLimit = 10;

  private [CmntStickerIdIDTag]: void;

  constructor(public readonly id: string) {}

  public isValid(): boolean {
    const m = this.id.match(/^[a-zA-Z0-9_-]*$/);
    const validChars = m && m.length > 0 ? m[0] === this.id : false;
    return this.length() > 0 && this.length() <= this.maxLimit && validChars;
  }

  public length(): number {
    return this.id.trim().length;
  }
}

export const ZodCmntStickerId = {
  schema: z
    .string()
    .min(1, "Id must be at least 1 character long")
    .max(15, "Id must be no more than 15 characters long"),
};
export type ZodCmntStickerId = z.infer<typeof ZodCmntStickerId.schema>;
