























































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { StickerStatus } from "@communitio-corp/teams-app-server-api-client-axios";
import { CmntSticker } from "@/domain/entity/sticker/CmntSticker";
import { CmntStickersV2 } from "@/domain/entity/sticker/CmntStickersV2";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import ReplaceableImage from "@/common/component/image/ReplaceableImage.vue";
import { UrlUtils } from "@/common/lib/UrlUtils";
import { EnvUtils } from "@/common/lib/EnvUtils";
import { CmntStickerForEventSticker } from "@/domain/entity/sticker/CmntStickerForEventSticker";
import { CmntStickerId } from "@/domain/entity/sticker/CmntStickerId";

@Component({
  components: {
    ReplaceableImage,
  },
})
export default class StickerSelector extends Vue {
  @Prop({ type: Object, required: true }) public stickers!: CmntStickersV2;
  @Prop({ type: CmntEventSticker, required: false })
  public eventSticker: CmntEventSticker;
  @Prop({ type: CmntTeamName, required: false })
  public cmntTeamName: CmntTeamName | null;
  @Prop({ type: Object, required: false })
  public sticker: CmntSticker | null;
  @Prop({ type: Boolean, required: true })
  public useWideStickerSelector: boolean;

  public showStickersOverlay = false;

  public UrlUtils = UrlUtils;
  public EnvUtils = EnvUtils;
  public StickerStatus = StickerStatus;

  public showTooltip: { [key: string]: boolean } = {};
  public stagedId = "";

  public mounted(): void {
    const eventStickers = this.eventSticker ? this.eventSticker.stickers : [];
    const allStickers = [
      ...this.stickers.defaultCategory.stickers,
      ...this.stickers.categories.flatMap((category) => category.stickers),
      ...eventStickers,
    ];
    allStickers.forEach((sticker) => {
      Vue.set(this.showTooltip, sticker.id.id, false);
    });
  }

  public hideStickerSelector(): void {
    this.setShowTooltip("");
    this.showStickersOverlay = false;
  }

  public setStaged(stickerId: CmntStickerId): void {
    this.stagedId = stickerId.id;
    this.setShowTooltip(stickerId.id);
  }

  public setShowTooltip(selectedId: string): void {
    Object.keys(this.showTooltip).forEach((id) => {
      Vue.set(this.showTooltip, id, id === selectedId);
    });
  }

  public onSelectEventStickerProxy(
    eventSticker: CmntEventSticker,
    stickerForEventSticker: CmntStickerForEventSticker
  ): void {
    if (
      this.stagedId === stickerForEventSticker.id.id ||
      !this.useSingleTapPreview
    ) {
      this.onSelectEventSticker(eventSticker, stickerForEventSticker);
    } else {
      this.setStaged(stickerForEventSticker.id);
    }
  }

  public onSelectStickerProxy(selectedSticker: CmntSticker): void {
    if (this.stagedId === selectedSticker.id.id || !this.useSingleTapPreview) {
      this.onSelectSticker(selectedSticker);
    } else {
      this.setStaged(selectedSticker.id);
    }
  }

  public get useSingleTapPreview(): boolean {
    return this.$vuetify.breakpoint.xsOnly;
  }

  @Emit("selected")
  public onSelectEventSticker(
    eventSticker: CmntEventSticker,
    stickerForEventSticker: CmntStickerForEventSticker
  ): CmntSticker {
    this.hideStickerSelector();
    return new CmntSticker(
      stickerForEventSticker.id,
      stickerForEventSticker.name,
      stickerForEventSticker.status,
      [],
      stickerForEventSticker.example
    );
  }

  @Emit("selected")
  public onSelectSticker(selectedSticker: CmntSticker): CmntSticker {
    this.hideStickerSelector();
    return selectedSticker;
  }

  public getStickerImageUrl(
    sticker: CmntSticker | CmntStickerForEventSticker
  ): string {
    return this.cmntTeamName
      ? UrlUtils.getStickerImageUrl(
          this.cmntTeamName,
          EnvUtils.getEnvironment(),
          sticker.id,
          ""
        )
      : UrlUtils.getDefaultStickerImageUrl(
          EnvUtils.getEnvironment(),
          sticker.id
        );
  }
  public formattedExample(example: string | null): string {
    return example ? example.toString().replace(/[\r\n]/g, "<br/>") : "";
  }

  public shouldDisplayCategory(): boolean {
    // イベントステッカーがあれば表示
    if (this.eventSticker && this.eventSticker.stickers.length > 0) {
      return true;
    }
    // 空ではないカテゴリーが2つ以上あれば表示
    const nonEmptyCategoriesCount = [
      this.stickers.defaultCategory,
      ...this.stickers.categories,
    ].filter((category) => category && category.stickers.length > 0).length;
    return nonEmptyCategoriesCount >= 2;
  }
}
