import { z } from "zod";

declare const CmntStickerNameIDTag: unique symbol;

export class CmntStickerName {
  public maxLimit = 20;

  private [CmntStickerNameIDTag]: void;

  constructor(public value: string) {}

  public isValid(): boolean {
    return this.length() > 0 && this.length() <= this.maxLimit;
  }

  public length(): number {
    return this.value.trim().length;
  }
}

export const ZodCmntStickerName = {
  schema: z
    .string()
    .min(1, "Name must be at least 1 character long")
    .max(20, "Name must be no more than 20 characters long"),
};
export type ZodCmntStickerName = z.infer<typeof ZodCmntStickerName.schema>;
