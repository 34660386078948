var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"id":"recipients-icon-array-menu","bottom":"","content-class":"elevation-0","disabled":"","offset-y":"","open-delay":"250","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center pa-1"},[_c('v-card',{attrs:{"dense":"","rounded":"lg","width":"300"}},[_c('v-card-title',{staticClass:"d-flex align-center py-1"},[_c('span',{staticClass:"fs-14 fc-dark fw-bold"},[_vm._v(_vm._s(_vm.$m.create_card_v2.all_receivers))])]),_c('v-divider',{staticClass:"my-0"}),_c('v-list',{staticClass:"recipients-popup",attrs:{"dense":""}},_vm._l((_vm.sortedReceivers),function(receiver){return _c('v-list-item',{key:receiver.id.value,staticClass:"text-decoration-none",attrs:{"ripple":false}},[_c('ReplaceableImage',{attrs:{"alt-src":_vm.UrlUtils.getDefaultUserImageUrl(),"css-class":" to","height":"20px","src":_vm.UrlUtils.getUserImageOrDefaultByUserId(
                  _vm.tenantName,
                  receiver.id
                ),"width":"20px"}}),_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"name"},[_vm._v(_vm._s(_vm.StringUtils.truncate(receiver.name.name, 20)))])],1)}),1)],1)],1)]},proxy:true},{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',{staticClass:"w-100 d-flex flex-row justify-center"},[_c('v-sheet',_vm._g(_vm._b({staticClass:"d-flex flex-row align-center",style:(_vm.sheetStyle),attrs:{"rounded":"pill"}},'v-sheet',attrs,false),on),[_c('div',{staticClass:"icon-array d-flex flex-row align-center",style:(_vm.iconArrayStyle)},[(_vm.receiversWithImageSrcForDisplay.length > 0)?_vm._l((_vm.receiversWithImageSrcForDisplay),function(ref,idx){
                var imageSrc = ref.imageSrc;
                var altSrc = ref.altSrc;
return _c('div',{key:idx,style:(_vm.iconContainerStyle(idx))},[_c('ReplaceableImage',{attrs:{"alt-src":altSrc,"height":"100%","src":imageSrc}})],1)}):_c('div',{style:(_vm.iconContainerStyle(0))},[_c('ReplaceableImage',{attrs:{"alt-src":_vm.UrlUtils.getDefaultUserImageUrl(),"height":"100%","src":_vm.UrlUtils.getDefaultUserImageUrl()}})],1)],2)])],1)]}}]),model:{value:(_vm.isMenuActive),callback:function ($$v) {_vm.isMenuActive=$$v},expression:"isMenuActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }