import { PeerGift } from "@communitio-corp/teams-app-server-api-client-axios/lib/api";
import { CmntPeerGift } from "@/domain/entity/peergift/CmntPeerGift";
import { CmntPeerGiftName } from "@/domain/entity/peergift/CmntPeerGiftName";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";
import { CmntPeerGiftStatus } from "@/domain/entity/peergift/CmntPeerGiftStatus";
import { CmntPeerGiftId } from "@/domain/entity/peergift/CmntPeerGiftId";
import { CmntPeerGiftPrice } from "@/domain/entity/peergift/CmntPeerGiftPrice";
import { CmntPeerGiftType } from "@/domain/entity/peergift/CmntPeerGiftType";
import { CmntPeerGiftConfigCode } from "@/domain/entity/peergift/CmntPeerGiftConfigCode";
import { CmntPeerGiftBrand } from "@/domain/entity/peergift/CmntPeerGiftBrand";
import { CmntPeerGiftDescription } from "@/domain/entity/peergift/CmntPeerGiftDescription";

export const fromPeerGift = (resp: PeerGift): CmntPeerGift => {
  return new CmntPeerGift(
    new CmntPeerGiftId(resp.id ?? ""),
    new CmntPeerGiftName(resp.name ?? ""),
    new CmntPeerGiftBrand(resp.brand ?? ""),
    new CmntPeerGiftPrice(resp.price ?? 0),
    new CmntPeerGiftDescription(resp.description ?? ""),
    CmntValidDateTime.fromDateStrOrThrow(`${resp.validFrom}T00:00:00+09:00`),
    CmntValidDateTime.fromDateStrOrThrow(`${resp.validUntil}T00:00:00+09:00`),
    CmntPeerGiftStatus.fromName(resp.status ?? ""),
    new CmntPeerGiftConfigCode(resp.giftConfigCode ?? ""),
    CmntPeerGiftType.fromValue(resp.giftType),
  );
};
