






import Vue from "vue";
import * as microsoftTeams from "@microsoft/teams-js";
import { Component } from "vue-property-decorator";
import router from "@/common/lib/router";

@Component({
  router,
})
export default class Index extends Vue {
  protected async mounted(): Promise<void> {
    await microsoftTeams.app.initialize().catch(() => {
      //
    });
  }
}
