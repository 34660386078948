






import { Component, Emit, Vue } from "vue-property-decorator";
import { IEmoji } from "v-emoji-picker/lib/models/Emoji";

import EmojiSelector from "@/container/create_card/component/EmojiSelector.vue";

@Component({
  components: {
    EmojiSelector,
  },
})
export default class MessageToolbar extends Vue {
  @Emit("emoji-selected")
  public onSelectedEmoji(selected: IEmoji): IEmoji {
    return selected;
  }
}
