





































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProgressingButton extends Vue {
  @Prop({ type: String, required: true }) public label!: string;
  @Prop({ type: String, required: true }) protected cssClass!: string;
  @Prop({ type: Boolean, required: true }) public progressing!: boolean;
  @Prop({ type: Boolean, required: true }) public disabled!: boolean;
  @Prop({ type: String, required: false }) protected widthClass?: string;
  @Prop({ type: Array, required: false }) public prependIcon?: string[];

  public get width(): string {
    return this.widthClass ?? "w-8";
  }
  public get classes(): string {
    const cssClasses = (this.cssClass ?? "").split(" ");
    if (!cssClasses.find((c) => c.indexOf("bg-") >= 0)) {
      cssClasses.push("bg-accent");
    }

    if (!cssClasses.find((c) => c.indexOf("fc-") >= 0)) {
      cssClasses.push("fc-cmnt-yellow-font");
    }

    cssClasses.push(this.width);

    return cssClasses.join(" ");
  }

  @Emit("click")
  public onOk(): void {
    // empty
  }
}
