import "./assets/style/main.scss";

import Vue, { VNode } from "vue";
import BootstrapVue from "bootstrap-vue";
import * as Raven from "raven-js";
import lineClamp from "vue-line-clamp";
import VTooltip from "v-tooltip";
import contenteditable from "vue-contenteditable/src/contenteditable";
import params from "../params";
import vuetify from "@/common/plugin/vuetify";
import { I18n } from "@/common/lib/lang/i18n";
import router from "@/common/lib/router";
import Index from "@/container/index.vue";
import VueBaseMixin from "@/common/lib/VueBaseMixin";
import "@/common/lib/FontAwesome";

Vue.use(contenteditable);
Vue.use(I18n);
Vue.use(BootstrapVue);
Vue.use(VTooltip);
Vue.mixin(VueBaseMixin);

// `v-focus` というグローバルカスタムディレクティブを登録します
Vue.directive("focus", {
  // ひも付いている要素が DOM に挿入される時...
  inserted: (el) => {
    // 要素にフォーカスを当てる
    el.focus();
  },
});

// 本番とDevとそれ以外で分ける
const ravenUrl =
  params.env === "prd"
    ? "https://4bad5f5e0ae74bcea27a47d1db41ed36@error-report.dev.communitio.tech/16"
    : params.env === "dev"
    ? "https://c6d1cc9f18c14d2ebd77d3182b06d026@error-report.dev.communitio.tech/14"
    : "https://e3ca0899bd854bb1a5b171f1d53f2fb1@error-report.dev.communitio.tech/15";

Raven.config(ravenUrl, {
  environment: `${params.env}-${params.version}-js`,
}).install();

const errorHandler: OnErrorEventHandlerNonNull = (
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error,
): void => {
  Raven.captureException(error);
  Raven.captureMessage(`<${params.env}/pages/global> error occurred.`, {
    extra: {
      event,
      err: error,
      source,
      lineno,
      colno,
    },
  });
};

window.onerror = errorHandler;

Vue.use(lineClamp);

// 4. root となるインスタンスを作成してマウントします
// アプリケーション全体がルーターを認知できるように、
// ルーターをインジェクトすることを忘れないでください。

Vue.config.productionTip = false;

let instance: Vue | null = null;

const render = async (): Promise<void> => {
  // Vueインスタンスの作成
  instance = new Vue({
    router,
    el: "#app",
    render: (h): VNode => h(Index),
    vuetify,
  }).$mount("#app"); // #appはマイクロアプリがマウントされるDOM要素のID
};

// qiankunのライフサイクルフック
export const bootstrap = async (): Promise<void> => {
  console.log("[vue] vue app bootstraped");
};

export const mount = async (): Promise<void> => {
  render();
};

export const unmount = async (): Promise<void> => {
  if (instance) {
    instance.$destroy();
    instance.$el.innerHTML = "";
    instance = null;
  }
};

// スタンドアロン（qiankunなし）で実行される場合
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render();
}
