


























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { PropType } from "vue";
import {
  PeergiftApi,
  TeamsAppApi,
} from "@communitio-corp/teams-app-server-api-client-axios";
import SelectDefaultTeam from "@/container/create_card/SelectDefaultTeam.vue";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";
import {
  buildPeerGiftAppApi,
  buildTeamsAppApi,
} from "@/usecase/interactor/TeamsAppApiBuilder";
import { ITeamStickerGetStickersPort } from "@/usecase/port/TeamStickerGetStickersPort";
import { ITeamStickerGetEventStickersPort } from "@/usecase/port/TeamStickerGetEventStickersPort";
import CreateCardV2 from "@/container/create_card/CreateCardV2.vue";
import { stageBasedOnHref } from "@/common/lib/WindowUtil";
import FallbackComponent from "@/container/error/FallbackComponent.vue";
import ErrorBoundary from "@/container/error/ErrorBoundary.vue";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { CmntEventSticker } from "@/domain/entity/sticker/CmntEventSticker";
import { CmntStickersV2 } from "@/domain/entity/sticker/CmntStickersV2";
import { CmntPeerGiftMyCampaign } from "@/domain/entity/peergift/CmntPeerGiftMyCampaign";
import { ITeamStickerGetMyCampaignAndGiftsPort } from "@/usecase/port/TeamStickerGetMyCampaignPort";
import { TeamsAppAdapterForTeamStickerGetMyCampaignPort } from "@/usecase/interactor/TeamsAppAdapterForTeamStickerGetMyCampaignPort";
import { CmntPeerGift } from "@/domain/entity/peergift/CmntPeerGift";
import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";
import { TeamStickerLoadError } from "@/domain/entity/error/TeamStickerLoadError";
import { SearchMode } from "@/container/create_card/MessageExtensionLogic";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { JwtToken } from "@/domain/entity/authentication/JwtToken";
import { isDefined } from "@/common/lib/TypeUtilities";
import { ITeamsGetUsersPort } from "@/usecase/port/TeamsGetUsersPort";
import { TeamsAppAdapterForTeamsGetUsersPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsGetUsersPort";
import { SimpleTeamsUserInfo } from "@/domain/entity/teams/SimpleTeamsUserInfo";
import { TeamsAppAdapterForTeamsGetAddresseesPort } from "@/usecase/interactor/TeamsAppAdapterForTeamsGetAddresseesPort";
import { ITeamsGetAddresseesPort } from "@/usecase/port/TeamsGetAddresseesPort";
import { ILogger } from "@/common/lib/logger/ILogger";
import { fireAndForget } from "@/common/lib/Utilities";

@Component({
  components: {
    CreateCardV2,
    SelectDefaultTeam,
    ErrorBoundary,
    FallbackComponent,
  },
})
export default class MessageExtensionV2 extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly canSelectAnotherCmntTeam: boolean;
  @Prop({ type: CmntTeamName, required: true })
  public readonly cmntTeam: CmntTeamName;
  @Prop({ type: SimpleTeamsUserInfoV2, required: true })
  public readonly fromUserV2!: SimpleTeamsUserInfoV2;
  @Prop({ type: Object as () => ITeamStickerGetStickersPort, required: true })
  protected teamStickerGetStickersPort: ITeamStickerGetStickersPort;
  @Prop({
    type: Object as () => ITeamStickerGetEventStickersPort,
    required: false,
  })
  protected teamStickerGetEventStickersPort: ITeamStickerGetEventStickersPort;
  @Prop({ type: TeamsContextInfo, required: true })
  public readonly teamsContextInfo: TeamsContextInfo;
  @Prop({ type: Object as () => SearchMode, required: true })
  public readonly searchMode: SearchMode;
  @Prop({ type: Boolean, required: true })
  public useWideStickerSelector: boolean;
  @Prop({ type: JwtToken, required: true })
  public jwtToken: JwtToken;
  @Prop({ type: Object as PropType<ILogger>, required: true })
  private readonly logger: ILogger;

  public eventSticker: CmntEventSticker | null = null;
  public stickers: CmntStickersV2 = {
    defaultCategory: { name: "", stickers: [] },
    categories: [],
  };
  public campaigns: CmntPeerGiftMyCampaign[] = [];
  public gifts: CmntPeerGift[] = [];
  public myEligibilityForPeerGift = false;
  private teamsAppApi: TeamsAppApi;
  private peergiftApi: PeergiftApi;

  public get isValidChannel(): boolean {
    return this.isChannel || this.isChatOrMeeting;
  }

  protected get isChannel(): boolean {
    return this.teamsContextInfo.isChannel;
  }

  protected get isChatOrMeeting(): boolean {
    return this.teamsContextInfo.isChatOrMeeting;
  }

  protected created(): void {
    this.peergiftApi = buildPeerGiftAppApi(stageBasedOnHref(), this.jwtToken);
    this.teamsAppApi = buildTeamsAppApi(stageBasedOnHref(), this.jwtToken);
    fireAndForget(
      Promise.all([this.loadSticker()]).finally(() =>
        Promise.all([
          this.loadMyCampaigns(),
          this.loadMyPeerGiftEligible(),
          this.loadAllUsersIdAndName(),
        ])
      )
    );
  }

  protected get teamStickerGetMyCampaignPort(): ITeamStickerGetMyCampaignAndGiftsPort | null {
    return this.teamsContextInfo && isDefined(this.peergiftApi)
      ? new TeamsAppAdapterForTeamStickerGetMyCampaignPort(
          this.peergiftApi,
          this.teamsContextInfo.tenantId,
          this.cmntTeam ?? null
        )
      : null;
  }

  @Emit("switch-team")
  public onSwitchTeamSuiteTeam(): void {
    // empty
  }

  private async loadMyPeerGiftEligible(): Promise<void> {
    return this.teamsContextInfo && this.cmntTeam
      ? await this.fetchPeerGiftEligibility(
          this.teamsContextInfo.tenantId,
          this.teamsContextInfo.userId,
          this.cmntTeam
        )
          .then((eligible) => {
            this.myEligibilityForPeerGift = eligible;
          })
          .catch(() => {
            this.myEligibilityForPeerGift = false;
          })
      : await Promise.resolve();
  }

  private async loadMyCampaigns(): Promise<void> {
    const now = CmntValidDateTime.now();
    return this.teamStickerGetMyCampaignPort && this.teamsContextInfo
      ? await this.teamStickerGetMyCampaignPort
          .get(this.teamsContextInfo.userId)
          .then((ret) => {
            this.campaigns = ret.campaigns.filter(
              (c) => c.status.isOpen && now.isBefore(c.validUntil)
            );
            this.gifts = ret.gifts;
            return;
          })
          .catch((err) => {
            if (
              isDefined(err) &&
              isDefined(err.response) &&
              isDefined(err.response.data) &&
              isDefined(err.response.data.code) &&
              err.response.data.code ===
                "teams.tenant.is.not.connected.to.team.suite.tenant"
            ) {
              this.$emit("switch-team");
            } else {
              return undefined;
            }
          })
      : await Promise.resolve();
  }

  private allUsersInfo: SimpleTeamsUserInfo[] = [];

  public get otherUsersInfo(): SimpleTeamsUserInfo[] {
    return this.allUsersInfo.filter(
      (userInfo) => userInfo.id !== this.fromUserV2.id
    );
  }

  public get isForAllMembersAvailable(): boolean {
    const l = this.otherUsersInfo.length;
    return l >= 2 && l <= 20;
  }

  get teamsGetUsersPort(): ITeamsGetUsersPort {
    return new TeamsAppAdapterForTeamsGetUsersPort(
      this.teamsAppApi,
      this.teamsContextInfo
    );
  }

  get teamsGetAddresseesPort(): ITeamsGetAddresseesPort {
    return new TeamsAppAdapterForTeamsGetAddresseesPort(
      this.teamsAppApi,
      this.teamsContextInfo,
      this.cmntTeam
    );
  }

  public isForAllMembers = false;

  private async loadAllUsersIdAndName(): Promise<void> {
    // 一括送付の実行条件：allUsersInfo >= 2 && allUsersInfo <= 20
    // 例外処理時(too_many_membersなど)、一括送付をできないようにするために、空配列を返す
    this.allUsersInfo = await this.teamsGetAddresseesPort
      .execute()
      .catch(() => []);
  }

  private async loadSticker(): Promise<void> {
    const p1 = this.teamStickerGetEventStickersPort
      ? await this.teamStickerGetEventStickersPort
          .get()
          .then((eventStickers) => {
            if (eventStickers.length > 0) {
              this.eventSticker = eventStickers[0];
            }
          })
          .catch((err) => {
            if (
              isDefined(err) &&
              isDefined(err.response) &&
              isDefined(err.response.data) &&
              isDefined(err.response.data.code) &&
              err.response.data.code ===
                "teams.tenant.is.not.connected.to.team.suite.tenant"
            ) {
              this.$emit("switch-team");
            } else {
              throw err;
            }
          })
      : await Promise.resolve();

    const p2 =
      this.teamStickerGetStickersPort && this.teamsContextInfo
        ? await this.teamStickerGetStickersPort
            .get(this.teamsContextInfo.userId)
            .then((stickers) => {
              this.stickers = stickers;
            })
        : await Promise.resolve();

    return await Promise.all([p1, p2])
      .then(() => {
        return;
      })
      .catch(() => {
        throw new TeamStickerLoadError();
      });
  }

  private async fetchPeerGiftEligibility(
    tenantId: TeamsTenantId,
    objectId: TeamsAAAObjectUserId,
    cmntTeamId: CmntTeamName
  ): Promise<boolean> {
    if (isDefined(this.peergiftApi)) {
      return this.peergiftApi
        .getPeerGiftEligibility({
          tenantId: tenantId.value,
          objectId: objectId.value,
          cmntTeamId: cmntTeamId.value,
        })
        .then((ret) => ret.data.eligible ?? false)
        .catch((err) => {
          if (
            isDefined(err) &&
            isDefined(err.response) &&
            isDefined(err.response.data) &&
            isDefined(err.response.data.code) &&
            err.response.data.code ===
              "teams.tenant.is.not.connected.to.team.suite.tenant"
          ) {
            this.$emit("switch-team");
            return false;
          } else {
            throw err;
          }
        });
    } else {
      return false;
    }
  }
}
