import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { ITeamsTeamConfigPort, TeamsTeamConfig } from "@/usecase/port/TeamsTeamConfigPort";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";

export class TeamsAppAdapterForTeamsTeamConfigPort implements ITeamsTeamConfigPort {
  constructor(private readonly teamsAppApi: TeamsAppApi, private readonly context: TeamsContextInfo) {}

  public get(): Promise<TeamsTeamConfig> {
    return this.teamsAppApi
      .getTeamConfig({
        context: JSON.stringify({
          tenantId: this.context.tenantId,
          conversation: this.context.conversation,
          groupId: this.context.groupId,
          teamName: this.context.teamName,
          userId: this.context.userId,
          context: this.context.legacyContext,
        }),
      })
      .then((ret) => ({
        isAdminGranted: ret.data.adminGranted ?? false,
        connectedCmntTenants:
          ret.data.connectedTeamSuiteTenants?.map((c) => ({
            teamName: new CmntTeamName(c.tenantId ?? ""),
          })) ?? [],
      }));
  }
}
