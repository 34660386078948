import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CmntInvalidDateTime } from "@/domain/entity/misc/CmntInvalidDateTime";
import { CmntDateTime } from "@/domain/entity/misc/CmntDateTime";
import { CmntDuration } from "@/domain/entity/misc/CmntDuration";

dayjs.extend(utc);

declare const CmntValidDateTimeIDTag: unique symbol;

export class CmntValidDateTime extends CmntDateTime {
  public static now(): CmntValidDateTime {
    return new CmntValidDateTime(dayjs()).setMilliseconds(0);
  }

  public static fromDateStrOrThrow(str: string | null | undefined): CmntValidDateTime {
    const m = dayjs.utc(str ?? "");
    if (m.isValid()) {
      return new CmntValidDateTime(m).setMilliseconds(0);
    } else {
      throw new Error("invalid date string");
    }
  }

  private [CmntValidDateTimeIDTag]: void;

  public setMilliseconds(value: number): CmntValidDateTime {
    return new CmntValidDateTime(this.m.local().millisecond(value));
  }

  public get value(): number {
    return this.m.valueOf();
  }

  public addValue(value: number, unit: dayjs.OpUnitType): CmntValidDateTime {
    return new CmntValidDateTime(this.m.add(value, unit));
  }

  public isBefore(other: CmntValidDateTime | CmntInvalidDateTime): boolean {
    return this.m.isBefore(other.m);
  }

  public toISOString(): string {
    return this.m.tz("Asia/Tokyo").toISOString();
  }

  public toLocalDateString(separator = "/"): string {
    return this.m.local().format(`YYYY${separator}MM${separator}DD`);
  }

  /**
   * この時刻から[[dt]]までの経過時間。
   */
  public diffFrom(dt: CmntValidDateTime): CmntDuration {
    return CmntDuration.fromSeconds(dt.m.diff(this.m, "seconds"));
  }
}
