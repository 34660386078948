import { StickerStatus } from "@communitio-corp/teams-app-server-api-client-axios";
import { z } from "zod";

export class CmntStickerStatus {
  constructor(public readonly status: StickerStatus) {}

  public isValid(): boolean {
    return this.status === StickerStatus.Enabled || this.status === StickerStatus.Disabled;
  }
}

export const ZodCmntStickerStatus = {
  schema: z.enum([StickerStatus.Disabled, StickerStatus.Enabled]),
};

export type ZodCmntStickerStatus = z.infer<typeof ZodCmntStickerStatus.schema>;
