


























































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { PropType } from "vue";
import ReplaceableImage from "@/common/component/image/ReplaceableImage.vue";
import UsersSelector from "@/common/component/selector/UsersSelector.vue";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { UrlUtils } from "@/common/lib/UrlUtils";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { CmntUserIdAndName } from "@/domain/entity/user/CmntUserIdAndName";
import { ILogger } from "@/common/lib/logger/ILogger";
import { TeamsTeamName } from "@/domain/entity/teams/TeamsTeamName";
import { SearchMode } from "@/container/create_card/MessageExtensionLogic";
import RecipientsIconArray from "@/container/create_card/component/RecipientsIconArray.vue";

@Component({
  components: {
    RecipientsIconArray,
    ReplaceableImage,
    UsersSelector,
  },
  model: {
    prop: "selectedUsers",
    event: "update",
  },
})
export default class RecipientSelector extends Vue {
  public UrlUtils = UrlUtils;

  @Prop({ type: CmntTeamName, required: true })
  public cmntTeamName: CmntTeamName;
  @Prop({ type: SimpleTeamsUserInfoV2, required: true })
  protected fromUser!: SimpleTeamsUserInfoV2;
  @Prop({ type: Number, required: true })
  protected readonly numOfReceiverLimit: number;
  @Prop({ type: Array, required: true })
  public readonly selectedUsers: CmntUserIdAndName[];
  @Prop({ type: TeamsTenantId, required: true })
  public teamsTenantId!: TeamsTenantId;
  @Prop({ type: Object as PropType<ILogger> })
  public readonly logger?: ILogger;
  @Prop({ type: Object as PropType<SearchMode>, required: true })
  public readonly searchMode: SearchMode;
  @Prop({ type: TeamsTeamName, required: true })
  public teamsTeamName!: TeamsTeamName;

  public get selectedUsers_(): CmntUserIdAndName[] {
    return this.selectedUsers;
  }

  public set selectedUsers_(selectedUsers: CmntUserIdAndName[]) {
    this.emitSelectedUsersUpdate(selectedUsers);
  }

  @Emit("update")
  private emitSelectedUsersUpdate(
    selectedUsers: CmntUserIdAndName[]
  ): CmntUserIdAndName[] {
    return selectedUsers;
  }

  public isUsersSelectorOpen = false;
  public recipientSelectorAreaMaxWidth = 100; // 妥当な初期値

  $refs: {
    recipientSelectorCard: InstanceType<typeof Vue>;
  };

  get recipientSelectorElement(): HTMLElement {
    return this.$refs.recipientSelectorCard.$el as HTMLElement;
  }

  public onMenuInput(shows: boolean): void {
    if (shows) {
      this.setWidth();
    } else {
      this.recipientSelectorElement.blur();
    }
  }

  private setWidth(): void {
    this.recipientSelectorAreaMaxWidth =
      this.recipientSelectorElement.clientWidth;
  }

  public async mounted(): Promise<void> {
    // activatorのマウントがv-menuのマウント時に行われないため
    // $nextTickでactivatorのマウントを待つ
    this.$nextTick(() => {
      this.setWidth();
    });
  }
}
