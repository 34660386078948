









import Vue from "vue";
import { Component } from "vue-property-decorator";
import querystring from "query-string";

@Component
export default class AuthRedirect extends Vue {
  public get authLink(): string {
    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const maybeRedirectUrl = qs["redirect_url"];
    const maybeParams = qs["params"];
    if (maybeRedirectUrl && maybeParams) {
      return `${maybeRedirectUrl.toString()}?${querystring.stringify(
        JSON.parse(maybeParams.toString())
      )}`;
    } else {
      return "";
    }
  }

  public get authLinkShort(): string {
    const linkUrl = this.authLink;
    if (linkUrl.length > 0) {
      return (
        linkUrl.substring(0, linkUrl.indexOf("/auth/")) +
        "/..." +
        linkUrl.substring(linkUrl.length - 16, linkUrl.length)
      );
    } else {
      return "";
    }
  }

  public mounted(): void {
    const linkUrl = this.authLink;
    if (linkUrl.length > 0) {
      window.location.href = linkUrl;
    }
  }
}
