import { CmntValidDateTime } from "@/domain/entity/misc/CmntValidDateTime";
import { CmntPeerGiftId } from "@/domain/entity/peergift/CmntPeerGiftId";
import { EnvUtils } from "@/common/lib/EnvUtils";
import { CmntPeerGiftName } from "@/domain/entity/peergift/CmntPeerGiftName";
import { CmntPeerGiftStatus } from "@/domain/entity/peergift/CmntPeerGiftStatus";
import { CmntPeerGiftPrice } from "@/domain/entity/peergift/CmntPeerGiftPrice";
import { CmntPeerGiftType } from "@/domain/entity/peergift/CmntPeerGiftType";
import { CmntPeerGiftConfigCode } from "@/domain/entity/peergift/CmntPeerGiftConfigCode";
import { CmntPeerGiftBrand } from "@/domain/entity/peergift/CmntPeerGiftBrand";
import { CmntPeerGiftDescription } from "@/domain/entity/peergift/CmntPeerGiftDescription";

declare const CmntPeerGiftIDTag: unique symbol;

export class CmntPeerGift {
  private [CmntPeerGiftIDTag]: void;

  constructor(
    // * ギフトID。
    public readonly giftId: CmntPeerGiftId,
    // * ギフト名。
    public readonly name: CmntPeerGiftName,
    // * ギフトのブランド。
    public readonly brand: CmntPeerGiftBrand,
    // * ギフトの単価。
    public readonly price: CmntPeerGiftPrice,
    // * ギフトの説明。
    public readonly description: CmntPeerGiftDescription,
    // * ギフトの有効期間開始日時。
    public readonly validFrom: CmntValidDateTime,
    // * ギフトの有効期間終了日時。
    public readonly validUntil: CmntValidDateTime,
    // * ギフトのステータス。
    public readonly status: CmntPeerGiftStatus,
    // * Giftee側のConfigCode
    public readonly giftConfigCode: CmntPeerGiftConfigCode,
    // * Giftee側のギフトタイプ
    public readonly giftType: CmntPeerGiftType,
  ) {}

  public get imageUrl(): string {
    const env = EnvUtils.getEnvironment();
    if (env.isProd) {
      return `https://communitio-images.communitio.tech/public/peergift/${this.giftConfigCode.code}`;
    } else {
      return `https://communitio-images.dev.communitio.tech/public/peergift/${this.giftConfigCode.code}`;
    }
  }
}
