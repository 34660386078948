var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",attrs:{"id":"users-selector"}},[_c('div',{staticClass:"receiver-selector",class:_vm.classes},[_c('transition-group',{staticClass:"receivers-wrapper",class:{
        'px-3 pt-2': _vm.selectedUsers.length > 0,
        'pb-2': !_vm.canSelectNewRecipient,
      },attrs:{"name":"zoom","tag":"div"}},_vm._l((_vm.selectedUsers),function(selected){return _c('div',{key:selected.id.value,staticClass:"receivers text-center"},[_c('div',{staticClass:"chip position-relative row-flex start-center"},[_c('ReplaceableImage',{attrs:{"alt-src":_vm.UrlUtils.getDefaultUserImageUrl(),"css-class":"round-image border-solid-1 mr-1","height":"24px","src":_vm.UrlUtils.getUserImageOrDefaultByUserId(
                _vm.cmntTeamName,
                selected.id
              ),"width":"24px"}}),_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(2),expression:"2"}],staticClass:"ws-nowrap fs-12 mr-2"},[_vm._v(" "+_vm._s(selected.name.name)+" ")]),(selected.id.value !== _vm.myId.value || _vm.canRemoveMyself)?_c('div',{staticClass:"clear-btn br-25 text-center c-pointer"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'times']},on:{"click":function($event){return _vm.unSelectUser(selected.id)}}})],1):_vm._e()],1)])}),0),_c('transition',{attrs:{"name":"slide-x-left"}},[_c('v-menu',{attrs:{"bottom":"","close-on-click":false,"close-on-content-click":false,"disabled":!_vm.canSelectNewRecipient,"nudge-bottom":"1","offset-y":"","open-on-click":""},on:{"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.canSelectNewRecipient),expression:"canSelectNewRecipient"}],ref:"selectNewRecipientMenuActivator",staticClass:"w-100 h-100 px-3",class:_vm.selectedUsers.length > 0 ? 'pb-2' : 'py-2'},'div',attrs,false),on),[_c('div',{staticClass:"receiver-placeholder row-flex start-center text-center w-7 c-pointer"},[_c('div',{staticClass:"row-flex start-center"},[_c('div',{staticClass:"fs-12 fw-bold ws-nowrap"},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])])]}}]),model:{value:(_vm.showSelectRecipientMenu),callback:function ($$v) {_vm.showSelectRecipientMenu=$$v},expression:"showSelectRecipientMenu"}},[_c('v-list',{staticClass:"p-0 m-0"},[_c('v-list-item',{staticClass:"p-0"},[(_vm.showSelectRecipientMenu)?_c('v-autocomplete',{ref:"autocomplete",staticClass:"receiver-selector-autocomplete",attrs:{"autofocus":"","cache-items":false,"close-on-select":false,"dense":"","filter":_vm.getCustomFilter(),"flat":"","height":"48","hide-details":"","hide-no-data":_vm.autoCompleteItems.length > 0 || _vm.canLoadMore,"hide-selected":"","item-text":"text","item-value":"value","items":_vm.autoCompleteItems,"loading":_vm.isLoading,"multiple":"","placeholder":_vm.$m.common.component.selector.users_selector
                  .search_by_name_and_email,"return-object":"","search-input":_vm.searchInput,"solo":""},on:{"blur":_vm.onBlur,"change":_vm.onUserSelected,"update:search-input":_vm.updateSearchInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('ReplaceableImage',{attrs:{"alt-src":_vm.UrlUtils.getDefaultUserImageUrl(),"height":"36","src":_vm.UrlUtils.getUserImageOrDefaultByUserId(
                      _vm.cmntTeamName,
                      item.cmntUserId
                    ),"width":"36"}}),_c('div',{staticClass:"ml-2 text-left py-1"},[_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"text-left fs-14"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"fs-12 fc-999999"},[_vm._v(_vm._s(item.group))])])]}},{key:"append-item",fn:function(){return [(_vm.canLoadMore)?[_c('v-list-item',{on:{"click":_vm.loadMore}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$m.common.component.selector.users_selector.load_more)+" ")])],1)]:_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_c('v-list-item',{on:{"click":_vm.loadMore}},[_c('v-list-item-content',[(_vm.canLoadMore)?[_vm._v(" "+_vm._s(_vm.$m.common.component.selector.users_selector.load_more)+" ")]:[_vm._v(" "+_vm._s(_vm.$m.common.component.selector.users_selector .no_search_result)+" ")]],2)],1)]},proxy:true}],null,false,3666146840)}):_vm._e()],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }