





















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FallbackComponent extends Vue {
  @Prop({ type: String, required: true })
  public message: string;
}
