













import Vue from "vue";
import { Component } from "vue-property-decorator";
import querystring from "query-string";
import * as microsoftTeams from "@microsoft/teams-js";
import * as Raven from "raven-js";
import params from "../../../params";

@Component
export default class NotAuthenticatedPage extends Vue {
  public reqId = "";

  protected async mounted(): Promise<void> {
    await microsoftTeams.app
      .initialize()
      .then(() => {
        return microsoftTeams.app.getContext().then((context) => {
          const locale = context.app.locale.toLowerCase();
          this.$changeLang(
            locale === "ja" || locale === "ja-jp" || locale === "ja_jp"
              ? "ja"
              : "en"
          );
        });
      })
      .catch(() => {
        //
      });

    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const reqIdOrUndef = qs["reqId"];
    this.reqId = reqIdOrUndef ? reqIdOrUndef.toString() : "";

    Raven.setTagsContext({
      reqId: this.reqId,
    });
    Raven.captureMessage(`<${params.env}/pages/not_authenticated>`, {
      level: "warn",
    });
  }
}
