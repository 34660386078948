










import Vue from "vue";
import * as microsoftTeams from "@microsoft/teams-js";
import { Component } from "vue-property-decorator";
import * as Raven from "raven-js";
import { RavenOptions } from "raven-js";
import querystring from "query-string";
import params from "../../../params";

@Component
export default class GrantedPage extends Vue {
  protected async mounted(): Promise<void> {
    const qs = querystring.parse(window.location.search.replace(/^\?/, ""));
    const stateOrUndef = qs["state"];
    const state = stateOrUndef ? JSON.parse(stateOrUndef.toString()) : {};
    const tenantId = state && state.tenantId ? state.tenantId : "";

    Raven.setTagsContext({
      tenant: tenantId,
    });
    Raven.captureMessage(
      `<${params.env}/pages/granted> App Granted. (${tenantId})`,
      {
        extra: { state },
        level: "info",
      } as RavenOptions
    );

    await microsoftTeams.app
      .initialize()
      .then(() => {
        return microsoftTeams.app.getContext().then((context) => {
          const locale = context.app.locale.toLowerCase();
          this.$changeLang(
            locale === "ja" || locale === "ja-jp" || locale === "ja_jp"
              ? "ja"
              : "en"
          );
        });
      })
      .catch(() => {
        //
      });
  }
}
