declare const CmntPeerGiftCampaignNameIDTag: unique symbol;

/**
 * ギフトキャンペーン名。
 */
export class CmntPeerGiftCampaignName {
  public static LOWER_LIMIT = 1;
  public static UPPER_LIMIT = 25;

  private [CmntPeerGiftCampaignNameIDTag]: void;

  constructor(public readonly name: string) {}

  public isValid(): boolean {
    return (
      CmntPeerGiftCampaignName.LOWER_LIMIT <= this.name.length &&
      this.name.length <= CmntPeerGiftCampaignName.UPPER_LIMIT
    );
  }
}
