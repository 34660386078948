declare const CmntPeerGiftIdIDTag: unique symbol;

/**
 * ギフトID。
 */
export class CmntPeerGiftId {
  private [CmntPeerGiftIdIDTag]: void;

  constructor(public readonly id: string) {}
}
