export const nonNullable = <T>(nullable: T): nullable is NonNullable<T> => {
  return nullable !== null && nullable !== undefined;
};

export const ignorePromiseError = (): void => {
  // empty
};

export const sleep = (milliSeconds: number): Promise<void> => {
  return new Promise<void>((resolve: () => void) => {
    setTimeout(() => {
      resolve();
    }, milliSeconds);
  });
};

export const fireAndForget = <T>(promise: Promise<T>): void => {
  promise.catch(ignorePromiseError);
};
