import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import { TeamsSearchUsersPort } from "@/usecase/port/TeamsSearchUsersPort";
import { nonNullable } from "@/common/lib/Utilities";
import { SimpleTeamsUserInfoV2 } from "@/domain/entity/teams/SimpleTeamsUserInfoV2";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { TeamsUserName } from "@/domain/entity/teams/TeamsUserName";
import { TeamsUserPrincipalName } from "@/domain/entity/teams/TeamsUserPrincipalName";
import { TeamsContextInfo } from "@/domain/entity/teams/TeamsContextInfo";
import { JwtToken } from "@/domain/entity/authentication/JwtToken";
import { CmntUserId } from "@/domain/entity/user/CmntUserId";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";

export class TeamsAppAdapterForTeamsSearchUserPort implements TeamsSearchUsersPort {
  constructor(
    private readonly teamsAppApi: TeamsAppApi,
    private readonly context: TeamsContextInfo,
    private readonly token: JwtToken,
  ) {}

  async execute(searchKeyword: string, cmntTeamName: CmntTeamName): Promise<SimpleTeamsUserInfoV2[]> {
    const { type, id } = this.context.conversationTypeAndIdForApi;
    const ret = await this.teamsAppApi.searchUsersInTeamsConversation(
      {
        tenantId: this.context.tenantId.value,
        conversationType: type,
        conversationId: id,
        userId: this.context.userId.value,
        searchWord: searchKeyword,
        cmntTeamId: cmntTeamName.value,
      },
      {
        headers: {
          Authorization: this.token.asBearerHeader,
        },
      },
    );
    return ret.data.users
      .map((u) => {
        return u.id !== undefined && u.userPrincipalName !== undefined && u.displayName !== undefined
          ? new SimpleTeamsUserInfoV2(
              new TeamsAAAObjectUserId(u.id),
              new TeamsUserName(u.displayName),
              new TeamsUserPrincipalName(u.userPrincipalName),
              new CmntUserId(u.cmntUserId ?? u.id),
            )
          : undefined;
      })
      .filter(nonNullable);
  }
}
