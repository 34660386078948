import { TeamsAppApi } from "@communitio-corp/teams-app-server-api-client-axios";
import * as microsoftTeams from "@microsoft/teams-js";
import { ITeamsUserConfigPort, TeamsUserConfig } from "@/usecase/port/TeamsUserConfigPort";
import { TeamsTenantId } from "@/domain/entity/teams/TeamsTenantId";
import { TeamsAAAObjectUserId } from "@/domain/entity/teams/TeamsAAAObjectUserId";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";
import { EnvName } from "@/domain/entity/misc/EnvName";
import { EnvUtils } from "@/common/lib/EnvUtils";
import { UserInfoWithoutToken } from "@/domain/entity/user/UserInfoWithoutToken";
import { ZodTeamSuiteApiToken } from "@/domain/entity/user/ZodTeamSuiteApiToken";

export const authTokenResource = (env: EnvName): string => {
  switch (env.name) {
    case "prd":
      return "api://app.communitio.net";
    case "dev":
      return `api://app.dev.communitio.net`;
    default:
      return "api://app.local.communitio.net";
  }
};

export class TeamsAppAdapterForTeamsUserConfigPort implements ITeamsUserConfigPort {
  constructor(
    private readonly teamsAppApi: TeamsAppApi,
    private readonly teantId: TeamsTenantId,
    private readonly teamsAaaObjectUserId: TeamsAAAObjectUserId,
  ) {}

  async loginWithAccessToken(
    cmntTeamName: CmntTeamName,
  ): Promise<{ user: UserInfoWithoutToken; token: ZodTeamSuiteApiToken }> {
    return await microsoftTeams.authentication
      .getAuthToken({
        resources: [`${authTokenResource(EnvUtils.getEnvironment())}`],
      })
      .then(async (result) => {
        return this.teamsAppApi
          .loginWithAccessToken(
            {
              tenantId: this.teantId.value,
              cmntTeamId: cmntTeamName.value,
            },
            {
              headers: {
                Authorization: `Bearer ${result}`,
              },
            },
          )
          .then(async (ret) => {
            return {
              user: UserInfoWithoutToken.schema.parse(ret.data.user.userInfo),
              token: ZodTeamSuiteApiToken.schema.parse(ret.data.token),
            };
          })
          .catch((e) => {
            console.error("error in loginWithAccessToken", e);
            return Promise.reject(e);
          });
      })
      .catch((err) => {
        console.error("error in getAuthToken", err);
        return Promise.reject(err);
      });
  }

  public get(): Promise<TeamsUserConfig> {
    return this.teamsAppApi
      .getUserSettings({
        tenantId: this.teantId.value,
        userId: this.teamsAaaObjectUserId.value,
      })
      .then((r) => ({
        defaultTeam: r.data.defaultCommunitioTenant ? new CmntTeamName(r.data.defaultCommunitioTenant) : null,
        loggedInTeams: r.data.connectedCommunitioTenants
          ? r.data.connectedCommunitioTenants.map((c) => new CmntTeamName(c))
          : [],
      }));
  }

  public store(config: TeamsUserConfig): Promise<void> {
    return this.teamsAppApi
      .updateUserSettings({
        tenantId: this.teantId.value,
        userId: this.teamsAaaObjectUserId.value,
        userSettings: {
          defaultCommunitioTenant: config.defaultTeam?.value ?? undefined,
        },
      })
      .then(() => {
        // @ts-ignore
      });
  }
}
