




























import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";

@Component({
  model: {
    prop: "text",
    event: "input",
  },
})
export default class CountableTextarea extends Vue {
  @Model("input", { type: String, required: true })
  protected readonly text!: string;

  /**
   * 編集時に許容される文字数の下限値。これを下回るとエラー表示になる。
   */
  @Prop({ type: Number, required: true })
  protected readonly lowerLimit!: number;

  /**
   * 編集時に許容される文字数の上限値。これを上回るとエラー表示になる。
   */
  @Prop({ type: Number, required: true })
  protected readonly upperLimit!: number;

  /**
   * 空文字のときに表示されるプレースホルダー。
   */
  @Prop({ type: String, required: true })
  public readonly placeholder!: string;

  /**
   * リードオンリーにするかどうか。
   */
  @Prop({ type: Boolean, required: true, default: false })
  public readonly readonly!: boolean;

  /**
   * 追加で渡したいCSSがあれば。
   */
  @Prop({ type: String, required: true, default: "" })
  protected readonly cssClass!: string;

  public get text_(): string {
    return this.text;
  }
  public set text_(text: string) {
    this.$emit("input", text);
  }

  /**
   * はじめに個のコンポーネントを表示したときにいきなりバリデーションエラーにならないように、
   * 一度でも編集したらバリデーションされるようにするためのガード。
   */
  protected isMessageValidatable = false;

  public async append(str: string): Promise<void> {
    // textareaのDOM取得
    const textarea = (this.$refs.textarea as Vue).$refs
      .input as HTMLInputElement;
    // textareaの文字列取得
    const sentence = textarea.value;
    const len = sentence.length;
    // キャレット位置取得
    const pos = textarea.selectionStart ?? 0;
    // キャレット位置で文字列分割
    const before = sentence.substring(0, pos);
    const after = sentence.substring(pos, len);
    // キャレット位置に文字挿入
    this.text_ = before + str + after;
    // キャレット位置を変更（連続で挿入する時に必要）
    await this.$nextTick().then(() => {
      textarea.selectionStart = pos + str.length;
    });
  }

  /**
   * 文字数が制限内かどうかのチェック。
   */
  protected get isMessageValid(): boolean {
    return (
      this.text.length >= this.lowerLimit && this.text.length <= this.upperLimit
    );
  }

  public get classes(): string {
    const classes: string[] = [];
    if (this.text.length === 0) {
      classes.push("empty");
    }

    if (this.isMessageValidatable) {
      if (this.isMessageValid) {
        // valid表示はうるさいのでださなくていいかな。
        // classes.push("is-valid");
      } else {
        classes.push("is-invalid");
      }
    }

    if (this.readonly) {
      classes.push("wb-all");
    }

    return classes.join(" ");
  }

  @Watch("text_") private onTextChanged(): void {
    this.isMessageValidatable = true;
  }
}
