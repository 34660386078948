
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ReplaceableImage extends Vue {
  @Prop({ type: String, required: true }) private src: string;
  @Prop({ type: String, required: true }) private altSrc: string;
  @Prop({ type: String, required: false }) private cssClass: string;
  @Prop({ type: String, required: false }) public height: string;
  @Prop({ type: String, required: false }) public width: string;
  @Prop({ type: Boolean, required: false, default: true })
  private rounded: boolean;
  @Prop({ type: Boolean, required: false, default: false })
  private noBorder: boolean;

  public imgSrc = "";

  protected mounted(): void {
    this.imgSrc = this.src;
  }

  public get imageClasses(): string {
    const classes: string[] = [];
    if (this.cssClass) {
      classes.push(this.cssClass);
    }

    if (this.rounded) {
      classes.push("rounded-circle");
    }

    if (!this.noBorder) {
      classes.push("border-2");
    }

    return classes.join(" ");
  }

  @Watch("src") protected onSrcChanged(): void {
    this.imgSrc = this.src;
  }

  public replaceAltSrc(): void {
    this.imgSrc = this.altSrc;
  }
}
