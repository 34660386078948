declare const CmntPeerGiftCampaignIdIDTag: unique symbol;

/**
 * ギフトキャンペーンID。
 */
export class CmntPeerGiftCampaignId {
  private [CmntPeerGiftCampaignIdIDTag]: void;

  constructor(public readonly id: string) {}
}
