declare const TeamsUserNameIDTag: unique symbol;

export class TeamsUserName {
  private [TeamsUserNameIDTag]: void;

  constructor(public readonly value: string) {}

  get initial(): string | undefined {
    // eslint-disable-next-line no-irregular-whitespace
    const names = this.value.split(/[ 　]/);
    if (names.length > 1) {
      return names
        .map((value) => value.substring(0, 1))
        .join("")
        .substring(0, 2);
    } else {
      return this.value.substring(0, 2);
    }
  }
}
