var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(_vm.sticker)?_c('div',{on:{"click":function($event){_vm.showStickersOverlay = !_vm.showStickersOverlay}}},[_c('ReplaceableImage',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.sticker.name.value),expression:"sticker.name.value"}],attrs:{"id":_vm.sticker.id.id,"alt-src":_vm.UrlUtils.getDefaultStickerImageUrl(
          _vm.EnvUtils.getEnvironment(),
          _vm.sticker.id
        ),"css-class":"sticker-selected round-image c-pointer","name":_vm.sticker.name.value,"src":_vm.getStickerImageUrl(_vm.sticker)}})],1):_c('div',{staticClass:"round-image border-dashed-2 sticker-placeholder row-flex center-center text-center fs-12 fc-999999 c-pointer",domProps:{"innerHTML":_vm._s(_vm.$m.create_card_v2.select_sticker)},on:{"click":function($event){_vm.showStickersOverlay = !_vm.showStickersOverlay}}}),_c('v-overlay',{attrs:{"value":_vm.showStickersOverlay},on:{"click":_vm.hideStickerSelector}}),_c('transition',{attrs:{"group":"","name":"slide-x-left"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStickersOverlay),expression:"showStickersOverlay"}],staticClass:"sticker-selector-popup"},[_c('div',{staticClass:"scroll-container p-3",class:_vm.useWideStickerSelector ? 'wide' : 'narrow'},[(_vm.eventSticker && _vm.eventSticker.stickers.length > 0)?_c('div',[_c('div',{staticClass:"p-2 br-8 text-left fs-14 fw-bold fc-555555 mb-1",staticStyle:{"background-color":"#cfd0ff"}},[_vm._v(" "+_vm._s(_vm.$m.create_card.event_sticker_is_open(_vm.eventSticker))+" ")]),_c('div',{staticClass:"flex-wrap d-flex h-100 w-100",class:("stickers-" + (_vm.eventSticker.stickers.length))},_vm._l((_vm.eventSticker.stickers),function(stickerForEventSticker){return (
                stickerForEventSticker.status.status === _vm.StickerStatus.Enabled
              )?_c('div',{key:("event-sticker-" + (stickerForEventSticker.id.id)),staticClass:"c-pointer sticker-container text-center",on:{"click":function($event){return _vm.onSelectEventStickerProxy(
                  _vm.eventSticker,
                  stickerForEventSticker
                )}}},[(_vm.cmntTeamName)?_c('v-tooltip',{attrs:{"bottom":"","color":"#000000CC","offset-overflow":"","open-delay":300,"open-on-click":_vm.useSingleTapPreview,"open-on-hover":!_vm.useSingleTapPreview,"transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('ReplaceableImage',_vm._g(_vm._b({attrs:{"id":stickerForEventSticker.id.id,"alt-src":_vm.UrlUtils.getDefaultStickerImageUrl(
                        _vm.EnvUtils.getEnvironment(),
                        stickerForEventSticker.id
                      ),"css-class":"round-image","name":stickerForEventSticker.name.value,"src":_vm.getStickerImageUrl(stickerForEventSticker)},on:{"click":function($event){return _vm.onSelectEventStickerProxy(
                        _vm.eventSticker,
                        stickerForEventSticker
                      )}}},'ReplaceableImage',attrs,false),on))]}}],null,true),model:{value:(_vm.showTooltip[stickerForEventSticker.id.id]),callback:function ($$v) {_vm.$set(_vm.showTooltip, stickerForEventSticker.id.id, $$v)},expression:"showTooltip[stickerForEventSticker.id.id]"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"10rem","max-width":"16rem","pointer-events":"all"},on:{"click":function($event){return _vm.onSelectEventStickerProxy(
                      _vm.eventSticker,
                      stickerForEventSticker
                    )}}},[_c('div',{staticClass:"my-2",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(stickerForEventSticker.name.value)+" ")]),_c('img',{staticClass:"round-image mb-2",staticStyle:{"width":"10rem","margin":"0 auto 0 auto"},attrs:{"id":stickerForEventSticker.id.id,"name":stickerForEventSticker.name.value,"src":_vm.getStickerImageUrl(stickerForEventSticker)}}),(stickerForEventSticker.example.value)?_c('div',{staticClass:"text-left fs-12 mb-2",domProps:{"innerHTML":_vm._s(
                      _vm.formattedExample(stickerForEventSticker.example.value)
                    )}}):_vm._e()])]):_vm._e()],1):_vm._e()}),0)]):_vm._e(),_vm._l((_vm.stickers.categories),function(category,id){return (category.stickers.length > 0)?_c('div',{key:id},[(_vm.shouldDisplayCategory())?_c('div',{staticClass:"w-100 p-2 br-8 text-left fs-14 fw-bold fc-555555 mb-1",staticStyle:{"background-color":"#fff8e1"}},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e(),_c('div',{staticClass:"flex-wrap d-flex p-2"},_vm._l((category.stickers),function(sticker){return _c('div',{key:("sticker-" + (sticker.id.id)),staticClass:"sticker-container c-pointer text-center",on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#000000CC","offset-overflow":"","open-delay":300,"open-on-click":_vm.useSingleTapPreview,"open-on-hover":!_vm.useSingleTapPreview,"transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('ReplaceableImage',_vm._g(_vm._b({attrs:{"id":sticker.id.id,"alt-src":_vm.UrlUtils.getDefaultStickerImageUrl(
                        _vm.EnvUtils.getEnvironment(),
                        sticker.id
                      ),"css-class":"round-image","name":sticker.name.value,"src":_vm.getStickerImageUrl(sticker)},on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},'ReplaceableImage',attrs,false),on))]}}],null,true),model:{value:(_vm.showTooltip[sticker.id.id]),callback:function ($$v) {_vm.$set(_vm.showTooltip, sticker.id.id, $$v)},expression:"showTooltip[sticker.id.id]"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"10rem","max-width":"16rem","pointer-events":"all"},on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},[_c('div',{staticClass:"my-2",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(sticker.name.value)+" ")]),_c('img',{staticClass:"round-image mb-2",staticStyle:{"width":"10rem","margin":"0 auto 0 auto"},attrs:{"id":sticker.id.id,"name":sticker.name.value,"src":_vm.getStickerImageUrl(sticker)}}),(sticker.example)?_c('div',{staticClass:"text-left fs-12 mb-2",domProps:{"innerHTML":_vm._s(_vm.formattedExample(sticker.example.value))}}):_vm._e()])])],1)}),0)]):_vm._e()}),(
            _vm.shouldDisplayCategory() &&
            _vm.stickers.defaultCategory.stickers.length > 0
          )?_c('div',{staticClass:"w-100 p-2 br-8 text-left fs-14 fw-bold fc-555555 mb-1",staticStyle:{"background-color":"#fff8e1"}},[_vm._v(" "+_vm._s(_vm.stickers.defaultCategory.name)+" ")]):_vm._e(),_c('div',{staticClass:"flex-wrap d-flex p-2"},_vm._l((_vm.stickers.defaultCategory.stickers),function(sticker){return _c('div',{key:("sticker-" + (sticker.id.id)),staticClass:"sticker-container c-pointer text-center",on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#000000CC","offset-overflow":"","open-delay":300,"open-on-click":_vm.useSingleTapPreview,"open-on-hover":!_vm.useSingleTapPreview,"transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('ReplaceableImage',_vm._g(_vm._b({attrs:{"id":sticker.id.id,"alt-src":_vm.UrlUtils.getDefaultStickerImageUrl(
                      _vm.EnvUtils.getEnvironment(),
                      sticker.id
                    ),"css-class":"round-image","name":sticker.name.value,"src":_vm.getStickerImageUrl(sticker)},on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},'ReplaceableImage',attrs,false),on))]}}],null,true),model:{value:(_vm.showTooltip[sticker.id.id]),callback:function ($$v) {_vm.$set(_vm.showTooltip, sticker.id.id, $$v)},expression:"showTooltip[sticker.id.id]"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"min-width":"10rem","max-width":"16rem","pointer-events":"all"},on:{"click":function($event){return _vm.onSelectStickerProxy(sticker)}}},[_c('div',{staticClass:"my-2",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(sticker.name.value)+" ")]),_c('img',{staticClass:"round-image mb-2",staticStyle:{"width":"10rem","margin":"0 auto 0 auto"},attrs:{"id":sticker.id.id,"name":sticker.name.value,"src":_vm.getStickerImageUrl(sticker)}}),(sticker.example)?_c('div',{staticClass:"text-left fs-12 mb-2",domProps:{"innerHTML":_vm._s(_vm.formattedExample(sticker.example.value))}}):_vm._e()])])],1)}),0)],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }