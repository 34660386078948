



























































































import { Component, Prop } from "vue-property-decorator";
import { PropType } from "vue";

import { CmntUserIdAndName } from "@/domain/entity/user/CmntUserIdAndName";
import ReplaceableImage from "@/common/component/image/ReplaceableImage.vue";
import { CmntTeamName } from "@/domain/entity/teamsuite/CmntTeamName";

import {
  getDisplayIconNum,
  getDisplayUsersNum,
  getIconArrayWidth,
  getIconShrinkRate,
  getIconSizePx,
  getMaxIconArrayWidthPx,
  getNonDisplayUsersNum,
  getSheetMaxInnerWidthPx,
  getSortByIdFunction,
  sheetPaddingSizePx,
} from "@/container/create_card/component/RecipientsIconArrayLogic";
import VueBaseMixin from "@/common/lib/VueBaseMixin";

const interIconMarginSizePx = 8;

type CSSProperties = { [key: string]: string };

@Component({
  components: { ReplaceableImage },
})
export default class RecipientsIconArray extends VueBaseMixin {
  $refs: {
    iconArray: HTMLDivElement;
    append: HTMLDivElement;
  };

  @Prop({ type: Array as PropType<CmntUserIdAndName[]>, required: true })
  public multipleReceivers: CmntUserIdAndName[];
  @Prop({ type: CmntTeamName, required: true })
  public tenantName: CmntTeamName;
  @Prop({ type: CmntUserIdAndName })
  public preferredUser?: CmntUserIdAndName;
  @Prop({
    type: Number,
    required: true,
    validator: (sheetMaxWidthPx: number): boolean => {
      return getSheetMaxInnerWidthPx(sheetMaxWidthPx) !== 0;
    },
  })
  public sheetMaxWidthPx: number;
  @Prop({ type: Number, required: true })
  public iconMaxSizePx: number;

  public isMenuActive = false;

  get sheetMaxInnerWidthPx(): number {
    return getSheetMaxInnerWidthPx(this.sheetMaxWidthPx);
  }
  /**
   * 表示するアイコンの数。
   *
   * アイコン = ユーザー・アイコン + 非表示ユーザー数アイコン
   */
  private get displayIconNum(): number {
    return getDisplayIconNum(this.multipleReceivers.length);
  }

  private get maxIconArrayWidthPx(): number {
    return getMaxIconArrayWidthPx(this.iconMaxSizePx, this.displayIconNum);
  }

  private iconMarginLeftSizePx(idx: number): number {
    return idx === 0 ? 0 : interIconMarginSizePx * this.iconShrinkRate;
  }

  private get iconArrayWidth(): number {
    return getIconArrayWidth(
      this.sheetMaxInnerWidthPx,
      this.maxIconArrayWidthPx
    );
  }
  private get iconShrinkRate(): number {
    return getIconShrinkRate(this.iconArrayWidth, this.maxIconArrayWidthPx);
  }
  private get iconSizePx(): number {
    return getIconSizePx(this.iconMaxSizePx, this.iconShrinkRate);
  }

  public get sheetStyle(): CSSProperties {
    return {
      padding: `${sheetPaddingSizePx}px`,
      height: "fit-content",
    };
  }
  public get iconArrayStyle(): CSSProperties {
    return {
      width: `${this.iconArrayWidth}px`,
      height: `${this.iconSizePx}px`,
    };
  }

  public iconContainerStyle(idx: number): CSSProperties {
    return {
      marginLeft: `${this.iconMarginLeftSizePx(idx)}px`,
      width: `${this.iconSizePx}px`,
      height: `${this.iconSizePx}px`,
    };
  }

  public get nonDisplayUsersNum(): number {
    return getNonDisplayUsersNum(
      this.multipleReceivers.length,
      this.displayUsersNum
    );
  }

  public get displayUsersNum(): number {
    return getDisplayUsersNum(this.multipleReceivers.length);
  }

  public get receiversWithImageSrcForDisplay(): {
    imageSrc: string;
    altSrc: string;
  }[] {
    const defaultUserImageUrl = this.UrlUtils.getDefaultUserImageUrl();
    const users = this.sortedReceivers
      .slice(0, this.displayUsersNum)
      .map((r) => {
        return {
          imageSrc: this.UrlUtils.getUserImageOrDefaultByUserId(
            this.tenantName,
            r.id
          ),
          altSrc: defaultUserImageUrl,
        };
      });
    if (this.nonDisplayUsersNum > 0) {
      const nonDisplayUserNumImage =
        this.UrlUtils.getUserNonDisplayUsersNumImage(this.nonDisplayUsersNum);
      return users.concat([
        {
          imageSrc: nonDisplayUserNumImage,
          altSrc: nonDisplayUserNumImage,
        },
      ]);
    } else {
      return users;
    }
  }

  public get sortedReceivers(): CmntUserIdAndName[] {
    const sortByIdFunction = getSortByIdFunction(this.preferredUser?.id);
    return this.multipleReceivers
      .slice()
      .sort((a, b) => sortByIdFunction(a.id, b.id));
  }
}
