/* eslint-disable @typescript-eslint/no-explicit-any */

import { ja } from "@/common/lib/lang/ja";
import { en } from "@/common/lib/lang/en";

export type LangType = typeof ja;

const map = {
  ja,
  en,
} as { [language: string]: typeof ja };

export type AcceptableLanguage = "ja" | "en";

export const messagesOf: (language: string) => typeof ja = (language: string) => {
  const found = map[language];
  return found || ja;
};

class I18nPlugin {
  private currentLang: AcceptableLanguage = "ja";

  constructor() {
    // no action
  }

  public install(Vue: any): void {
    Vue.prototype.$changeLang = (lang: AcceptableLanguage): void => {
      this.currentLang = lang;
      Vue.prototype.$m = messagesOf(this.currentLang);
    };
    Vue.prototype.$m = messagesOf(this.currentLang);
    Vue.prototype.$getLang = (): AcceptableLanguage => {
      return this.currentLang;
    };
  }

  public get messages(): LangType {
    return messagesOf(this.currentLang);
  }
}

export const I18n = new I18nPlugin();
